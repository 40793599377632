import OrderTemplatesComponent from "components/OrderTemplates";
import LoadingComponent from "components/Shared/Loading";
import { useOrderTemplate } from "hooks/useOrderTemplate/useOrderTemplate";
import React, { useEffect } from "react";

const OrderTemplatesPage = (): React.ReactElement => {
  const props = useOrderTemplate();
  const { loaders } = props;

  useEffect(() => {
    props.handleGetTemplates();
  }, []);

  return (
    <LoadingComponent loading={loaders.fetchingTemplates}>
      <OrderTemplatesComponent {...props} />
    </LoadingComponent>
  );
};

export default OrderTemplatesPage;
