import React from "react";

const Initials = ({ text }: { text?: string }): React.ReactElement => {
  return (
    <span className="w-5 md:w-9 h-5 md:h-9 rounded-full bg-primary relative flex items-center justify-center">
      <img src="/images/svg/profile.svg" alt="" className="w-5 md:w-9 h-5 md:h-9 rounded-full" />
      <span
        className="absolute text-white font-medium text-sm md:text-xl uppercase"
        data-testid="businessInitial">
        {text?.charAt(0)}
      </span>
    </span>
  );
};

export default Initials;
