import api from "config/api";
import { useAppState } from "config/store";
import { ERROR_OCCURRED, INVOICE_GENERATION_ERROR, REQUEST_SUCCESSFUL } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { toDate } from "date-fns";
import { track } from "helpers/analytics";
import { replaceAtIndex } from "helpers/array";
import { findCurrencyDescription } from "helpers/currencies";
import { formatReadableDate, formatToISODate, getDate, getLastSecondOfDay } from "helpers/date";
import { isNotEmpty } from "helpers/validate";
import useOrder from "hooks/useOrder/useOrder";
import { useEffect, useRef, useState } from "react";
import {
  FormChangeEvent,
  OrderInvoiceForm,
  OrderInvoiceFormLoaders,
  UseOrderInvoiceType,
  Response
} from "types";
import { AlertType, SegmentEvent } from "types/enum";
import deepEqual from "deep-equal";
import { useInvoice } from "hooks/useInvoice/useInvoice";
import { useNavigate } from "react-router-dom";
import { usePaymentMethods } from "hooks/usePaymentMethods/usePaymentMethods";

export const useOrderInvoice = (): UseOrderInvoiceType => {
  const baseUrl = `${window.location.origin}/order/invoice`;
  const {
    loaders: { fetchingOrder },
    orderDetail,
    orderId
  } = useOrder();
  const invoiceProps = useInvoice();
  const paymentMethodProps = usePaymentMethods();
  const { showAlert } = useAlert();
  const appState = useAppState();
  const navigate = useNavigate();
  const business = appState.business.get();
  const currency = business?.currency;

  const defaultOrderInvoiceForm: OrderInvoiceForm = {
    items: [
      {
        name: "",
        description: "",
        orderItemGroupId: "",
        defaultDescription: "",
        quantity: 0,
        unitPrice: 0,
        totalPrice: 0
      }
    ],
    businessPaymentMethodId: paymentMethodProps.businessPaymentMethods[0]?.id || "",
    dueDate: getDate(),
    date: getDate(),
    note: "",
    whatsAppMessage: "",
    email: "",
    emailMessage: ""
  };

  const [orderInvoiceForm, setOrderInvoiceForm] =
    useState<OrderInvoiceForm>(defaultOrderInvoiceForm);
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<OrderInvoiceFormLoaders>({
    generatingInvoice: false,
    fetchingInvoice: true,
    emailingInvoice: false,
    downloadingInvoice: false
  });
  const [error, setError] = useState("");
  const [dialog, setDialog] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const savedRequestData = useRef<object>({});

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setOrderInvoiceForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleFormItemChange = (event: FormChangeEvent, itemIndex: number): void => {
    const existingItem = orderInvoiceForm.items[itemIndex];

    const { name, value } = event.target;
    setOrderInvoiceForm((prev) => {
      const items = replaceAtIndex(
        prev.items,
        {
          ...existingItem,
          [name]: value
        },
        itemIndex
      );
      return {
        ...prev,
        items
      };
    });
    setError("");
  };

  const handleGenerateInvoice = async (): Promise<string | undefined> => {
    const { businessPaymentMethodId, note, dueDate, items, date } = orderInvoiceForm;
    const requestData = {
      orderId,
      businessPaymentMethodId,
      note,
      dueDate: toDate(dueDate).getTime(),
      date: toDate(date).getTime(),
      items: items.map((item) => ({
        name: item.name,
        description: item.description,
        orderItemGroupId: item.orderItemGroupId
      }))
    };
    // There is no change in the requestData
    if (deepEqual(requestData, savedRequestData.current)) {
      return invoiceId;
    }
    savedRequestData.current = requestData;

    try {
      const json: Response<string> = await api.post("invoice", { json: requestData }).json();
      return json.data;
    } catch {
      return undefined;
    }
  };

  const sendInvoiceViaWhatsApp = async (): Promise<void> => {
    if (!(await generateInvoice())) {
      return;
    }
    track(SegmentEvent.INVOICE_GENERATED, {
      orderId: orderDetail?.id,
      method: "whatsapp"
    });
    setOrderInvoiceForm((prev) => ({
      ...prev,
      whatsAppMessage: `${appState.business.get()?.name} has sent you an invoice of ${
        currency ? findCurrencyDescription(currency)?.symbol : ""
      }${Math.abs(orderDetail?.amountTotal || 0).toLocaleString()}. Due on ${formatReadableDate(
        prev.dueDate
      )}.`
    }));
    setDialog("whatsapp");
    return;
  };

  const generateInvoice = async (): Promise<boolean> => {
    setLoaders((prev) => ({ ...prev, generatingInvoice: true }));
    const invoiceId = await handleGenerateInvoice();
    if (!invoiceId) {
      showAlert(AlertType.DANGER, INVOICE_GENERATION_ERROR);
      setLoaders((prev) => ({ ...prev, generatingInvoice: false }));
      return false;
    }
    setInvoiceId(invoiceId);
    setLoaders((prev) => ({ ...prev, generatingInvoice: false }));
    return true;
  };

  const handlePreview = async (): Promise<void> => {
    if (!(await generateInvoice())) {
      return;
    }
    track(SegmentEvent.INVOICE_GENERATED, {
      orderId: orderDetail?.id,
      method: "preview"
    });
    const newWindow = window.open(`${baseUrl}/${orderId}`, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const sendInvoiceViaEmail = async (): Promise<void> => {
    if (!(await generateInvoice())) {
      return;
    }
    track(SegmentEvent.INVOICE_GENERATED, {
      orderId: orderDetail?.id,
      method: "email"
    });
    setDialog("email");
    return;
  };

  const sendInvoiceEmail = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, emailingInvoice: true }));

    const requestData = {
      to: orderInvoiceForm.email,
      message: orderInvoiceForm.emailMessage
    };

    try {
      const json: Response<string> = await api
        .post(`invoice/${invoiceId}/email`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.INVOICE_SENT_VIA_EMAIL, {
          to: orderInvoiceForm.email,
          message: orderInvoiceForm.emailMessage,
          invoiceId
        });
        showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
        setDialog("");
        setLoaders((prev) => ({ ...prev, emailingInvoice: false }));
        navigate(-1);
        return;
      }
    } catch {
      //
    }
    showAlert(AlertType.DANGER, ERROR_OCCURRED);
    setLoaders((prev) => ({ ...prev, emailingInvoice: false }));
    return;
  };

  const exportInvoiceAsPDF = async (): Promise<void> => {
    if (!(await generateInvoice())) {
      return;
    }
    track(SegmentEvent.INVOICE_GENERATED, {
      orderId: orderDetail?.id,
      method: "pdf"
    });
    invoiceProps.handleGetInvoiceByOrder(orderId || "");
    setDialog("pdf");
  };

  const downloadInvoice = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, downloadingInvoice: true }));
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await invoiceProps.handleDownload();
    setLoaders((prev) => ({ ...prev, downloadingInvoice: false }));
    setDialog("");
  };

  const sendWhatsappMessage = async (): Promise<void> => {
    const newWindow = window.open(
      `https://wa.me/?text=${orderInvoiceForm.whatsAppMessage}%0A%0AView invoice: ${baseUrl}/${orderId}  `,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
    navigate(-1);
  };

  useEffect(() => {
    setOrderInvoiceForm((prev) => {
      return {
        ...prev,
        items:
          orderDetail?.items.map(({ type, value }) => {
            const itemTotal =
              type == "group" ? value.items.reduce((prev, curr) => prev + curr.totalPrice, 0) : 0;

            const groupItemUnitPrice =
              type == "group"
                ? value.items.reduce((prev, curr) => prev + curr.unitPrice * curr.quantity, 0)
                : 0;

            return {
              name: type == "order_item" ? value.product.name : value.name,
              description: "",
              orderItemGroupId: type == "order_item" ? value.groupId : value.id,
              defaultDescription:
                type == "order_item"
                  ? value.variant.name
                  : value.items.map((item) => item.product.name).join(", "),
              quantity: value.quantity,
              unitPrice: type == "order_item" ? value.unitPrice : groupItemUnitPrice,
              totalPrice: type == "order_item" ? value.totalPrice : itemTotal
            };
          }) || [],
        businessPaymentMethodId:
          orderDetail?.invoice?.businessPaymentMethod?.id || prev.businessPaymentMethodId || "",
        dueDate: getDate(),
        date: orderDetail?.invoice?.date || getDate(),
        note: orderDetail?.invoice?.note || "",
        email: orderDetail?.customer.email || ""
      };
    });
  }, [orderDetail]);

  useEffect(() => {
    if (
      paymentMethodProps.loaders.fetchingBusinessPaymentMethods ||
      !orderDetail ||
      !!orderInvoiceForm.businessPaymentMethodId
    ) {
      return;
    }

    setOrderInvoiceForm((prev) => {
      return {
        ...prev,
        businessPaymentMethodId: paymentMethodProps.businessPaymentMethods[0]?.id || ""
      };
    });
  }, [orderDetail, paymentMethodProps.loaders.fetchingBusinessPaymentMethods]);
  useEffect(() => {
    setFormIsValid(
      orderInvoiceForm.items.reduce((curr, prev) => curr && isNotEmpty(prev.name), true)
    );
  }, [orderInvoiceForm]);

  return {
    fetchingOrder,
    orderDetail,
    formIsValid,
    handleFormChange,
    error,
    orderInvoiceForm,
    loaders,
    handleFormItemChange,
    dialog,
    setDialog,
    sendInvoiceViaWhatsApp,
    invoiceId,
    handlePreview,
    baseUrl,
    sendInvoiceEmail,
    sendInvoiceViaEmail,
    exportInvoiceAsPDF,
    downloadInvoice,
    invoiceProps,
    sendWhatsappMessage,
    paymentMethodProps
  };
};
