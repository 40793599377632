import api from "config/api";
import {
  COMPONENT_ATTACHED,
  COMPONENT_DETACHED,
  ERROR_OCCURRED,
  FETCH_FAILED,
  REQUEST_SUCCESSFUL
} from "constants/response";
import { track } from "helpers/analytics";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ComponentForm,
  ComponentLoaders,
  FormChangeEvent,
  ProductComponent,
  ProductComponentDetail,
  ProductFormula,
  ProductFormulaDetail,
  Response,
  UseProductComponentType,
  ObjectChanges
} from "types";
import { AlertType, SegmentEvent } from "types/enum";
import deepEqual from "deep-equal";
import { updateProperty } from "helpers/object";
import { useAlert } from "context/alert/AlertContext";
import { useAutosave } from "react-autosave";
import { AUTOSAVE_INTERVAL } from "constants/general";

const useProductComponent = (): UseProductComponentType => {
  const { productId } = useParams();
  const { showAlert } = useAlert();

  // UseStates
  const [componentForm, setComponentForm] = useState<ComponentForm>({
    measurement: "",
    unit: ""
  });
  const [components, setComponents] = useState<ProductComponent[]>([]);
  const [formulas, setFormulas] = useState<ProductFormula[]>([]);
  const [savedFormulas, setSavedFormulas] = useState<ProductFormula[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [loaders, setLoaders] = useState<ComponentLoaders>({
    savingComponents: false,
    fetchingComponents: true
  });
  const [error, setError] = useState("");
  const [variantsHaveChanges, setVariantsHaveChanges] = useState<ObjectChanges>({});
  useAutosave({
    data: formulas,
    onSave: async (): Promise<void> => {
      if (hasUnsavedChanges) {
        setLoaders((prev) => ({ ...prev, savingComponents: true }));
        await handleSaveFormulas();
        setLoaders((prev) => ({ ...prev, savingComponents: false }));
      }
    },
    interval: AUTOSAVE_INTERVAL,
    saveOnUnmount: true
  });

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setComponentForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleVariantComponentsChange = (
    event: FormChangeEvent,
    variantId: string,
    componentId: string
  ): void => {
    const { name, value } = event.target;
    setFormulas((prev) => {
      return prev.map((formula) => {
        const formulaParts = formula.formulaParts.map((part) =>
          updateProperty(part.componentId === componentId, part, { [name]: value })
        );
        return updateProperty(formula.variantId === variantId, formula, { formulaParts });
      });
    });
    setError("");
  };

  const handleAttachComponent = async (storeItemId: string): Promise<boolean> => {
    setError("");
    const requestData = {
      productId,
      storeItemId
    };
    setLoaders((prev) => ({ ...prev, savingComponents: true }));

    try {
      const json: Response<string> = await api.post("component", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.COMPONENT_ATTACHED, {
          productId,
          storeItemId
        });
        showAlert(AlertType.SUCCESS, COMPONENT_ATTACHED);
        await handleGetProductComponents();
      } else {
        showAlert(AlertType.DANGER, ERROR_OCCURRED);
      }
      setLoaders((prev) => ({ ...prev, savingComponents: false }));
      return isSuccessfull;
    } catch {
      setLoaders((prev) => ({ ...prev, savingComponents: false }));
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
      return false;
    }
  };

  const handleDetachComponent = async (componentId: string): Promise<boolean> => {
    setError("");
    setLoaders((prev) => ({ ...prev, savingComponents: true }));
    try {
      const json: Response<void> = await api.delete(`component/${componentId}`).json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.COMPONENT_DETACHED, {
          productId,
          componentId
        });
        showAlert(AlertType.SUCCESS, COMPONENT_DETACHED);
        await handleGetProductComponents();
      } else {
        showAlert(AlertType.DANGER, ERROR_OCCURRED);
      }
      setLoaders((prev) => ({ ...prev, savingComponents: false }));
      return isSuccessfull;
    } catch {
      setLoaders((prev) => ({ ...prev, savingComponents: false }));
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
      return false;
    }
  };

  const handleSaveFormulas = async (): Promise<boolean> => {
    const requestData = {
      formulas: formulas.map(({ variantId, formulaParts }) => ({
        variantId,
        parts: formulaParts.map(({ measurementValue, measurementUnit, componentId }) => ({
          measurementValue: +measurementValue,
          measurementUnit,
          componentId
        }))
      }))
    };

    try {
      const json: Response<string> = await api
        .put(`formula/${productId}`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        await handleGetProductFormulas();
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingComponents: true }));
    const result = await handleSaveFormulas();

    setLoaders((prev) => ({ ...prev, savingComponents: false }));
    if (result) {
      showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
    } else {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
    }
  };

  const handleGetProductComponents = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingComponents: true }));

    try {
      const json: Response<ProductComponentDetail> = await api.get(`component/${productId}`).json();
      if (json.code === 200) {
        setComponents(json.data.components);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingComponents: false }));
  };

  const handleGetProductFormulas = async (): Promise<void> => {
    try {
      const json: Response<ProductFormulaDetail> = await api.get(`formula/${productId}`).json();
      if (json.code === 200) {
        setSavedFormulas(json.data.formulas);
        // Set received formulas to saved data if present
        setFormulas((prev) => {
          return json.data.formulas.map((formula) => {
            const existingFormula = prev.find(
              (oldFormula) => oldFormula.variantId === formula.variantId
            );
            return updateProperty(!!existingFormula, formula, {
              formulaParts: formula.formulaParts.map((part) => {
                const existingPart = existingFormula?.formulaParts.find(
                  (oldPart) => oldPart.componentId == part.componentId
                );
                return !existingPart
                  ? part
                  : {
                      ...part,
                      measurementValue: +existingPart.measurementValue,
                      measurementUnit: existingPart.measurementUnit
                    };
              })
            });
          });
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // UseEffects
  useEffect(() => {
    setHasUnsavedChanges(!deepEqual(formulas, savedFormulas));
    const variantsHaveChanges: ObjectChanges = {};
    formulas.forEach((formula) => {
      variantsHaveChanges[formula.variantId] = !deepEqual(
        formula,
        savedFormulas.find((savedFormula) => savedFormula.variantId == formula.variantId)
      );
    });
    setVariantsHaveChanges(variantsHaveChanges);
  }, [savedFormulas, formulas]);

  useEffect(() => {
    handleGetProductComponents();
  }, []);

  useEffect(() => {
    // Get variant formulas
    handleGetProductFormulas();
  }, [components]);

  return {
    componentForm,
    components,
    handleFormChange,
    handleFormSubmit,
    error,
    hasUnsavedChanges,
    handleVariantComponentsChange,
    productId,
    handleAttachComponent,
    handleDetachComponent,
    loaders,
    formulas,
    variantsHaveChanges
  };
};

export default useProductComponent;
