import { useAppState } from "config/store";
import { BUSINESS_INFO_ERROR } from "constants/response";
import { group } from "helpers/analytics";
import useBusiness from "hooks/useBusiness";
import useInvite from "hooks/useInvite";
import { HTTPError } from "ky";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MemberBusinesses, UsePostAuthType } from "types";

const usePostAuth = (): UsePostAuthType => {
  const navigate = useNavigate();
  const appState = useAppState();
  const { handleAcceptInvite } = useInvite();
  const invite = appState.invite.get();
  const user = appState.user.get();

  const { handleGetBusiness, handleGetUserBusinesses } = useBusiness();

  const [error, setError] = useState("");

  // User exists on Firebase but not on the server
  const handleSignUp = async (): Promise<void> => {
    navigate(invite ? "/onboarding/member" : "/onboarding");
    return;
  };

  // User exists on Firebase and on the server
  const handleSignIn = async (userWithBusinessess: MemberBusinesses): Promise<void> => {
    setError("");

    const userWithNoBusinessAndInvite = userWithBusinessess.businesses.length === 0 && !invite;
    if (userWithNoBusinessAndInvite) {
      navigate("/onboarding/business");
      return;
    }

    let activeBusinessId = userWithBusinessess.businesses[0].id;

    const userWithInvite = invite && invite.email == userWithBusinessess.email;
    if (userWithInvite) {
      activeBusinessId = invite.business.id;
      await handleAcceptInvite();
    }

    appState.activeBusinessId.set(activeBusinessId);
    const business = await handleGetBusiness(activeBusinessId as string);
    if (!business) {
      setError(BUSINESS_INFO_ERROR);
      navigate("/signin");
      return;
    }

    group();
    navigate("/overview");
    return;
  };

  const handleSetUpBusinesses = async (): Promise<void> => {
    setError("");
    // redirect to landing page if user is not logged in
    if (!user) {
      navigate("/signin");
      return;
    }
    try {
      const businesses = await handleGetUserBusinesses();
      if (businesses) {
        handleSignIn(businesses);
        return;
      } else {
        console.error("No record for user with businesses found");
        navigate("/signin");
        return;
      }
    } catch (err) {
      if (err instanceof HTTPError) {
        if (err.response.status === 404) {
          handleSignUp();
          return;
        }
      }
    }
    setError(BUSINESS_INFO_ERROR);
  };

  useEffect(() => {
    // Make a call to get business before redirecting to onboarding or overview
    handleSetUpBusinesses();
  }, []);

  return {
    error,
    handleSetUpBusinesses
  };
};

export default usePostAuth;
