import api from "config/api";
import { FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { getDate } from "helpers/date";
import { isNotEmpty, isNumber } from "helpers/validate";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  FormChangeEvent,
  OrderForm,
  OrderFormLoaders,
  UseOrderFormType,
  Response,
  OrderDetail
} from "types";
import { AlertType, SegmentEvent } from "types/enum";
import {
  collapseSingleAndGroupedProducts,
  formatOrderItemDiscountAndTax,
  formatOrderItemToOrderFormProduct,
  getTotal
} from "./useOrderForm.utils";
import { v4 } from "uuid";
import { useOrderTemplate } from "hooks/useOrderTemplate/useOrderTemplate";
import { useOrderProductsForm } from "hooks/useOrderProductsForm/useOrderProductsForm";

const useOrderForm = (): UseOrderFormType => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { showAlert } = useAlert();
  const [searchParams] = useSearchParams();
  const templateId = searchParams.get("templateId") || "";

  const { handleGetTemplate } = useOrderTemplate();

  const defaultOrderForm: OrderForm = {
    customerName: "",
    date: getDate(),
    products: [
      {
        key: v4(),
        type: "order_item",
        value: {
          groupId: "",
          productId: "",
          variantId: "",
          quantity: "1",
          unitPrice: ""
        }
      }
    ],
    note: "",
    paymentAmount: "",
    paymentDate: getDate(),
    discountType: "PERCENTAGE",
    discountValue: 0,
    taxName: "VAT",
    taxRate: 0,
    saveAsTemplate: false
  };

  // UseStates
  const [orderForm, setOrderForm] = useState<OrderForm>(defaultOrderForm);
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<OrderFormLoaders>({
    savingOrder: false,
    fetchingOrder: true
  });
  const [error, setError] = useState("");
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState("");
  const [dialog, setDialog] = useState("");
  const productIndexToBeRemoved = useRef<number>(-1);
  const groupedItemIndexToBeRemoved = useRef<number>(-1);

  const orderProductsForm = useOrderProductsForm(setOrderForm, setError);

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    const form: Partial<OrderForm> = {};
    if (name === "date") {
      // Set payment date as order date
      form.paymentDate = event.target.value;
    }
    setOrderForm((prev) => ({
      ...prev,
      ...form,
      [name]: value
    }));
    setError("");
  };

  const handleRemoveSingleOrGroupedProduct = (productIndex: number, confirmed = false): void => {
    const product = orderForm.products[productIndex];
    let message = "";
    let itemId;
    if (product.type == "group") {
      itemId = product.value.id;
      message =
        "Are you sure you want to continue? This action will clear all the output records mapped to the variants in this group";
    } else if (product.type == "order_item") {
      itemId = product.value.orderItemId;
      message =
        "Are you sure you want to continue? This action will clear all the output records mapped to this variant";
    }

    if (!!itemId && !confirmed) {
      setDeleteConfirmationMessage(message);
      productIndexToBeRemoved.current = productIndex;
      groupedItemIndexToBeRemoved.current = -1;
      return;
    }
    orderProductsForm.handleRemoveSingleOrGroupedProduct(productIndex);
  };

  const handleRemoveProductInGroup = (
    productIndex: number,
    itemIndex: number,
    confirmed = false
  ): void => {
    const groupedProducts = orderForm.products[productIndex];
    if (groupedProducts.type == "order_item") {
      return;
    }

    const product = groupedProducts.value.items[itemIndex];
    if (!!product.orderItemId && !confirmed) {
      setDeleteConfirmationMessage(
        "Are you sure you want to continue? This action will clear all the output records mapped to this variant"
      );
      productIndexToBeRemoved.current = productIndex;
      groupedItemIndexToBeRemoved.current = itemIndex;
      return;
    }

    orderProductsForm.handleRemoveProductInGroup(productIndex, itemIndex);
  };

  const handleCreateOrder = async (): Promise<boolean> => {
    const requestData = {
      customer: {
        id: orderForm.customerId,
        name: !orderForm.customerId ? orderForm.customerName : undefined
      },
      date: orderForm.date,
      payment: orderForm.paymentAmount
        ? { amount: orderForm.paymentAmount, date: orderForm.paymentDate }
        : undefined,
      note: orderForm.note,
      saveAsTemplate: !!orderForm.saveAsTemplate,
      ...formatOrderItemDiscountAndTax(orderForm)
    };

    try {
      const json: Response<string> = await api.post("order", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.ORDER_ADDED, {
          hasNote: orderForm.note,
          hasPayment: !!orderForm.paymentAmount,
          noOfItems: requestData.items.length,
          orderId: json.data,
          items: requestData.items.map(({ type, value }) => ({ type, value }))
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleUpdateOrder = async (): Promise<boolean> => {
    const orderUpdateData: Partial<OrderDetail> = {};
    orderUpdateData.date = orderForm.date;
    orderUpdateData.note = orderForm.note;

    try {
      await api.put(`order/${orderId}/info`, { json: orderUpdateData });
      await api.put(`order/${orderId}/items`, {
        json: formatOrderItemDiscountAndTax(orderForm)
      });
    } catch {
      return false;
    }
    return true;
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setLoaders((prev) => ({ ...prev, savingOrder: true }));
    let result;
    if (orderId) {
      result = await handleUpdateOrder();
    } else {
      result = await handleCreateOrder();
    }

    setLoaders((prev) => ({ ...prev, savingOrder: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
      navigate(orderId ? `/sales/orders/${orderId}` : "/sales/orders");
    } else {
      showAlert(AlertType.DANGER);
    }
  };

  const handleGetOrder = async (orderId: string): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingOrder: true }));

    try {
      const json: Response<OrderDetail> = await api.get(`order/${orderId}`).json();
      if (json.code === 200) {
        setOrderForm((prev) => ({
          customerName: json.data.customer.name,
          date: getDate(json.data.date),
          products: prev.products,
          note: json.data.note || "",
          paymentDate: getDate(),
          discountType: json.data.discount?.type || "AMOUNT",
          discountValue: json.data.discount?.value || 0,
          taxName: json.data.tax?.taxName || "VAT",
          taxRate: json.data.tax?.taxRate || 0
        }));
        orderProductsForm.handleSetProducts(
          json.data.items.map((item) => formatOrderItemToOrderFormProduct(item))
        );
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingOrder: false }));
  };

  const handleDeleteConfirmation = async (): Promise<void> => {
    if (groupedItemIndexToBeRemoved.current != -1) {
      // Group product is being removed
      handleRemoveProductInGroup(
        productIndexToBeRemoved.current,
        groupedItemIndexToBeRemoved.current,
        true
      );
    } else {
      // Single or grouped product is being removed
      handleRemoveSingleOrGroupedProduct(productIndexToBeRemoved.current, true);
    }
    handleDeleteCancellation();
  };

  const handleDeleteCancellation = (): void => {
    setDeleteConfirmationMessage("");
    productIndexToBeRemoved.current = -1;
    groupedItemIndexToBeRemoved.current = -1;
  };

  // Handlers
  const handleToggle = (event: FormChangeEvent) => {
    const { name } = event.target;

    setOrderForm((prev) => ({
      ...prev,
      [name]: !prev[name as keyof OrderForm]
    }));
  };

  const handleSetupOrderFormFromTemplate = async (): Promise<void> => {
    const orderTemplate = await handleGetTemplate(templateId);
    if (orderTemplate) {
      orderProductsForm.handleSetProducts(
        orderTemplate.items.map((item) => formatOrderItemToOrderFormProduct(item, false))
      );
    }
  };

  // UseEffects
  useEffect(() => {
    const productsAreValid =
      orderForm.products.length > 0 &&
      collapseSingleAndGroupedProducts(orderForm.products).reduce((prev, curr) => {
        return (
          prev && isNotEmpty(curr.variantId) && isNumber(curr.quantity) && isNumber(curr.unitPrice)
        );
      }, true);

    const customerIsValid = isNotEmpty(orderForm.customerName) || isNotEmpty(orderForm.customerId);

    const paymentIsValid =
      !isNotEmpty(orderForm.paymentAmount) ||
      (isNotEmpty(orderForm.paymentAmount) &&
        !!orderForm.paymentDate &&
        orderForm.date <= orderForm.paymentDate);

    const groupNamesAreValid = orderForm.products.every(
      (product) => product.type == "order_item" || isNotEmpty(product.value.name)
    );

    if (!paymentIsValid) {
      setError("Payment date should be after order date");
    }

    setFormIsValid(
      isNotEmpty(orderForm.date) &&
        productsAreValid &&
        customerIsValid &&
        paymentIsValid &&
        groupNamesAreValid
    );
  }, [orderForm]);

  useEffect(() => {
    if (orderId) {
      handleGetOrder(orderId);
    }
  }, []);

  useEffect(() => {
    if (orderForm.fullPayment) {
      setOrderForm((prev) => ({
        ...prev,
        paymentAmount: getTotal(orderForm).toString()
      }));
    }
  }, [
    orderForm.fullPayment,
    orderForm.products,
    orderForm.discountValue,
    orderForm.discountType,
    orderForm.taxRate
  ]);

  useEffect(() => {
    if (templateId) {
      handleSetupOrderFormFromTemplate();
    }
  }, [templateId]);

  return {
    orderForm,
    formIsValid,
    loaders,
    error,
    handleFormChange,
    ...orderProductsForm,
    handleRemoveSingleOrGroupedProduct,
    handleRemoveProductInGroup,
    handleFormSubmit,
    deleteConfirmationMessage,
    handleDeleteConfirmation,
    handleDeleteCancellation,
    orderId,
    handleToggle,
    dialog,
    setDialog,
    templateId
  };
};

export default useOrderForm;
