import { SignInProps } from "types";
import React from "react";
import AuthLayout from "components/Layout/Auth";
import { Link } from "react-router-dom";
import TextInput from "components/Shared/TextInput";
import Checkbox from "components/Shared/Checkbox";
import Button from "components/Shared/Button";
import Error from "components/Shared/Error";
import SubTitle from "components/Shared/SubTitle";
import Title from "components/Shared/Title";
import Info from "components/Shared/Info";

const SignIn = (props: SignInProps): React.ReactElement => {
  const {
    signInForm,
    handleFormChange,
    handleFormSubmit,
    formIsValid,
    handleGoogleAuth,
    error,
    passwordIsVisible,
    handleTogglePasswordVisibility,
    loaders,
    invite
  } = props;

  return (
    <AuthLayout
      header={
        <span>
          <span className="hidden sm:inline-flex">Dont have an account?&nbsp;</span>
          <Link className="link" to="/signup">
            Sign up
          </Link>
        </span>
      }
      image={<img src="images/svg/signin.svg" />}>
      {invite && (
        <Info
          message={`Welcome to bloom, ${invite.inviter.name} has invited you as a member of ${invite.business.name}, please signin with your account if you have any or signup to create one.`}
          close
        />
      )}
      <div className="w-full">
        <Title className="text-center">Welcome back!</Title>
        <SubTitle>Sign in to continue</SubTitle>
        <button
          data-testid="googleAuth"
          onClick={handleGoogleAuth}
          type="button"
          className="mt-8 w-full font-bold text-primary text-base bg-white border-2 border-primary rounded-full px-4 py-3 md:px-5 md:py-4 text-center inline-flex justify-center">
          <img src="images/svg/google.svg" className="mr-3" />
          Continue with Google
        </button>
        <div className="flex justify-center items-center mt-3 mb-3">
          <hr className="flex-1 h-0.5 bg-dark-grey" />
          <p className="text-center ml-2 mr-2">or</p>
          <hr className="flex-1 h-0.5 bg-dark-grey" />
        </div>

        <form onSubmit={handleFormSubmit} className="">
          <Error error={error} />

          <div>
            <TextInput
              data-testid="emailInput"
              type="email"
              name="email"
              onChange={handleFormChange}
              value={signInForm.email}
              required
              label="Email address"
            />
          </div>
          <div>
            <TextInput
              data-testid="passwordInput"
              type={passwordIsVisible ? "text" : "password"}
              name="password"
              onChange={handleFormChange}
              value={signInForm.password}
              required
              label="Password"
            />
          </div>
          <div className="w-full flex justify-between mb-5">
            <Checkbox
              data-testid="showPassword"
              checked={passwordIsVisible}
              onChange={handleTogglePasswordVisibility}
              label="Show password"
              name="showPassword"
            />
            <Link to="/signin" className="link">
              Forgot password?
            </Link>
          </div>
          <Button
            data-testid="submitButton"
            type="submit"
            disabled={!formIsValid}
            loading={loaders.authenticatingUserAccount}
            className="w-full">
            Sign in
          </Button>
        </form>
      </div>
    </AuthLayout>
  );
};

export default SignIn;
