import { OrderProductionComponentProps } from "types";
import React, { useState } from "react";
import Accordion from "components/Shared/Accordion";
import TextInput from "components/Shared/TextInput";
import Select from "components/Shared/Select";
import Info from "icons/Info";
import Error from "components/Shared/Error";
import Amount from "components/Shared/Amount";
import Cancel from "icons/Cancel";
import IconButton from "components/Shared/IconButton";
import Plus from "icons/Plus";
import { AutosaveButton } from "components/Shared/AutosaveButton/AutosaveButton";
import InputGroup from "components/Shared/InputGroup";

const Activities = (props: OrderProductionComponentProps): React.ReactElement => {
  const {
    productionDetail,
    handleProductionActivityDetailsChange,
    loaders,
    error,
    hasUnsavedChanges,
    handleActivityFormSubmit,
    handleAddActivity,
    handleRemoveActivity,
    businessActivities
  } = props;
  const [activeKey, setActiveKey] = useState("");

  return (
    <div>
      <form
        className="pb-40"
        onSubmit={handleActivityFormSubmit}
        data-testid="productionDetailForm">
        <div className="bg-white md:rounded-lg px-4 md:px-6 py-4 mt-4 md:h-auto">
          <Error error={error} />
          {productionDetail.variants.map(
            (variant): React.ReactElement => (
              <Accordion
                key={variant.id}
                itemId={variant.id}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                header={
                  <div className="inline-flex items-center justify-between flex-1 pr-6">
                    <span>
                      <b data-testid="variantName">
                        {variant.product.name}: {variant.name}
                      </b>
                      <small>&nbsp; x {variant.quantity} unit(s)</small>

                      {variant.activities.filter((activity) => activity.activityTime == 0).length >
                        0 && (
                        <span
                          className="ml-4 text-xs font-medium bg-warning text-dark-warning rounded px-2 py-1 inline-flex items-center"
                          data-testid="emptyActivities">
                          <Info /> &nbsp;
                          {
                            variant.activities.filter((activity) => activity.activityTime == 0)
                              .length
                          }
                        </span>
                      )}
                    </span>
                    <Amount amount={variant.cost.activities} />
                  </div>
                }>
                <div className="flex flex-col">
                  {variant.activities.map((activity, activityIndex) => (
                    <div
                      className="flex flex-wrap justify-between items-center pt-2 px-2 md:px-4  border-b border-grey-30"
                      key={activity.id}
                      data-testid="material">
                      {activity.id ? (
                        <span data-testid="activityName" className="flex-1">
                          {activity.name}
                        </span>
                      ) : (
                        <div className="flex-[100%] md:flex-1 pr-2">
                          <Select
                            id="businessActivityId"
                            inputSize="sm"
                            name="businessActivityId"
                            value={activity.businessActivityId}
                            required
                            onChange={(e): void => {
                              handleProductionActivityDetailsChange(
                                e,
                                variant.id,
                                activity.businessActivityId
                              );
                            }}
                            data-testid="businessActivityId"
                            className="bg-soft-grey"
                            label="Add activity">
                            <option value="" disabled>
                              Select activities
                            </option>
                            {businessActivities
                              .filter(
                                (businessActivity) =>
                                  !variant.activities.find(
                                    (activity) =>
                                      activity.businessActivityId === businessActivity.id &&
                                      activity.id
                                  ) && businessActivity.isProductionActivity
                              )
                              .map((businessActivity) => (
                                <option
                                  key={businessActivity.id}
                                  value={businessActivity.id}
                                  disabled={
                                    !!variant.activities.find(
                                      (activity) =>
                                        activity.businessActivityId === businessActivity.id
                                    )
                                  }>
                                  {businessActivity.name}
                                </option>
                              ))}
                          </Select>
                        </div>
                      )}
                      <div className="flex-1 max-w-xs">
                        <InputGroup>
                          <div className="w-auto flex-1">
                            <TextInput
                              type="number"
                              name="activityTime"
                              id="activityTime"
                              value={activity.activityTime}
                              required
                              onChange={(e): void =>
                                handleProductionActivityDetailsChange(
                                  e,
                                  variant.id,
                                  activity.businessActivityId
                                )
                              }
                              label="Time"
                              data-testid="timeInput"
                              inputSize="sm"
                              min={0}
                              prepend={<span className="text-md">⏰</span>}
                            />
                          </div>
                          <div className="w-20">
                            <Select
                              id="unit"
                              inputSize="sm"
                              name="unit"
                              value={activity.unit}
                              required
                              onChange={(e): void =>
                                handleProductionActivityDetailsChange(
                                  e,
                                  variant.id,
                                  activity.businessActivityId
                                )
                              }
                              data-testid="unit"
                              className="bg-soft-grey"
                              label="Unit">
                              <option value="" disabled>
                                Select a unit
                              </option>
                              <option value="HOUR">Hr</option> <option value="MINUTE">Min</option>
                            </Select>
                          </div>
                        </InputGroup>
                      </div>
                      <button
                        onClick={(): void => handleRemoveActivity(activityIndex, variant.id)}
                        type="button"
                        className="ml-4 top-0 bottom-0 text-danger"
                        data-testid="removeActivity">
                        <Cancel />
                      </button>
                    </div>
                  ))}
                  <div className="flex justify-between items-center py-2 px-4 border-grey-30 bg-soft-grey text-primary">
                    <div>
                      <IconButton
                        disabled={
                          variant.activities.filter((activity) => !activity.businessActivityId)
                            .length > 0
                        }
                        size="sm"
                        onClick={() => handleAddActivity(variant.id)}
                        icon={<Plus />}
                        data-testid="addActivity"
                        outlined>
                        Add activity
                      </IconButton>
                    </div>
                  </div>
                </div>
              </Accordion>
            )
          )}
        </div>
        <div className="fixed left-0 right-0 bottom-0 bg-white px-4 sm:px-12 xl:px-40 py-6 flex justify-between items-center z-10">
          <p className="lg:ml-64">
            {!hasUnsavedChanges && (
              <>
                <b className="text-grey-50 mr-3">Total producion cost</b>
                <b data-testid="productionCost">
                  <Amount amount={productionDetail.cost.total} />
                </b>
              </>
            )}
          </p>
          <AutosaveButton
            disabled={!hasUnsavedChanges}
            hasChanges={hasUnsavedChanges}
            type="submit"
            loading={loaders.savingProductionDetails}
            data-testid="submitButton"
            className="w-60"
          />
        </div>
      </form>
    </div>
  );
};

export default Activities;
