import { Dispatch, SetStateAction, useState } from "react";
import { SortDirection, UseSortType } from "types";

const useSort = <T>(setData: Dispatch<SetStateAction<Array<T>>>): UseSortType<T> => {
  const [direction, setDirection] = useState<SortDirection>("ASC");
  const [selectedField, setSelectedField] = useState("");

  const sort = (data: Array<T>, field: keyof T): void => {
    let newDirection: SortDirection = "ASC";
    if (selectedField == field) {
      newDirection = direction == "ASC" ? "DSC" : "ASC";
    }
    setSelectedField(field as string);
    setDirection(newDirection);
    if (newDirection == "ASC") {
      setData(data.sort((a, b) => (a[field] > b[field] ? 1 : -1)));
    } else {
      setData(data.sort((a, b) => (a[field] < b[field] ? 1 : -1)));
    }
  };

  return {
    direction,
    selectedField,
    sort
  };
};

export default useSort;
