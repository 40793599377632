import EditExpenseComponent from "components/CreateEditExpense";
import useExpense from "hooks/useExpense";
import React from "react";
import LoadingComponent from "components/Shared/Loading";

const EditExpensePage = (): React.ReactElement => {
  const { loaders, ...props } = useExpense();

  return (
    <LoadingComponent loading={loaders.fetchingExpense}>
      <EditExpenseComponent {...props} loaders={loaders} />
    </LoadingComponent>
  );
};

export default EditExpensePage;
