import api from "config/api";
import { ERROR_OCCURRED, FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { getDate } from "helpers/date";
import { isNotEmpty } from "helpers/validate";
import useStoreItems from "hooks/useStoreItems";
import { getStoreItemName } from "hooks/useStoreItems/useStoreItems.utils";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FormChangeEvent,
  Response,
  StoreForm,
  UseStoreItemType,
  StoreItemLoaders,
  DetailedStoreItem,
  StockHistory
} from "types";
import { AlertType, SegmentEvent } from "types/enum";

const useStoreItem = (): UseStoreItemType => {
  const { storeId } = useParams();
  const { measurementUnits } = useStoreItems();
  const { showAlert } = useAlert();

  const defaultStoreForm: StoreForm = {
    name: "",
    unit: "",
    price: "",
    measurement: "",
    quantity: ""
  };

  const defaultPurchaseForm: Pick<StoreForm, "quantity" | "price" | "measurement" | "unit"> = {
    price: "",
    measurement: "",
    quantity: "",
    unit: ""
  };

  // UseStates
  const [storeForm, setStoreForm] = useState<StoreForm>(defaultStoreForm);
  const [purchaseForm, setPurchaseForm] =
    useState<Pick<StoreForm, "quantity" | "price" | "measurement" | "unit">>(defaultPurchaseForm);

  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<StoreItemLoaders>({
    savingStoreItem: false,
    fetchingStoreItem: true,
    savingPurchase: false,
    savingCostPerUnit: false,
    savingAvailableStock: false
  });
  const [error, setError] = useState("");
  const [storeItem, setStoreItem] = useState<DetailedStoreItem>();
  const [dialog, setDialog] = useState("");
  const [history, setHistory] = useState<StockHistory>();
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState("");

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setStoreForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handlePurchaseFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setPurchaseForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleEditStoreItem = async (): Promise<boolean> => {
    const requestData = {
      ...storeForm,
      date: getDate()
    };

    try {
      const json: Response<string> = await api
        .put(`store/${storeId}`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.STORE_ITEM_MODIFIED, {
          storeId,
          name: storeForm.name,
          unit: storeForm.unit
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleSaveCostPerUnit = async (): Promise<boolean> => {
    try {
      const json: Response<string> = await api
        .put(`store/${storeId}/cost-per-unit`, { json: { costPerUnit: +storeForm.price } })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.COST_PER_UNIT_MODIFIED, {
          storeId,
          costPerUnit: storeForm.price
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleSaveAvailableStock = async (): Promise<boolean> => {
    try {
      const json: Response<string> = await api
        .put(`store/${storeId}/available-stock`, { json: { stockValue: +storeForm.measurement } })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.AVAILABLE_STOCK_MODIFIED, {
          storeId,
          stockValue: storeForm.measurement
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleSavePurchase = async (): Promise<boolean> => {
    const requestData = {
      date: getDate(),
      measurementValue: +purchaseForm.measurement,
      price: +purchaseForm.price,
      quantity: +purchaseForm.quantity,
      unit: purchaseForm.unit || storeForm?.unit
    };

    try {
      const json: Response<string> = await api
        .put(`store/${storeId}/stock`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.PURCHASE_ADDED, {
          storeId,
          measurementValue: purchaseForm.measurement,
          price: purchaseForm.price,
          quantity: purchaseForm.quantity
        });
        setPurchaseForm(defaultPurchaseForm);
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleDeleteHistory = async (historyId: string): Promise<boolean> => {
    try {
      const json: Response<void> = await api.delete(`store/${storeId}/stock/${historyId}`).json();
      return json.code === 200;
    } catch {
      return false;
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingStoreItem: true }));
    const result = await handleEditStoreItem();

    setLoaders((prev) => ({ ...prev, savingStoreItem: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
      await handleGetStoreItem();
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  const handlePurchaseFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingPurchase: true }));
    const result = await handleSavePurchase();

    setLoaders((prev) => ({ ...prev, savingPurchase: false }));
    if (result) {
      // Close modal and refetch
      showAlert(AlertType.SUCCESS);
      await handleGetStoreItem();
      setDialog("");
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  const handleCostFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingCostPerUnit: true }));
    const result = await handleSaveCostPerUnit();

    setLoaders((prev) => ({ ...prev, savingCostPerUnit: false }));
    if (result) {
      // Close modal and refetch
      showAlert(AlertType.SUCCESS);
      await handleGetStoreItem();
      setDialog("");
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  const handleAvailableStockFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingAvailableStock: true }));
    const result = await handleSaveAvailableStock();

    setLoaders((prev) => ({ ...prev, savingAvailableStock: false }));
    if (result) {
      // Close modal and refetch
      showAlert(AlertType.SUCCESS);
      await handleGetStoreItem();
      setDialog("");
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  const handleGetStoreItem = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingStoreItem: true }));

    try {
      const json: Response<DetailedStoreItem> = await api.get(`store/${storeId}`).json();
      if (json.code === 200) {
        setStoreItem(json.data);
        setStoreForm({
          name: getStoreItemName(json.data),
          unit: json.data.unit.name,
          price: json.data.costPerUnit.toString(),
          measurement: json.data.availableStock.toString(),
          quantity: ""
        });
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingStoreItem: false }));
  };

  const handleConfirmDelete = (history: StockHistory): void => {
    setDeleteConfirmationMessage("Are you sure you want to permanently delete this purchase?");
    setHistory(history);
  };

  const handleDeleteConfirmation = async (): Promise<void> => {
    if (!history?.id) {
      return;
    }
    if (await handleDeleteHistory(history?.id)) {
      showAlert(AlertType.SUCCESS);
    }
    handleDeleteCancellation();
    await handleGetStoreItem();
  };

  const handleDeleteCancellation = (): void => {
    setDeleteConfirmationMessage("");
    setHistory(undefined);
  };

  // UseEffects
  useEffect(() => {
    setFormIsValid(isNotEmpty(storeForm.name) && isNotEmpty(storeForm.unit));
  }, [storeForm]);

  useEffect(() => {
    handleGetStoreItem();
  }, [storeId]);

  return {
    storeForm,
    formIsValid,
    loaders,
    error,
    handleFormChange,
    handleFormSubmit,
    measurementUnits,
    storeItem,
    handleGetStoreItem,
    handlePurchaseFormChange,
    purchaseForm,
    handlePurchaseFormSubmit,
    handleCostFormSubmit,
    handleAvailableStockFormSubmit,
    dialog,
    setDialog,
    handleConfirmDelete,
    deleteConfirmationMessage,
    handleDeleteConfirmation,
    handleDeleteCancellation
  };
};

export default useStoreItem;
