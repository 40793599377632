import * as React from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar } from "react-date-range";
import { formatDate, formatReadableDate } from "helpers/date";
import { TextInputProps } from "types";
import TextInput from "../TextInput";
import cx from "classnames";
import { useComponentVisible } from "hooks/shared/useComponentVisible/useComponentVisible";
import { toDate } from "date-fns";

const DateInput = (props: TextInputProps): React.ReactElement => {
  const value = typeof props.value === "string" ? new Date(props.value) : new Date();
  const { ref, isComponentVisible } = useComponentVisible<HTMLDivElement>(false);

  return (
    <div className="relative w-full" ref={ref}>
      <TextInput {...props} readOnly value={formatReadableDate(value)} />
      <span
        className={cx(
          "hidden absolute top-full left-0 bg-white shadow-lg mt-1 hover:!block z-10",
          isComponentVisible && "!block"
        )}>
        <Calendar
          minDate={props.min ? toDate(props.min) : undefined}
          color="#00A89D"
          date={value}
          onChange={(date: string) => {
            props.onChange?.({
              target: { value: formatDate(date), name: props.name }
            } as React.ChangeEvent<HTMLInputElement>);
          }}
        />
      </span>
    </div>
  );
};

export default DateInput;
