import { DateRangeType } from "types";
import { addMonths, endOfMonth, format, startOfMonth, toDate } from "date-fns";

export const formatDate = (currDate: Date | string): string => {
  return format(toDate(currDate), "yyyy-MM-dd'T'HH:mm:ss'Z'");
};

export const getLastSecondOfDay = (currDate: Date | string): string => {
  return format(toDate(currDate), "yyyy-MM-dd'T'23:59:59'Z'");
};

export const formatMonth = (date: Date | string): string => {
  return format(toDate(date), "yyyy-MM-01'T'HH:mm:ss'Z'");
};

export const formatReadableMonth = (currDate: string | Date): string => {
  return format(toDate(currDate), "LLL yyyy");
};

export const formatReadableDate = (currDate: string | Date): string => {
  return format(toDate(currDate), "do LLLL yyyy");
};

export const formatReadableTime = (currDate: string | Date): string => {
  return format(toDate(currDate), "hh:mm aaa");
};

export const getDate = (dateText?: string | Date): string => {
  return formatDate(dateText || new Date());
};

export const getDay = (dateText?: string | Date): string => {
  return format(toDate(dateText || new Date()), "yyyy-MM-dd");
};

export const getMonth = (dateText?: string | Date): string => {
  return formatMonth(dateText || new Date());
};

export const getYear = (dateText?: string | Date): string => {
  return format(toDate(dateText || new Date()), "yyyy");
};

export const changeMonth = (currMonth: string, diff: number): string => {
  return formatMonth(addMonths(toDate(currMonth), diff));
};

export const getFirstAndLastDayOfMonth = (currMonth: string | Date): DateRangeType => {
  const currDate = toDate(currMonth);
  const startDate = format(startOfMonth(currDate), "yyyy-MM-01'T'HH:mm:ss'Z'");
  const endDate = formatDate(endOfMonth(currDate));
  return { startDate, endDate };
};

export const getReadableFirstAndLastDayOfMonth = (currMonth: string): DateRangeType => {
  const { startDate, endDate } = getFirstAndLastDayOfMonth(currMonth);
  return { startDate: formatReadableMonth(startDate), endDate: formatReadableMonth(endDate) };
};

export const formatMonthRange = (range: DateRangeType): string => {
  const start = formatReadableMonth(range.startDate);
  const end = formatReadableMonth(range.endDate);

  return start == end ? start : `${start} to ${end}`;
};

export const formatFirstOfMonthToISODate = (date: string | Date): string => {
  return new Date(getFirstAndLastDayOfMonth(date).startDate).toISOString();
};

export const formatToISODate = (date: string | Date): string => {
  return new Date(date).toISOString();
};
