import api from "config/api";
import { useEffect, useState } from "react";
import { UseCustomerType, Response, Customer } from "types";

const useCustomer = (): UseCustomerType => {
  const [customers, setCustomers] = useState<Customer[]>([]);

  // Handlers
  const handleGetCustomers = async (): Promise<void> => {
    const json: Response<Customer[]> = await api.get("customer").json();
    if (json.code === 200) {
      setCustomers(json.data);
    }
  };

  useEffect(() => {
    handleGetCustomers();
  }, []);

  return {
    customers
  };
};

export default useCustomer;
