import React from "react";

export const NavExpense = (): React.ReactElement => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path
      d="M13 10.9789C13.4333 10.9789 13.7917 10.8372 14.075 10.5539C14.3583 10.2705 14.5 9.91222 14.5 9.47888C14.5 9.04555 14.3583 8.68722 14.075 8.40388C13.7917 8.12055 13.4333 7.97888 13 7.97888C12.5667 7.97888 12.2083 8.12055 11.925 8.40388C11.6417 8.68722 11.5 9.04555 11.5 9.47888C11.5 9.91222 11.6417 10.2705 11.925 10.5539C12.2083 10.8372 12.5667 10.9789 13 10.9789ZM2.3 17.9789C1.8 17.9789 1.375 17.8039 1.025 17.4539C0.675 17.1039 0.5 16.6789 0.5 16.1789V2.77888C0.5 2.27888 0.675 1.85388 1.025 1.50388C1.375 1.15388 1.8 0.978882 2.3 0.978882H15.7C16.2 0.978882 16.625 1.15388 16.975 1.50388C17.325 1.85388 17.5 2.27888 17.5 2.77888V5.50388H16V2.77888C16 2.69555 15.971 2.62488 15.913 2.56688C15.8543 2.50821 15.7833 2.47888 15.7 2.47888H2.3C2.21667 2.47888 2.146 2.50821 2.088 2.56688C2.02933 2.62488 2 2.69555 2 2.77888V16.1789C2 16.2622 2.02933 16.3332 2.088 16.3919C2.146 16.4499 2.21667 16.4789 2.3 16.4789H15.7C15.7833 16.4789 15.8543 16.4499 15.913 16.3919C15.971 16.3332 16 16.2622 16 16.1789V13.4539H17.5V16.1789C17.5 16.6789 17.325 17.1039 16.975 17.4539C16.625 17.8039 16.2 17.9789 15.7 17.9789H2.3ZM10.3 13.9789C9.8 13.9789 9.375 13.8039 9.025 13.4539C8.675 13.1039 8.5 12.6789 8.5 12.1789V6.77888C8.5 6.27888 8.675 5.85388 9.025 5.50388C9.375 5.15388 9.8 4.97888 10.3 4.97888H16.7C17.2 4.97888 17.625 5.15388 17.975 5.50388C18.325 5.85388 18.5 6.27888 18.5 6.77888V12.1789C18.5 12.6789 18.325 13.1039 17.975 13.4539C17.625 13.8039 17.2 13.9789 16.7 13.9789H10.3ZM16.7 12.4789C16.7833 12.4789 16.8543 12.4495 16.913 12.3909C16.971 12.3329 17 12.2622 17 12.1789V6.77888C17 6.69555 16.971 6.62455 16.913 6.56588C16.8543 6.50788 16.7833 6.47888 16.7 6.47888H10.3C10.2167 6.47888 10.146 6.50788 10.088 6.56588C10.0293 6.62455 10 6.69555 10 6.77888V12.1789C10 12.2622 10.0293 12.3329 10.088 12.3909C10.146 12.4495 10.2167 12.4789 10.3 12.4789H16.7Z"
      fill="currentColor"
    />
  </svg>
);
