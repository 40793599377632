import TextInput from "../TextInput";
import { FormChangeEvent, UseSearchType } from "types";
import React from "react";
import Search from "icons/Search";
import Cancel from "icons/Cancel";

export const SearchInput = <T, K extends keyof T>({
  search,
  setSearch
}: UseSearchType<T, K>): React.ReactElement => {
  const handleFormChange = (event: FormChangeEvent): void => {
    const { value } = event.target;
    setSearch(value);
  };

  return (
    <div className=" max-w-md">
      <TextInput
        type="text"
        name="search"
        onChange={handleFormChange}
        value={search}
        required
        label="Start typing to search..."
        data-testid="searchInput"
        inputSize="sm"
        prepend={
          <span className="relative -left-1 -top-1 text-grey-50">
            <Search width={20} height={20} />
          </span>
        }
        append={
          search ? (
            <span
              className="relative -top-1 text-grey-50 cursor-pointer"
              onClick={() => setSearch("")}
              title="Clear search input">
              <Cancel width={20} height={20} />
            </span>
          ) : undefined
        }
      />
    </div>
  );
};
