import React from "react";

const CaretLeft = (): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_245_1603" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" transform="matrix(-1 0 0 1 24 0)" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_245_1603)">
        <path
          d="M15.9749 21.65L17.3999 20.225L9.1749 12L17.3999 3.77498L15.9749 2.34998L6.3249 12L15.9749 21.65Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default CaretLeft;
