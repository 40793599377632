import Edit from "icons/Edit";
import React, { ReactElement } from "react";
import cx from "classnames";

export const InlineInput = ({
  value = "",
  className,
  defaultSize = 20,
  padSize = 3,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & {
  defaultSize?: number;
  padSize?: number;
}): ReactElement => {
  return (
    <span className="flex items-center">
      <input
        type="text"
        className={cx(
          "border-0 !border-b-2 border-transparent px-0 font-bold focus:outline-none focus:ring-0 bg-transparent focus:!border-b-primary text-dark-grey placeholder:text-grey-30 peer max-w-[calc(100%-30px)]",
          className
        )}
        size={value ? value.toString().length + padSize : defaultSize}
        value={value}
        {...props}
      />
      <label htmlFor={props.id} className="text-primary px-2 cursor-pointer peer-focus:invisible">
        <Edit width={20} height={20} />
      </label>
    </span>
  );
};
