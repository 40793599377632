import ExpenseConfigurationComponent from "components/ExpenseConfiguration";
import LoadingComponent from "components/Shared/Loading";
import useActivity from "hooks/useActivity";
import useExpense from "hooks/useExpense";
import React, { useEffect } from "react";

const ExpensesPage = (): React.ReactElement => {
  const expenseDetail = useExpense();
  const activityDetail = useActivity();

  useEffect(() => {
    expenseDetail.handleGetExpenses();
  }, [expenseDetail.dateRange]);

  useEffect(() => {
    activityDetail.handleGetActivitiesWithCostDetails();
  }, [activityDetail.dateRange]);

  return (
    <LoadingComponent
      loading={
        (expenseDetail.loaders.fetchingExpense && !expenseDetail.expenses) ||
        (activityDetail.loaders.fetchingActivitites && activityDetail.activities.length === 0)
      }>
      <ExpenseConfigurationComponent
        expenseDetail={expenseDetail}
        activityDetail={activityDetail}
      />
    </LoadingComponent>
  );
};

export default ExpensesPage;
