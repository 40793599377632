import { ERROR_OCCURRED, REQUEST_SUCCESSFUL } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import Error from "components/Shared/Error";
import React from "react";
import { AlertType } from "types/enum";
import Warning from "../Warning/Warning";
import Success from "../Success/Success";
import Info from "../Info";

const Alert = () => {
  const { alerts, removeAlert } = useAlert();
  return (
    <div className="fixed right-0 top-0 p-6 flex flex-col space-y-2 z-50">
      {alerts.map((alert) => (
        <div key={alert.id} className="relative w-96 transition-all">
          {alert.type === AlertType.DANGER && (
            <Error
              error={alert.message ?? ERROR_OCCURRED}
              close
              onClose={(): void => removeAlert(alert.id)}
            />
          )}
          {alert.type === AlertType.WARNING && (
            <Warning message={alert.message} close onClose={(): void => removeAlert(alert.id)} />
          )}
          {alert.type === AlertType.SUCCESS && (
            <Success
              message={alert.message ?? REQUEST_SUCCESSFUL}
              close
              onClose={(): void => removeAlert(alert.id)}
            />
          )}
          {alert.type === AlertType.INFO && (
            <Info message={alert.message} close onClose={(): void => removeAlert(alert.id)} />
          )}
        </div>
      ))}
    </div>
  );
};

export default Alert;
