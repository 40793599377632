import usePostAuth from "hooks/usePostAuth";
import React from "react";
import cx from "classnames";

const PostAuthPage = (): React.ReactElement => {
  const { error, handleSetUpBusinesses } = usePostAuth();
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      {error ? (
        <div className="text-center">
          <p>{error}</p>
          <button
            onClick={handleSetUpBusinesses}
            className={cx(
              "rounded-full px-3 py-2 text-center bg-gradient-to-r from-primary to-secondary text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-primary"
            )}>
            Retry
          </button>
        </div>
      ) : (
        <svg
          className="animate-spin h-14 w-14 text-primary"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      )}
    </div>
  );
};

export default PostAuthPage;
