import CreateStoreItem from "components/CreateStoreItem";
import LoadingComponent from "components/Shared/Loading";
import useStore from "hooks/useStoreItems";
import React from "react";

const CreateStoreItemPage = (): React.ReactElement => {
  const props = useStore();

  return (
    <LoadingComponent loading={false}>
      <CreateStoreItem {...props} />
    </LoadingComponent>
  );
};

export default CreateStoreItemPage;
