import { SettingsHeaderTab } from "components/Settings/Settings";
import Disclosure from "components/Shared/Disclosure";
import Dropdown from "components/Shared/Dropdown";
import IconButton from "components/Shared/IconButton";
import Table from "components/Shared/Table";
import Edit from "icons/Edit";
import Kebeb from "icons/Kebeb";
import Plus from "icons/Plus";
import React from "react";
import { PaymentMethodsProps } from "types";
import { PaymentMethodsDialog } from "./PaymentMethodsDialog";

export const PaymentMethods = (props: PaymentMethodsProps): React.ReactElement => {
  const { businessPaymentMethods, setBusinessPaymentMethod, resetForm, setIsOpen } = props;
  return (
    <div className="px-4 sm:px-0 pb-8">
      <div className="space-y-4">
        <SettingsHeaderTab selectedIndex={2} />
        <div className="bg-white sm:rounded-md p-4 lg:p-8 mt-2 md:mt-7">
          <div className="flex flex-col md:flex-row justify-between">
            <p className="font-bold mb-6 md:mb-0">Payment Methods</p>
            <span
              role="button"
              data-testid="createPaymentMethod"
              className="mb-2 sm:mb-0"
              onClick={(): void => {
                resetForm();
                setIsOpen(true);
              }}>
              <IconButton icon={<Plus />}>Add new payment method</IconButton>
            </span>
          </div>
          <div className="hidden sm:block">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.HeadData>Name</Table.HeadData>
                  <Table.HeadData>Method</Table.HeadData>
                  <Table.HeadData />
                </Table.Row>
              </Table.Head>
              <Table.Body className="text-dark-grey" data-testid="storeItems">
                {businessPaymentMethods.map(
                  (method): React.ReactElement => (
                    <Table.Row key={method.id} className="rounded-lg" data-testid="methodItem">
                      <Table.Data data-testid="methodName">
                        <b>{method.name}</b>
                      </Table.Data>
                      <Table.Data data-testid="method">
                        <b>{method.paymentMethodName}</b>
                      </Table.Data>
                      <Table.Data className="text-dark-grey">
                        <Dropdown
                          menu={[
                            {
                              text: "Edit",
                              icon: <Edit />,
                              onClick: () => {
                                setBusinessPaymentMethod(method);
                                setIsOpen(true);
                              }
                            }
                          ]}>
                          <Kebeb />
                        </Dropdown>
                      </Table.Data>
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
          </div>
          <div className="block sm:hidden mt-4">
            <div className="flex">
              <span className="font-bold text-grey-50 w-4/5 px-4">Name</span>
              <span className="w-1/5"></span>
            </div>
            {businessPaymentMethods.map(
              (method): React.ReactElement => (
                <Disclosure key={method.id} data-testid="methodMobile">
                  <span className="flex">
                    <span className="font-bold w-1/2" data-testid="methodName">
                      <b>{method.name}</b>
                    </span>
                  </span>
                  <div className="flex flex-col">
                    <small className="mt-2 mb-1" data-testid="memberEmail">
                      <span>{method.paymentMethodName}</span>
                    </small>
                    <button
                      className="mobile-link"
                      onClick={() => {
                        setBusinessPaymentMethod(method);
                        setIsOpen(true);
                      }}>
                      <Edit className="mobile-icon" />
                      <span>Edit</span>
                    </button>
                  </div>
                </Disclosure>
              )
            )}
          </div>
          {businessPaymentMethods.length == 0 && (
            <div className="p-4 md:px-6">No payment methods created</div>
          )}
        </div>
      </div>
      <PaymentMethodsDialog {...props} />
    </div>
  );
};
