import React, { useEffect, useState } from "react";
import { DropdownProps } from "types";
import cx from "classnames";
import "./Dropdown.css";
import { Link } from "react-router-dom";
import { useComponentVisible } from "hooks/shared/useComponentVisible/useComponentVisible";

const Dropdown = (props: DropdownProps): React.ReactElement => {
  const { children, menu } = props;
  const { ref, isComponentVisible } = useComponentVisible<HTMLDivElement>(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!isComponentVisible) {
      setShow(false);
    }
  }, [isComponentVisible]);

  return (
    <div
      ref={ref}
      className="relative dropdown-container"
      data-testid="dropdown"
      onClick={(e): void => e.stopPropagation()}>
      <button
        type="button"
        className={cx(
          "dropdown-button w-full inline-flex justify-center",
          show && isComponentVisible && "show"
        )}
        data-testid="dropdownButton"
        onClick={(): void => setShow((prev) => !prev)}>
        {children}
      </button>
      <div
        id="dropdown"
        className={cx(
          "right-0 z-10 bg-white divide-y divide-soft-grey rounded-lg shadow w-44 absolute dropdown",
          show && isComponentVisible && "!block"
        )}>
        <ul
          className="py-2 text-sm text-gray-700"
          aria-labelledby="dropdownDefaultButton"
          data-testid="dropdownList">
          {menu.map((item) => (
            <li
              data-testid="dropdownItem"
              key={String(item.text)}
              className="block px-4 py-2 hover:bg-soft-grey cursor-pointer">
              {item.link && (
                <Link to={item.link} data-testid="dropdownItemLink">
                  <span className="text-grey-50 flex space-x-1 font-bold items-center">
                    {item.icon} <span>{item.text}</span>
                  </span>
                </Link>
              )}
              {item.onClick && (
                <span onClick={item.onClick} data-testid="dropdownItemContainer">
                  <span className="text-grey-50 flex space-x-1 font-bold items-center">
                    {item.icon} <span>{item.text}</span>
                  </span>
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
