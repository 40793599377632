import React, { SVGProps } from "react";

const Cancel = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M7.5 17.8125L12 13.3125L16.5 17.8125L17.8125 16.5L13.3125 12L17.8125 7.5L16.5 6.1875L12 10.6875L7.5 6.1875L6.1875 7.5L10.6875 12L6.1875 16.5L7.5 17.8125ZM12 23.875C10.3542 23.875 8.8075 23.5625 7.36 22.9375C5.91167 22.3125 4.65625 21.4688 3.59375 20.4063C2.53125 19.3438 1.6875 18.0883 1.0625 16.64C0.4375 15.1925 0.125 13.6458 0.125 12C0.125 10.3542 0.4375 8.80708 1.0625 7.35875C1.6875 5.91125 2.53125 4.65625 3.59375 3.59375C4.65625 2.53125 5.91167 1.6875 7.36 1.0625C8.8075 0.4375 10.3542 0.125 12 0.125C13.6458 0.125 15.1929 0.4375 16.6413 1.0625C18.0888 1.6875 19.3438 2.53125 20.4063 3.59375C21.4688 4.65625 22.3125 5.91125 22.9375 7.35875C23.5625 8.80708 23.875 10.3542 23.875 12C23.875 13.6458 23.5625 15.1925 22.9375 16.64C22.3125 18.0883 21.4688 19.3438 20.4063 20.4063C19.3438 21.4688 18.0888 22.3125 16.6413 22.9375C15.1929 23.5625 13.6458 23.875 12 23.875ZM12 22C14.7917 22 17.1562 21.0312 19.0937 19.0937C21.0312 17.1562 22 14.7917 22 12C22 9.20833 21.0312 6.84375 19.0937 4.90625C17.1562 2.96875 14.7917 2 12 2C9.20833 2 6.84375 2.96875 4.90625 4.90625C2.96875 6.84375 2 9.20833 2 12C2 14.7917 2.96875 17.1562 4.90625 19.0937C6.84375 21.0312 9.20833 22 12 22Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Cancel;
