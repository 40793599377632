import OnboardingBusinessComponent from "components/OnboardingBusiness";
import useOnboarding from "hooks/useOnboarding";
import React from "react";

const OnboardingMemberPage = (): React.ReactElement => {
  const props = useOnboarding();

  return <OnboardingBusinessComponent {...props} />;
};

export default OnboardingMemberPage;
