import { OrderItems } from "components/OrderDetail/OrderItems/OrderItems";
import Button from "components/Shared/Button";
import ConfirmationDialog from "components/Shared/ConfirmationDialog";
import Dialog from "components/Shared/Dialog";
import Disclosure from "components/Shared/Disclosure";
import Dropdown from "components/Shared/Dropdown";
import IconButton from "components/Shared/IconButton";
import Title from "components/Shared/Title";
import ArrowLeft from "icons/ArrowLeft";
import Copy from "icons/Copy";
import Delete from "icons/Delete";
import Edit from "icons/Edit";
import Info from "icons/Info";
import Kebeb from "icons/Kebeb";
import Plus from "icons/Plus";
import React from "react";
import { Link } from "react-router-dom";
import { TemplatesProps } from "types";

const OrderTemplates = (props: TemplatesProps): React.ReactElement => {
  const {
    templates,
    deleteConfirmationMessage,
    handleDeleteConfirmation,
    handleDeleteCancellation,
    handleConfirmDelete,
    handlePreview,
    setIsOpen,
    isOpen,
    template
  } = props;

  if (templates.length === 0) {
    return (
      <div
        className="w-full h-full flex flex-col items-center justify-center"
        data-testid="noItems">
        <img src="/images/svg/template.svg" />
        <Title className="pt-10 pb-3">No order templates</Title>
        <p className="text-grey-50 px-4 text-center mb-4">
          Create and save templates for efficient and consistent use for your orders.
        </p>
        <Link to="create" data-testid="createTemplate" className="mb-4 sm:mb-0">
          <IconButton icon={<Plus />}>Add new template</IconButton>
        </Link>
      </div>
    );
  }

  return (
    <div className="">
      <Link to="../" className="font-bold text-sm	inline-flex px-4 md:px-0" data-testid="backLink">
        <span className="text-primary mr-1">
          <ArrowLeft />
        </span>
        Back to orders
      </Link>
      <div className="flex flex-col-reverse sm:flex-row justify-between items-start sm:items-center px-4 md:px-0">
        <Title className="">Saved order templates</Title>
        <Link to="create" data-testid="createTemplate" className="mb-4 sm:mb-0">
          <IconButton icon={<Plus />}>Add new template</IconButton>
        </Link>
      </div>
      <div className="bg-white sm:rounded-lg p-4 lg:p-8 mt-2 sm:mt-7">
        <div className="hidden sm:block space-y-4">
          {templates.map(
            (template): React.ReactElement => (
              <div
                className="flex items-center space-x-8 px-4 py-2 md:px-6 md:py-3 bg-soft-grey rounded-lg text-sm"
                key={template.id}>
                <div className="flex-grow cursor-pointer" onClick={() => handlePreview(template)}>
                  {template.name}
                </div>
                <Link
                  to={`/sales/orders/create?templateId=${template.id}`}
                  className="link inline-flex space-x-2">
                  <Copy />
                  <span>Use template</span>
                </Link>
                <Dropdown
                  menu={[
                    {
                      text: "Preview",
                      icon: <Info />,
                      onClick: () => handlePreview(template)
                    },
                    {
                      text: "Edit",
                      icon: <Edit />,
                      link: `${template.id}`
                    },
                    {
                      text: "Delete",
                      icon: <Delete />,
                      onClick: () => handleConfirmDelete(template)
                    }
                  ]}>
                  <Kebeb />
                </Dropdown>
              </div>
            )
          )}
          {templates.length === 0 && (
            <div className="px-4 py-2 md:px-6 md:py-3 bg-soft-grey rounded-lg text-sm">
              No template created
            </div>
          )}
        </div>
        <div className="block sm:hidden mt-4">
          {templates.map(
            (template): React.ReactElement => (
              <Disclosure key={template.id} data-testid="templateMobile">
                <span className="flex">
                  <span className="font-bold w-4/5" data-testid="templateName">
                    {template.name}
                  </span>
                  <span className="font-bold w-1/5" data-testid="">
                    <Link to={`sales/orders/new?templateId=${template.id}`} className="mobile-link">
                      <Copy className="mobile-icon" />
                      <span className="hidden sm:inline">Use template</span>
                    </Link>
                  </span>
                </span>
                <div className="flex flex-col pt-2">
                  <span className="flex">
                    <button onClick={() => handlePreview(template)} className="mobile-link">
                      <Info className="mobile-icon" />
                      <span>Preview</span>
                    </button>
                    <Link to={`${template.id}`} className="mobile-link">
                      <Edit className="mobile-icon" />
                      <span>Edit</span>
                    </Link>
                    <button
                      data-testid="confirmDelete"
                      onClick={(): void => handleConfirmDelete(template)}
                      className="text-red-500 mobile-link">
                      <Delete className="mobile-icon" />
                      <span>Delete</span>
                    </button>
                  </span>
                </div>
              </Disclosure>
            )
          )}
        </div>
      </div>
      <ConfirmationDialog
        message={deleteConfirmationMessage}
        handleContinue={handleDeleteConfirmation}
        handleCancel={handleDeleteCancellation}
      />
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={template?.name}
        large
        titleClassName="bg-soft-grey"
        titleBtn={
          <Link
            to={`/sales/orders/create?templateId=${template?.id}`}
            className="link inline-flex space-x-2 mr-4">
            <Button type="button" className="px-6" size="md">
              <Copy className="mr-2" />
              <span>Use this template</span>
            </Button>
          </Link>
        }>
        <OrderItems items={template?.items || []} amountTotal={template?.total || 0} />
      </Dialog>
    </div>
  );
};

export default OrderTemplates;
