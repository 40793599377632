import React from "react";
import { ToggleProps } from "types";

const Toggle = (props: ToggleProps) => {
  const { label, ...checkboxProps } = props;

  return (
    <label className="relative inline-flex items-center cursor-pointer my-4">
      <input
        data-testid="toggleInput"
        type="checkbox"
        className="sr-only peer"
        id={checkboxProps.name}
        {...checkboxProps}
      />
      <div className="w-11 h-6 bg-soft-grey peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-soft-teal rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border--softgray after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary peer-disabled:bg-[#05a89d7d] peer-disabled:cursor-not-allowed"></div>
      <span className="ml-3 text-sm font-medium text-dark-grey" data-testid="toggleLabel">
        {label}
      </span>
    </label>
  );
};

export default Toggle;
