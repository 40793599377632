import React from "react";

export const NavTemplate = (): React.ReactElement => (
  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.68112 14.6376C1.2863 14.6376 0.949082 14.4978 0.669465 14.2182C0.389822 13.9386 0.25 13.6014 0.25 13.2065V3.67613H1.4375V13.2065C1.4375 13.2776 1.46034 13.336 1.50602 13.3816C1.5517 13.4273 1.61007 13.4501 1.68112 13.4501H14.5V14.6376H1.68112ZM4.45191 11.8668C4.0571 11.8668 3.71988 11.727 3.44026 11.4474C3.16064 11.1678 3.02084 10.8305 3.02084 10.4357V1.9101C3.02084 1.51528 3.16064 1.17807 3.44026 0.898449C3.71988 0.618819 4.0571 0.479004 4.45191 0.479004H8.39503L9.97837 2.06234H15.4439C15.8387 2.06234 16.1759 2.20215 16.4556 2.48178C16.7352 2.7614 16.875 3.09862 16.875 3.49344V10.4357C16.875 10.8305 16.7352 11.1678 16.4556 11.4474C16.1759 11.727 15.8387 11.8668 15.4439 11.8668H4.45191ZM4.45191 10.6793H15.4439C15.515 10.6793 15.5733 10.6565 15.619 10.6108C15.6647 10.5651 15.6875 10.5068 15.6875 10.4357V3.49344C15.6875 3.42239 15.6647 3.36402 15.619 3.31834C15.5733 3.27266 15.515 3.24982 15.4439 3.24982H9.49272L7.90938 1.66649H4.45191C4.38086 1.66649 4.3225 1.68933 4.27682 1.735C4.23115 1.78068 4.20832 1.83905 4.20832 1.9101V10.4357C4.20832 10.5068 4.23115 10.5651 4.27682 10.6108C4.3225 10.6565 4.38086 10.6793 4.45191 10.6793Z"
      fill="currentColor"
    />
  </svg>
);
