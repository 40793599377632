import { DefaultComponentProps } from "types";
import React from "react";
import "./InputGroup.css";

const InputGroup = (props: DefaultComponentProps): React.ReactElement => {
  return (
    <div className="input-group flex" data-testid="input-group">
      {props.children}
    </div>
  );
};

export default InputGroup;
