import validator from "validator";

export const isNotEmpty = (text: string | undefined): boolean => {
  return text !== undefined && !validator.isEmpty(text.trim());
};

export const isEmail = (email: string): boolean => {
  return validator.isEmail(email.trim());
};

export const isNumber = (number: string | undefined): boolean => {
  return number !== undefined && validator.isNumeric(number);
};
