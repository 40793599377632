import React, { useState } from "react";
import { ProductConfigurationProps } from "types";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Title from "components/Shared/Title";
import { Tab } from "@headlessui/react";
import Hidden from "components/Shared/Hidden";
import Components from "./Components";
import AddOns from "./AddOns";
import Prompt from "components/Shared/Prompt";
import { FeatureFlag } from "types/enum";
import Extension from "icons/Extension";
import Customize from "icons/Customize";
import { TabTitle } from "components/Shared/Tab/Tab";
import Wallet from "icons/Wallet";
import Activities from "./Activities/Activities";

const ProductConfiguration = (props: ProductConfigurationProps): React.ReactElement => {
  const {
    productDetail,
    productComponents,
    storeItems,
    measurementUnits,
    productAddOns,
    productActivities
  } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="">
      <Link to={`/products/${productDetail.id}`} className="font-bold text-sm	flex px-4 md:px-0">
        <span className="text-primary mr-1">
          <ArrowLeft />
        </span>
        Back to variants
      </Link>
      <div className="mt-3 flex flex-col sm:flex-row justify-between sm:items-center space-y-4 sm:space-y-0 px-4 md:px-0 mb-4">
        <Title className="pt-2 md:b-6" data-testid="productName">
          {productDetail.name}
        </Title>
      </div>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="bg-white flex text-grey-50 font-bold">
          <Hidden flag={FeatureFlag.STORE}>
            <TabTitle title="Components" isSelected={selectedIndex == 0} icon={<Extension />} />
          </Hidden>
          <TabTitle title="Add-ons" isSelected={selectedIndex == 1} icon={<Customize />} />
          <TabTitle
            title="Activities"
            isSelected={selectedIndex == 2}
            icon={<Wallet width={30} height={30} />}
          />
        </Tab.List>

        <Tab.Panels>
          <Hidden flag={FeatureFlag.STORE}>
            <Tab.Panel>
              <Components
                {...productComponents}
                storeItems={storeItems}
                measurementUnits={measurementUnits}
                productName={productDetail.name}
              />
            </Tab.Panel>
          </Hidden>
          <Tab.Panel>
            <AddOns {...productAddOns} productName={productDetail.name} />
          </Tab.Panel>
          <Tab.Panel>
            <Activities {...productActivities} productName={productDetail.name} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <Prompt when={productComponents.hasUnsavedChanges} />
    </div>
  );
};

export default ProductConfiguration;
