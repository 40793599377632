import ProductsComponent from "components/Products";
import LoadingComponent from "components/Shared/Loading";
import useProduct from "hooks/useProduct";
import React, { useEffect } from "react";

const ExpensesPage = (): React.ReactElement => {
  const {
    products,
    handleGetProducts,
    loaders,
    handleClone,
    handleCloneFormSubmit,
    handleFormChange,
    isOpen,
    setIsOpen,
    productForm,
    error
  } = useProduct();

  useEffect(() => {
    handleGetProducts();
  }, []);

  return (
    <LoadingComponent loading={loaders.fetchingProduct && !products}>
      <ProductsComponent
        products={products}
        handleClone={handleClone}
        handleCloneFormSubmit={handleCloneFormSubmit}
        handleFormChange={handleFormChange}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loaders={loaders}
        productForm={productForm}
        error={error}
      />
    </LoadingComponent>
  );
};

export default ExpensesPage;
