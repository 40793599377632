import MembersComponent from "components/Members";
import LoadingComponent from "components/Shared/Loading";
import useMembers from "hooks/useMembers";
import React from "react";

const MembersPage = (): React.ReactElement => {
  const membersDetail = useMembers();

  return (
    <LoadingComponent loading={membersDetail.loaders.fetchingMembers}>
      <MembersComponent {...membersDetail} />
    </LoadingComponent>
  );
};

export default MembersPage;
