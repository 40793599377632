import api from "config/api";
import { useAppState } from "config/store";
import { identify } from "helpers/analytics";
import {
  UseBusinessType,
  Response,
  Business,
  BusinessType,
  MemberBusinesses,
  Currency
} from "types";

const useBusiness = (): UseBusinessType => {
  const appState = useAppState();
  const user = appState.user.get();

  // Handlers
  const handleCreateBusiness = async ({
    ownerName,
    businessName,
    currency,
    businessType
  }: {
    ownerName: string;
    businessName: string;
    currency: string;
    businessType: string;
  }): Promise<string | null> => {
    const requestData = {
      userId: user?.uid,
      email: user?.email,
      ownerName,
      businessName,
      currency,
      businessType
    };
    try {
      const json: Response<string> = await api.post("register", { json: requestData }).json();
      return json.code === 201 ? json.data : null;
    } catch {
      return null;
    }
  };

  const handleGetBusiness = async (businessId: string): Promise<Business | void> => {
    const json: Response<Business> = await api
      .get(`business/${businessId}/user`, { timeout: false })
      .json();
    if (json.code === 200) {
      appState.business.set(json.data);
      return json.data;
    }
  };

  const handleGetUserBusinesses = async (): Promise<MemberBusinesses | void> => {
    const json: Response<MemberBusinesses> = await api.get("user", { timeout: false }).json();
    if (json.code === 200) {
      identify();
      appState.member.set(json.data);
      return json.data;
    }
    return;
  };

  const handleGetBusinessTypes = async (): Promise<BusinessType[]> => {
    const json: Response<BusinessType[]> = await api.get("business-types").json();
    if (json.code === 200) {
      return json.data;
    }
    return [];
  };

  const handleGetCurrencies = async (): Promise<Currency[]> => {
    const json: Response<Currency[]> = await api.get("currencies").json();
    if (json.code === 200) {
      return json.data;
    }
    return [];
  };

  return {
    handleCreateBusiness,
    handleGetBusiness,
    handleGetBusinessTypes,
    handleGetUserBusinesses,
    handleGetCurrencies
  };
};

export default useBusiness;
