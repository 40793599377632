import { useAppState } from "config/store";
import { findCurrencyDescription } from "helpers/currencies";
import React from "react";

const CurrencySymbol = ({ currency }: { currency?: string }): React.ReactElement => {
  const appState = useAppState();
  const business = appState.business.get();
  const currentCurrency = currency || business?.currency;

  return <span>{currentCurrency ? findCurrencyDescription(currentCurrency)?.symbol : ""}</span>;
};

export default CurrencySymbol;
