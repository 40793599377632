import { CheckboxProps } from "types";
import React from "react";

const Checkbox = (props: CheckboxProps): React.ReactElement => {
  const { label, ...checkboxProps } = props;
  return (
    <span className="cursor-pointer">
      <input
        data-testid="checkboxInput"
        type="checkbox"
        id={checkboxProps.name}
        {...checkboxProps}
        className={`cursor-pointer bg-transparent border-grey-60 text-grey-60 focus:ring-0 focus:border-grey-60 focus:checked:bg-primary hover:checked:bg-primary checked:bg-primary checked:border-primary rounded-sm mr-1 ${checkboxProps.className}`}
      />
      &nbsp;
      <label
        htmlFor={checkboxProps.name}
        className="cursor-pointer text-sm text-grey-60"
        data-testid="checkboxLabel">
        {label}
      </label>
    </span>
  );
};

export default Checkbox;
