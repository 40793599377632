import React from "react";
import { DefaultComponentProps, SortDirection } from "types";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import Sort from "icons/Sort";

const Table = ({ children, ...props }: DefaultComponentProps): React.ReactElement => {
  return (
    <table
      {...props}
      className={cx(
        "w-full text-sm text-dark-gray border-separate border-spacing-y-4",
        props.className
      )}>
      {children}
    </table>
  );
};

const Head = ({ children, ...props }: DefaultComponentProps): React.ReactElement => {
  return (
    <thead {...props} className="text-bold text-grey-50 text-left">
      {children}
    </thead>
  );
};

const Body = ({ children, ...props }: DefaultComponentProps): React.ReactElement => {
  return (
    <tbody {...props} className="text-dark-grey">
      {children}
    </tbody>
  );
};

const Row = ({
  children,
  className,
  to,
  ...props
}: DefaultComponentProps & { to?: string }): React.ReactElement => {
  const navigate = useNavigate();
  return (
    <tr
      {...props}
      className={cx(className, to && "cursor-pointer")}
      onClick={(): void | undefined | "" => to && navigate(to)}>
      {children}
    </tr>
  );
};

const HeadData = ({
  children,
  className,
  selectedField,
  direction,
  field,
  handleSort,
  ...props
}: DefaultComponentProps & {
  colSpan?: number;
  field?: string;
  direction?: SortDirection;
  selectedField?: string;
  handleSort?: (field: string) => void;
}): React.ReactElement => {
  return (
    <th
      {...props}
      onClick={(): void => {
        if (handleSort && field) {
          handleSort(field);
        }
      }}
      scope="col"
      className={cx("px-4 py-2 md:px-6 md:py-3 last:text-right", className)}>
      <span className="inline-flex cursor-pointer items-center text-[15px]">
        <b className="mr-1">{children}</b>
        {handleSort && (
          <Sort
            className={cx(
              "text-grey-30 w-5 h-7",
              selectedField == field && direction == "ASC" && "[&_.arrowUp]:!text-primary",
              selectedField == field && direction == "DSC" && "[&_.arrowDown]:!text-primary"
            )}
          />
        )}
      </span>
    </th>
  );
};

const Data = ({
  children,
  className,
  ...props
}: DefaultComponentProps & { colSpan?: number }): React.ReactElement => {
  return (
    <td
      {...props}
      scope="col"
      className={cx(
        "px-4 py-2 md:px-6 md:py-3 bg-soft-grey first:rounded-l-lg last:rounded-r-lg first:!text-left last:text-right",
        className
      )}>
      {children}
    </td>
  );
};

Table.Head = Head;
Table.Body = Body;
Table.Row = Row;
Table.Data = Data;
Table.HeadData = HeadData;
export default Table;
