import React from "react";
import { AmountProps } from "types";
import CurrencySymbol from "../CurrencySymbol/CurrencySymbol";

const Amount = (props: AmountProps): React.ReactElement => {
  const { amount, currency } = props;

  return (
    <span data-testid="amount">
      {(amount || 0) < 0 && "-"}
      <CurrencySymbol currency={currency} />
      {amount ? Math.abs(amount).toLocaleString() : 0}
    </span>
  );
};

export default Amount;
