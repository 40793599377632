import React from "react";
import { DialogProps } from "types";
import cx from "classnames";

const Dialog = (props: DialogProps): React.ReactElement => {
  const {
    title,
    children,
    footer,
    isOpen,
    setIsOpen,
    large,
    titleClassName,
    contentClassName,
    titleBtn
  } = props;

  return (
    <div
      data-testid="dialog"
      tabIndex={-1}
      aria-hidden="true"
      className={cx(
        "hidden fixed w-full p-4 overflow-x-hidden overflow-y-auto inset-0 h-full z-50 justify-center items-center",
        isOpen && "!flex"
      )}>
      <div
        data-testid="dialogOverlay"
        className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50"
        onClick={(): void => setIsOpen(false)}></div>
      <div className={cx("relative w-full md:h-auto", large ? "max-w-4xl" : "max-w-2xl")}>
        <div className="relative bg-white rounded-lg shadow">
          <div
            className={cx(
              "flex items-start sm:items-center justify-between p-4 rounded-t",
              titleClassName
            )}>
            <div className="flex-1 flex items-center flex-wrap">
              <h3
                data-testid="dialogTitle"
                className="text-xl font-semibold text-gray-900 flex-1 min-w-[200px]">
                {title}
              </h3>
              {titleBtn}
            </div>

            <button
              data-testid="dialogCloseIcon"
              onClick={(): void => setIsOpen(false)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-hide="defaultModal">
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div
            className={cx("p-6 space-y-6 max-h-[85vh] overflow-y-auto", contentClassName)}
            data-testid="dialogContent">
            {children}
          </div>
          {footer && (
            <div className="flex items-center p-6 space-x-2 rounded-b" data-testid="dialogFooter">
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dialog;
