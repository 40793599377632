import api from "config/api";
import { FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { useState } from "react";
import { OrderTemplate, OrderTemplateLoaders, UseOrderTemplateType, Response } from "types";
import { AlertType } from "types/enum";

export const useOrderTemplate = (): UseOrderTemplateType => {
  const [templates, setTemplates] = useState<OrderTemplate[]>([]);
  const [template, setTemplate] = useState<OrderTemplate>();
  const [loaders, setLoaders] = useState<OrderTemplateLoaders>({
    fetchingTemplates: true,
    fetchingTemplate: false
  });
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const { showAlert } = useAlert();

  const handleGetTemplates = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingTemplates: true }));

    try {
      const json: Response<OrderTemplate[]> = await api.get("order-template").json();
      if (json.code === 200) {
        setTemplates(json.data);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingTemplates: false }));
  };

  const handleGetTemplate = async (templateId: string): Promise<OrderTemplate | undefined> => {
    setLoaders((prev) => ({ ...prev, fetchingTemplate: true }));

    try {
      const json: Response<OrderTemplate> = await api.get(`order-template/${templateId}`).json();
      if (json.code === 200) {
        return json.data;
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingTemplate: false }));
  };

  const handleDeleteTemplate = async (templateId: string): Promise<boolean> => {
    try {
      const json: Response<void> = await api.delete(`order-template/${templateId}`).json();
      return json.code === 200;
    } catch {
      return false;
    }
  };

  const handleConfirmDelete = (template: OrderTemplate): void => {
    setDeleteConfirmationMessage(
      `Are you sure you want to permanently delete ${template.name} template?`
    );
    setTemplate(template);
  };

  const handleDeleteConfirmation = async (): Promise<void> => {
    if (template?.id) {
      await handleDeleteTemplate(template?.id);
      handleDeleteCancellation();
      await handleGetTemplates();
    }
  };

  const handleDeleteCancellation = (): void => {
    setDeleteConfirmationMessage("");
    setTemplate(undefined);
  };

  const handlePreview = (template: OrderTemplate): void => {
    setTemplate(template);
    setIsOpen(true);
  };

  return {
    templates,
    loaders,
    handleConfirmDelete,
    handleDeleteConfirmation,
    deleteConfirmationMessage,
    handleDeleteCancellation,
    handlePreview,
    template,
    isOpen,
    setIsOpen,
    handleGetTemplate,
    handleGetTemplates
  };
};
