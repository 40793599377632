import Amount from "components/Shared/Amount";
import Disclosure from "components/Shared/Disclosure";
import Dropdown from "components/Shared/Dropdown";
import IconButton from "components/Shared/IconButton";
import Table from "components/Shared/Table";
import Title from "components/Shared/Title";
import Edit from "icons/Edit";
import Kebeb from "icons/Kebeb";
import Store from "icons/Store";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { StoreItemProduct, StoreItemRaw, StoreProps } from "types";
import Plus from "icons/Plus";
import CreditCard from "icons/CreditCard";
import Caret from "icons/Caret";
import { DropdownButton } from "components/Shared/DropdownButton/DropdownButton";
import { getStoreItemName } from "hooks/useStoreItems/useStoreItems.utils";
import { useSearch } from "hooks/shared/useSearch/useSearch";
import { SearchInput } from "components/Shared/SearchInput/SearchInput";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";

const StoreItem = (props: StoreProps): React.ReactElement => {
  const { storeItems } = props;
  const navigate = useNavigate();
  const search = useSearch<StoreItemRaw | StoreItemProduct, "type">();

  const filteredItems = search.advancedFilter(
    (item) => (item.type == "RAW_MATERIAL" ? item.name : `${item.productName} ${item.variantName}`),
    storeItems?.items || []
  );

  if (!storeItems || storeItems?.items.length === 0) {
    return (
      <div
        className="w-full h-full flex flex-col items-center justify-center"
        data-testid="noItems">
        <img src="images/svg/store.svg" />
        <Title className="pt-10 pb-3">
          Store items
          <InfoTooltip>
            These are the materials you buy or create and use directly in the process of creating
            your product. Store items should have a measurable quantity
          </InfoTooltip>
        </Title>
        <p className="text-grey-50 px-4 text-center">
          All materials involved in the creation of your products and services can be saved and
          tracked here
        </p>
        <DropdownButton
          items={[
            { text: "From a raw material", onClick: () => navigate("create") },
            { text: "From a product", onClick: () => navigate("create-product") }
          ]}>
          {(props) => (
            <IconButton icon={<Plus />}>
              Add new store item
              <Caret {...props} />
            </IconButton>
          )}
        </DropdownButton>
      </div>
    );
  }

  return (
    <div className="">
      <div className="flex sm:flex-row justify-between items-start sm:items-center px-4 md:px-0">
        <Title className="pt-2 md:pb-6 px-4 md:px-0">
          Store items
          <InfoTooltip>
            These are the materials you buy or create and use directly in the process of creating
            your product. Store items should have a measurable quantity
          </InfoTooltip>
        </Title>
        <DropdownButton
          items={[
            { text: "From a raw material", onClick: () => navigate("create") },
            { text: "From a product", onClick: () => navigate("create-product") }
          ]}>
          {(props) => (
            <IconButton icon={<Plus />}>
              Add new store item
              <Caret {...props} />
            </IconButton>
          )}
        </DropdownButton>
      </div>
      <div className="bg-white sm:rounded-lg p-4 lg:p-8 mt-2 md:mt-7">
        <div>
          <SearchInput {...search} />
        </div>
        {/* <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 text-white">
          <div className="w-full rounded bg-grey-60 p-3 md:p-6 flex justify-between">
            <div>
              <p className="text-sm md:text-base	font-semibold">Total store items</p>
              <h2 className="text-2xl md:text-4xl font-bold mt-2">{storeItems?.count}</h2>
            </div>
            <Store />
          </div>
          <div className="w-full rounded bg-gradient-to-r from-primary to-secondary p-3 md:p-6 flex justify-between">
            <div>
              <p className="text-sm md:text-base	font-semibold">Store total value</p>
              <h2 className="text-2xl md:text-4xl font-bold mt-2">
                <Amount amount={storeItems?.storeValue} />
              </h2>
            </div>
            <CreditCard />
          </div>
        </div> */}
        <div className="hidden sm:block">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.HeadData>Item</Table.HeadData>
                <Table.HeadData>In stock</Table.HeadData>
                <Table.HeadData>Amount</Table.HeadData>
                <Table.HeadData>Cost per unit</Table.HeadData>
                <Table.HeadData />
              </Table.Row>
            </Table.Head>
            <Table.Body className="text-dark-grey" data-testid="storeItems">
              {filteredItems.map(
                (item): React.ReactElement => (
                  <Table.Row
                    key={item.id}
                    className="rounded-lg"
                    data-testid="storeItem"
                    to={`${item.id}/edit`}>
                    <Table.Data data-testid="itemName">
                      <b>
                        {item.type == "RAW_MATERIAL" && item.name}
                        {item.type == "PRODUCT" && (
                          <>
                            <span>{item.productName}</span>
                            <br />
                            <small>{item.variantName}</small>
                          </>
                        )}
                      </b>
                    </Table.Data>
                    <Table.Data
                      data-testid="itemQuantity"
                      className={`${
                        item.availableStock < 0 ? "text-red-500" : ""
                      }`}>{`${item.availableStock}${item.unit.symbol}`}</Table.Data>
                    <Table.Data className="font-bold" data-testid="itemAmount">
                      <Amount amount={item.stockValue} />
                    </Table.Data>
                    <Table.Data data-testid="itemUnitCost">
                      <Amount amount={item.costPerUnit} /> {`per ${item.unit.symbol}`}
                    </Table.Data>
                    <Table.Data className="text-dark-grey">
                      <Dropdown
                        menu={[
                          {
                            text: "Edit",
                            icon: <Edit />,
                            link: `${item.id}/edit`
                          }
                        ]}>
                        <Kebeb />
                      </Dropdown>
                    </Table.Data>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </div>
        <div className="block sm:hidden mt-4">
          <div className="flex">
            <span className="font-bold text-grey-50 w-2/5 px-4">Item</span>
            <span className="font-bold text-grey-50 w-2/5 px-4">In stock</span>
            <span className="w-1/5"></span>
          </div>
          {filteredItems.map(
            (item): React.ReactElement => (
              <Disclosure key={item.id} data-testid="itemMobile">
                <Link className="flex" to={`${item.id}/edit`}>
                  <span className="font-bold w-2/5" data-testid="itemName">
                    <b>{getStoreItemName(item)}</b>
                  </span>
                  <span
                    className={`font-bold w-2/5 pl-4 ${
                      item.availableStock < 0 ? "text-red-500" : ""
                    }`}
                    data-testid="itemQuantity">
                    {`${item.availableStock}${item.unit.symbol}`}
                  </span>
                </Link>
                <div className="flex flex-col">
                  <small className="mt-2" data-testid="itemAmount">
                    <span className="font-bold w-24 inline-flex">Amount:</span>
                    <Amount amount={item.stockValue} />
                  </small>
                  <small className="mb-2" data-testid="itemUnitCost">
                    <span className="font-bold w-24 inline-flex">Cost per unit:</span>
                    <Amount amount={item.costPerUnit} /> {`per ${item.unit.symbol}`}
                  </small>
                  <span className="flex">
                    <Link to={`${item.id}/edit`} className="mobile-link">
                      <Edit className="mobile-icon" />
                      <span>Edit</span>
                    </Link>
                  </span>
                </div>
              </Disclosure>
            )
          )}
        </div>
        <div>
          {filteredItems.length == 0 && (
            <p className="text-sm p-4 md:py-0 md:px-6">No store items match your search criteria</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreItem;
