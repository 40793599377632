import { hookstate, State, useHookstate } from "@hookstate/core";
import { localstored } from "@hookstate/localstored";
import { AppState } from "types";

export const appState = hookstate<AppState>(
  {
    request: {
      hasTimedOut: false
    }
  },
  localstored({
    key: "bloom-app"
  })
);

export const useAppState = (): State<AppState> => {
  const state: State<AppState> = useHookstate<AppState>(appState);
  return state;
};
