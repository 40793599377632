import { PaymentMethods } from "components/PaymentMethods/PaymentMethods";
import LoadingComponent from "components/Shared/Loading";
import { usePaymentMethods } from "hooks/usePaymentMethods/usePaymentMethods";
import React from "react";

const PaymentMethodsPage = (): React.ReactElement => {
  const paymentMethodDetail = usePaymentMethods();

  return (
    <LoadingComponent loading={paymentMethodDetail.loaders.fetchingBusinessPaymentMethods}>
      <PaymentMethods {...paymentMethodDetail} />
    </LoadingComponent>
  );
};

export default PaymentMethodsPage;
