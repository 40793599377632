import React, { SVGProps } from "react";

const Customize = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="mask0_1346_3779" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1346_3779)">
        <path
          d="M3.75 3.25H11.25V10.75H3.75V3.25ZM13.75 3.25H21.25V10.75H13.75V3.25ZM3.75 13.25H11.25V20.75H3.75V13.25ZM16.75 13.25H18.25V16.25H21.25V17.75H18.25V20.75H16.75V17.75H13.75V16.25H16.75V13.25ZM15.25 4.74995V9.25H19.75V4.74995H15.25ZM5.24995 4.74995V9.25H9.75V4.74995H5.24995ZM5.24995 14.75V19.25H9.75V14.75H5.24995Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default Customize;
