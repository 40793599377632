import { DefaultComponentProps } from "types";
import React, { useState } from "react";
import cx from "classnames";
import Caret from "icons/Caret";

const Disclosure = (props: DefaultComponentProps & { large?: boolean }): React.ReactElement => {
  const { children, className, large, ...defaultProps } = props;
  const [open, setOpen] = useState(false);
  const height = large ? "h-10" : "h-6";

  return (
    <div
      {...defaultProps}
      className={cx(
        "bg-soft-grey rounded-lg p-4 my-2 text-dark-grey w-full flex justify-between relative",
        className
      )}>
      <div className={cx("flex-grow overflow-hidden", !open && height)}>{children}</div>
      <span className="text-primary cursor-pointer">
        <Caret open={open} onClick={(): void => setOpen(!open)} />
      </span>
      <span
        className="absolute left-0 right-0 top-0 h-10 lg:h-12 cursor-pointer"
        onClick={(): void => setOpen(!open)}
      />
    </div>
  );
};

export default Disclosure;
