import CreateEditMember from "components/CreateEditMember";
import useMembers from "hooks/useMembers";
import React from "react";

const CreateExpensePage = (): React.ReactElement => {
  const membersDetail = useMembers();

  return <CreateEditMember {...membersDetail} />;
};

export default CreateExpensePage;
