import OrderDetailComponent from "components/OrderDetail";
import React from "react";
import LoadingComponent from "components/Shared/Loading";
import useOrder from "hooks/useOrder";

const ViewOrderPage = (): React.ReactElement => {
  const {
    loaders,
    orderDetail,
    deleteConfirmationMessage,
    handleConfirmDelete,
    handleDeleteConfirmation,
    handleDeleteCancellation,
    handleDeletePayment,
    paymentForm,
    creatingPayment,
    setCreatingPayment,
    handleFormSubmit,
    handleFormChange
  } = useOrder();

  return (
    <LoadingComponent loading={loaders.fetchingOrder && !orderDetail}>
      {orderDetail && (
        <OrderDetailComponent
          orderDetail={orderDetail}
          handleConfirmDelete={handleConfirmDelete}
          deleteConfirmationMessage={deleteConfirmationMessage}
          handleDeleteConfirmation={handleDeleteConfirmation}
          handleDeleteCancellation={handleDeleteCancellation}
          handleDeletePayment={handleDeletePayment}
          paymentForm={paymentForm}
          creatingPayment={creatingPayment}
          setCreatingPayment={setCreatingPayment}
          handleFormSubmit={handleFormSubmit}
          handleFormChange={handleFormChange}
        />
      )}
    </LoadingComponent>
  );
};

export default ViewOrderPage;
