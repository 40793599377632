import React from "react";
import { ProductDetailProps } from "types";
import { Link } from "react-router-dom";
import IconButton from "components/Shared/IconButton";
import Plus from "icons/Plus";
import ArrowLeft from "icons/ArrowLeft";
import Title from "components/Shared/Title";
import Edit from "icons/Edit";
import Amount from "components/Shared/Amount";
import Table from "components/Shared/Table";
import Disclosure from "components/Shared/Disclosure";
import Dropdown from "components/Shared/Dropdown";
import Kebeb from "icons/Kebeb";
import Settings from "icons/Settings";
import Sort from "components/Shared/Sort";

const ProductDetail = (props: ProductDetailProps): React.ReactElement => {
  const { productDetail, variants, sort } = props;

  return (
    <div className="">
      <Link to="/products" className="font-bold text-sm	inline-flex px-4 md:px-0">
        <span className="text-primary mr-1">
          <ArrowLeft />
        </span>
        Back to products
      </Link>
      <div className="mt-3 flex flex-col sm:flex-row justify-between sm:items-center space-y-4 sm:space-y-0 px-4 md:px-0 mb-6">
        <Title data-testid="productName">{productDetail.name}</Title>
        <Link to={`/products/${productDetail.id}/configure`} data-testid="configure">
          <IconButton icon={<Settings />}>Configure product</IconButton>
        </Link>
      </div>
      <div className="">
        <div className="bg-white md:rounded-lg px-4 md:px-6 py-4 mt-4 md:h-auto">
          <p className="text-grey-50 px-4 md:px-0 mt-2 sm:mt-0" data-testid="variantDesc">
            These are different attributes of your master product.
          </p>
          <div className="overflow-y-unset hidden sm:block">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.HeadData {...sort} field="name" className="w-full">
                    Variants
                  </Table.HeadData>
                  <Table.HeadData {...sort} field="costTotal" className="whitespace-nowrap">
                    Cost price
                  </Table.HeadData>
                  <Table.HeadData
                    {...sort}
                    field="defaultSellingPrice"
                    className="whitespace-nowrap">
                    Selling price
                  </Table.HeadData>
                  <Table.HeadData {...sort} field="marginPercentage" className="whitespace-nowrap">
                    Margin
                  </Table.HeadData>
                  <Table.HeadData />
                </Table.Row>
              </Table.Head>
              <Table.Body data-testid="variants">
                {variants.map((variant) => (
                  <Table.Row
                    key={variant.id}
                    className="rounded-lg"
                    data-testid="variant"
                    to={`variants/${variant.id}/edit`}>
                    <Table.Data className="font-bold" data-testid="variantName">
                      {variant.name}
                    </Table.Data>
                    <Table.Data data-testid="variantCostPrice">
                      <Amount amount={+variant.cost.total} />
                    </Table.Data>
                    <Table.Data data-testid="variantSellingPrice">
                      <Amount amount={+variant.defaultSellingPrice} />
                    </Table.Data>
                    <Table.Data data-testid="variantSellingPrice">
                      <span
                        className={variant.marginPercentage < 0 ? "text-danger" : ""}
                        data-testid="variantMarginPercentage">{`${
                        variant.marginPercentage || 0
                      }%`}</span>
                    </Table.Data>
                    <Table.Data className="text-dark-grey">
                      <Dropdown
                        menu={[
                          {
                            text: "Edit",
                            icon: <Edit />,
                            link: `variants/${variant.id}/edit`
                          }
                        ]}>
                        <Kebeb />
                      </Dropdown>
                    </Table.Data>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
          <div className="block sm:hidden mt-4">
            <Sort
              {...sort}
              fields={[
                { field: "name", name: "Variants" },
                { field: "costTotal", name: "Cost price" },
                { field: "defaultSellingPrice", name: "Selling price" },
                { field: "marginPercentage", name: "Margin" }
              ]}
            />
            <div className="flex">
              <span className="font-bold text-grey-50 w-2/5 px-4">Variants</span>
              <span className="font-bold text-grey-50 w-2/5 px-4">Selling price</span>
              <span className="w-1/5"></span>
            </div>
            {variants.map(
              (variant): React.ReactElement => (
                <Disclosure key={variant.id}>
                  <Link className="flex" to={`variants/${variant.id}/edit`}>
                    <span className="font-bold w-1/2" data-testid="variantName">
                      {variant.name}
                    </span>
                    <span className="font-bold w-1/2" data-testid="variantSellingPrice">
                      <Amount amount={+variant.defaultSellingPrice} />
                    </span>
                  </Link>
                  <span className="flex flex-col mt-2">
                    <small className="mt-2" data-testid="variantCostPrice">
                      <span className="font-bold w-24 inline-flex">Cost Price:</span>{" "}
                      <Amount amount={+variant.cost.total} />
                    </small>
                    <Link to={`variants/${variant.id}/edit`} className="mobile-link">
                      <Edit className="mobile-icon" />
                      <span>Edit</span>
                    </Link>
                  </span>
                </Disclosure>
              )
            )}
          </div>
          {variants.length == 0 && (
            <div data-testid="noActivities" className="p-4 md:px-6">
              No variants created for this product
            </div>
          )}
          <Link to={`/products/${productDetail.id}/variants/create`} data-testid="createVariant">
            <IconButton icon={<Plus />} data-testid="createVariant" outlined>
              Add new variant
            </IconButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
