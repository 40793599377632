import React, { useEffect } from "react";
import { StepperProps } from "types";
import cx from "classnames";
import { useSearchParams } from "react-router-dom";

const Stepper = (props: StepperProps): React.ReactElement => {
  const { steps, activeStep, setActiveStep } = props;
  const [searchParams] = useSearchParams();
  const activeStepIndex = steps.findIndex((step) => activeStep === step);

  useEffect(() => {
    setActiveStep?.(searchParams.get("step") || activeStep);
  }, [searchParams.get("step")]);

  return (
    <div
      className="w-full flex justify-center items-center flex-col mt-6 sm:mt-0 px-4 sm:px-0"
      data-testid="stepper">
      <ol className="flex items-center w-full sm:w-4/5 md:w-2/3 px-5">
        {steps.map((step, index) => (
          <li
            data-testid="stepLineContainer"
            key={step}
            className={cx(
              "flex items-center",
              index !== steps.length - 1 &&
                "w-full text-dark-grey after:content-[''] after:w-full after:h-0 after:border-b after:border-2 after:inline-block",
              index < activeStepIndex && "after:border-dark-grey",
              index >= activeStepIndex && "after:border-grey-30"
            )}>
            <span
              data-testid="stepCircle"
              className={cx(
                "flex items-center justify-center rounded-full shrink-0",
                index === activeStepIndex ? "w-6 h-6" : "w-4 h-4",
                index <= activeStepIndex ? "bg-dark-grey" : "bg-grey-30"
              )}></span>
          </li>
        ))}
      </ol>
      <div className="flex justify-between w-full sm:w-4/5 md:w-2/3 mt-3">
        {steps.map((step, index) => (
          <span
            key={step}
            className={cx(
              `font-bold w-1/${steps.length}`,
              !(index === 0 || index === steps.length - 1) && "text-center",
              index <= activeStepIndex ? "text-dark-grey cursor-pointer" : "text-grey-30",
              index === steps.length - 1 && "text-right",
              index === 0 && "text-left"
            )}
            onClick={(): void => {
              if (index <= activeStepIndex) {
                setActiveStep?.(step);
              }
            }}>
            <span
              data-testid="stepText"
              data-active={index === activeStepIndex}
              className={cx(
                "z-10",
                index === activeStepIndex &&
                  "relative after:content-[''] after:w-full after:h-0 after:border-b after:border-8 after:absolute after:left-0 after:right-0 after:bottom-0 after:border-secondary after:-z-10"
              )}>
              {step}
            </span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
