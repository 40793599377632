import { CheckboxProps } from "types";
import React from "react";

const Radio = (props: CheckboxProps): React.ReactElement => {
  const { label, ...radioProps } = props;
  return (
    <span className="cursor-pointer">
      <input
        data-testid="checkboxInput"
        type="radio"
        id={radioProps.name}
        {...radioProps}
        className={`cursor-pointer bg-transparent border-grey-60 text-grey-60 focus:ring-0 focus:border-grey-60 focus:checked:bg-primary hover:checked:bg-primary checked:bg-primary checked:border-primary rounded-full mr-1 ${radioProps.className}`}
      />
      &nbsp;
      <label
        htmlFor={radioProps.id}
        className="cursor-pointer text-sm text-grey-60"
        data-testid="checkboxLabel">
        {label}
      </label>
    </span>
  );
};

export default Radio;
