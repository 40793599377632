import { getMonth, getFirstAndLastDayOfMonth } from "helpers/date";
import { useState } from "react";
import { DateRangeType, UseDateRangeType } from "types";

const useDateRange = (): UseDateRangeType => {
  const [dateRange, setDateRange] = useState<DateRangeType>({
    startDate: getMonth(),
    endDate: getMonth()
  });

  const [date, setDate] = useState<string>(getMonth());

  const handleDateChange = (date: string): void => {
    setDate(date);
  };

  const handleDateRangeChange = (range: DateRangeType): void => {
    setDateRange({
      startDate: range.startDate,
      endDate: range.endDate
    });
  };

  const getDateFilter = (): string => {
    return `startingFrom=${getFirstAndLastDayOfMonth(dateRange.startDate).startDate}&endingOn=${
      getFirstAndLastDayOfMonth(dateRange.endDate).endDate
    }`;
  };

  return {
    date,
    dateRange,
    handleDateChange,
    handleDateRangeChange,
    getDateFilter
  };
};

export default useDateRange;
