import { OrderInvoice } from "components/OrderInvoice/OrderInvoice";
import React from "react";
import LoadingComponent from "components/Shared/Loading";
import { useOrderInvoice } from "hooks/useOrderInvoice/useOrderInvoice";
import { Invoice } from "components/Invoice/Invoice";

const OrderInvoicePage = (): React.ReactElement => {
  const orderInvoiceProps = useOrderInvoice();

  return (
    <LoadingComponent loading={orderInvoiceProps.fetchingOrder}>
      <OrderInvoice {...orderInvoiceProps} paymentMethod={orderInvoiceProps.paymentMethodProps} />
      <div className="h-0 w-0 overflow-hidden">
        <Invoice {...orderInvoiceProps.invoiceProps} />
      </div>
    </LoadingComponent>
  );
};

export default OrderInvoicePage;
