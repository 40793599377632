import React from "react";
import { CreateEditProductProps } from "types";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Title from "components/Shared/Title";

const EditProduct = (props: CreateEditProductProps): React.ReactElement => {
  const { productForm, handleFormChange, handleFormSubmit, formIsValid, error, loaders } = props;

  return (
    <div className="bg-white rounded-lg p-4 lg:p-10 mobile-full-screen">
      <Link to="/products" className="font-bold text-sm	inline-flex" data-testid="backLink">
        <span className="text-primary mr-1">
          <ArrowLeft />
        </span>
        Back to products
      </Link>
      <Title className="my-8">Edit product</Title>
      <form
        onSubmit={handleFormSubmit}
        className="mt-10 mx-6 md:mx-12 lg:mx-12 xl:mx-20 2xl:mx-40"
        data-testid="productForm">
        <Error error={error} />
        <div>
          <TextInput
            type="text"
            name="name"
            onChange={handleFormChange}
            value={productForm.name}
            required
            label="Name"
            data-testid="nameInput"
          />
        </div>

        <Button
          type="submit"
          disabled={!formIsValid}
          loading={loaders.savingProduct}
          data-testid="submitButton"
          className="w-full mt-12">
          Save
        </Button>
      </form>
    </div>
  );
};

export default EditProduct;
