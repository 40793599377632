import api from "config/api";
import {
  ERROR_OCCURRED,
  FETCH_FAILED,
  PRODUCTION_ACTIVITY_ADDED,
  PRODUCTION_ACTIVITY_REMOVED,
  REQUEST_SUCCESSFUL
} from "constants/response";
import { track } from "helpers/analytics";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FormChangeEvent,
  Response,
  ObjectChanges,
  UseProductActivitiesType,
  ActivityTimeForm,
  ProductionActivity,
  ProductVariantActivity,
  ProductActivityLoaders,
  ProductVariantActivityDetails,
  ProductionActivities
} from "types";
import { AlertType, SegmentEvent } from "types/enum";
import deepEqual from "deep-equal";
import { updateProperty } from "helpers/object";
import { useAlert } from "context/alert/AlertContext";
import { useAutosave } from "react-autosave";
import { AUTOSAVE_INTERVAL } from "constants/general";
import useActivity from "hooks/useActivity";

const useProductActivity = (): UseProductActivitiesType => {
  const { productId } = useParams();
  const { showAlert } = useAlert();
  const { businessActivities, handleGetActivities } = useActivity();

  // UseStates
  const [activityTimeForm, setActivityTimeForm] = useState<ActivityTimeForm>({
    time: "",
    unit: "HOUR"
  });
  const [productActivities, setProductActivities] = useState<ProductionActivity[]>([]);
  const [productVariantActivities, setProductVariantActivities] = useState<
    ProductVariantActivity[]
  >([]);
  const [savedProductVariantActivities, setSavedProductVariantActivities] = useState<
    ProductVariantActivity[]
  >([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [loaders, setLoaders] = useState<ProductActivityLoaders>({
    savingActivities: false,
    fetchingActivities: true
  });
  const [error, setError] = useState("");
  const [variantsHaveChanges, setVariantsHaveChanges] = useState<ObjectChanges>({});
  useAutosave({
    data: productVariantActivities,
    onSave: async (): Promise<void> => {
      if (hasUnsavedChanges) {
        setLoaders((prev) => ({ ...prev, savingActivities: true }));
        await handleSaveActivityTime();
        setLoaders((prev) => ({ ...prev, savingActivities: false }));
      }
    },
    interval: AUTOSAVE_INTERVAL,
    saveOnUnmount: true
  });

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setActivityTimeForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleVariantActivitiesChange = (
    event: FormChangeEvent,
    variantId: string,
    productionActivityId: string
  ): void => {
    const { name, value } = event.target;
    setProductVariantActivities((prev) => {
      return prev.map((variant) => {
        const activityTimes = variant.activityTimes.map((time) =>
          updateProperty(time.productionActivityId === productionActivityId, time, {
            [name]: value
          })
        );
        return updateProperty(variant.variantId === variantId, variant, { activityTimes });
      });
    });
    setError("");
  };

  const handleAddActivity = async (
    businessActivityId: string,
    activityName: string
  ): Promise<boolean> => {
    setError("");
    const requestData = {
      productId,
      businessActivityId
    };

    try {
      const json: Response<string> = await api
        .post("production-activity", { json: requestData })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.PRODUCTION_ACTIVITY_ADDED, {
          productId,
          activityName
        });
        showAlert(AlertType.SUCCESS, PRODUCTION_ACTIVITY_ADDED);
        await handleGetProductActivities();
      } else {
        showAlert(AlertType.DANGER, ERROR_OCCURRED);
      }
      return isSuccessfull;
    } catch {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
      return false;
    }
  };

  const handleRemoveActivity = async (productionActivityId: string): Promise<boolean> => {
    setError("");
    try {
      const json: Response<void> = await api
        .delete(`production-activity/${productionActivityId}`)
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.PRODUCTION_ACTIVITY_REMOVED, {
          productId,
          productionActivityId
        });
        showAlert(AlertType.SUCCESS, PRODUCTION_ACTIVITY_REMOVED);
        await handleGetProductActivities();
      } else {
        showAlert(AlertType.DANGER, ERROR_OCCURRED);
      }
      return isSuccessfull;
    } catch {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
      return false;
    }
  };

  const handleSaveActivityTime = async (): Promise<boolean> => {
    const requestData = {
      variants: productVariantActivities.map(({ variantId, activityTimes }) => ({
        variantId,
        activityTimes: activityTimes.map(({ productionActivityId, time, unit }) => ({
          time: +time,
          unit,
          productionActivityId
        }))
      }))
    };

    try {
      const json: Response<string> = await api
        .put(`production-activity-time/${productId}`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        await handleGetProductActivities();
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingActivities: true }));
    const result = await handleSaveActivityTime();

    setLoaders((prev) => ({ ...prev, savingActivities: false }));
    if (result) {
      showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
    } else {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
    }
  };

  const handleGetProductActivities = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingActivities: true }));

    try {
      const json: Response<ProductionActivities> = await api
        .get(`production-activity/product/${productId}`)
        .json();
      if (json.code === 200) {
        setProductActivities(json.data.productionActivities);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingActivities: false }));
  };

  const handleGetProductVariantActivities = async (): Promise<void> => {
    try {
      const json: Response<ProductVariantActivityDetails> = await api
        .get(`production-activity-time/${productId}`)
        .json();
      if (json.code === 200) {
        setSavedProductVariantActivities(json.data.variants);
        // Set received activities to saved data if present
        setProductVariantActivities((prev) => {
          return json.data.variants.map((variant) => {
            const existingProductVariant = prev.find(
              (oldVariant) => oldVariant.variantId === variant.variantId
            );
            return updateProperty(!!existingProductVariant, variant, {
              activityTimes: variant.activityTimes.map((time) => {
                const existingTime = existingProductVariant?.activityTimes.find(
                  (oldTime) => oldTime.productionActivityId == time.productionActivityId
                );
                return !existingTime
                  ? time
                  : {
                      ...time,
                      time: +existingTime.time,
                      unit: existingTime.unit
                    };
              })
            });
          });
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // UseEffects
  useEffect(() => {
    setHasUnsavedChanges(!deepEqual(productVariantActivities, savedProductVariantActivities));
    const variantsHaveChanges: ObjectChanges = {};
    productVariantActivities.forEach((variant) => {
      variantsHaveChanges[variant.variantId] = !deepEqual(
        variant,
        savedProductVariantActivities.find(
          (savedVariant) => savedVariant.variantId == variant.variantId
        )
      );
    });
    setVariantsHaveChanges(variantsHaveChanges);
  }, [savedProductVariantActivities, productVariantActivities]);

  useEffect(() => {
    handleGetActivities();
    if (productId) {
      handleGetProductActivities();
    }
  }, []);

  useEffect(() => {
    // Get variant activities
    if (productId) {
      handleGetProductVariantActivities();
    }
  }, [productActivities]);

  return {
    activityTimeForm,
    productActivities,
    businessActivities,
    handleFormSubmit,
    handleFormChange,
    error,
    hasUnsavedChanges,
    handleVariantActivitiesChange,
    productId,
    handleAddActivity,
    handleRemoveActivity,
    loaders,
    productVariantActivities,
    variantsHaveChanges
  };
};

export default useProductActivity;
