import React from "react";
import { CreateEditProductVariantProps } from "types";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Title from "components/Shared/Title";
import Amount from "components/Shared/Amount";

const CreateEditProductVariant = (props: CreateEditProductVariantProps): React.ReactElement => {
  const {
    productVariantForm,
    handleProductVariantFormChange,
    handleProductVariantFormSubmit,
    formIsValid,
    error,
    loaders,
    variantId,
    productId
  } = props;
  const isCreatingNewVariant = !variantId;
  return (
    <div className="bg-white rounded-lg p-4 lg:p-10 mobile-full-screen">
      <Link to={`/products/${productId}`} className="font-bold text-sm flex" data-testid="backLink">
        <span className="text-primary mr-1">
          <ArrowLeft />
        </span>
        Back to variants
      </Link>
      <Title className="text-center mt-6 md:mt-0">
        <>{isCreatingNewVariant ? "Add new" : "Edit"} variant</>
      </Title>
      <p className="text-grey-50 text-center pb-4">
        Variants are different attributes of your product, such as colours, sizes, pricing,
        materials, or any other specific features.
      </p>
      <form
        onSubmit={handleProductVariantFormSubmit}
        className="mt-10 mx-6 md:mx-12 lg:mx-40"
        data-testid="productVariantForm">
        <Error error={error} />

        <div>
          <TextInput
            type="text"
            name="name"
            onChange={handleProductVariantFormChange}
            value={productVariantForm.name}
            required
            label="Name"
            data-testid="nameInput"
          />
        </div>
        <div>
          <TextInput
            type="number"
            name="defaultSellingPrice"
            onChange={handleProductVariantFormChange}
            value={productVariantForm.defaultSellingPrice}
            required={true}
            label={
              <>
                Selling Price <Amount amount={+productVariantForm.defaultSellingPrice} />
              </>
            }
            data-testid="defaultSellingPrice"
            min={0}
          />
        </div>
        <Button
          type="submit"
          data-testid="submitButton"
          disabled={!formIsValid}
          loading={loaders.savingProduct}
          className="w-full mt-6">
          Save
        </Button>
      </form>
    </div>
  );
};

export default CreateEditProductVariant;
