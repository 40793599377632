import React, { SVGProps } from "react";

const Info = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="mask0_542_3234" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_542_3234)">
        <path
          d="M9.37468 13.9583H10.6246V9.16669H9.37468V13.9583ZM9.99965 7.74042C10.1904 7.74042 10.3502 7.67592 10.4792 7.54692C10.6082 7.41792 10.6727 7.25806 10.6727 7.06735C10.6727 6.87666 10.6082 6.7168 10.4792 6.58779C10.3502 6.45879 10.1904 6.39429 9.99965 6.39429C9.80895 6.39429 9.64909 6.45879 9.52009 6.58779C9.39109 6.7168 9.32659 6.87666 9.32659 7.06735C9.32659 7.25806 9.39109 7.41792 9.52009 7.54692C9.64909 7.67592 9.80895 7.74042 9.99965 7.74042ZM10.001 17.9167C8.9061 17.9167 7.87691 17.7089 6.91347 17.2933C5.95001 16.8778 5.11194 16.3138 4.39926 15.6015C3.68656 14.8891 3.12235 14.0514 2.70661 13.0884C2.29088 12.1253 2.08301 11.0964 2.08301 10.0014C2.08301 8.90647 2.29079 7.87728 2.70634 6.91383C3.1219 5.95037 3.68586 5.1123 4.39822 4.39962C5.11059 3.68693 5.94829 3.12271 6.91132 2.70698C7.87433 2.29124 8.90331 2.08337 9.99826 2.08337C11.0932 2.08337 12.1224 2.29115 13.0858 2.70671C14.0493 3.12226 14.8874 3.68622 15.6001 4.39858C16.3127 5.11096 16.877 5.94866 17.2927 6.91169C17.7084 7.8747 17.9163 8.90368 17.9163 9.99862C17.9163 11.0936 17.7085 12.1228 17.293 13.0862C16.8774 14.0497 16.3135 14.8877 15.6011 15.6004C14.8887 16.3131 14.051 16.8773 13.088 17.2931C12.125 17.7088 11.096 17.9167 10.001 17.9167ZM9.99965 16.6667C11.8608 16.6667 13.4372 16.0209 14.7288 14.7292C16.0205 13.4375 16.6663 11.8611 16.6663 10C16.6663 8.13891 16.0205 6.56252 14.7288 5.27085C13.4372 3.97919 11.8608 3.33335 9.99965 3.33335C8.13854 3.33335 6.56215 3.97919 5.27049 5.27085C3.97882 6.56252 3.33299 8.13891 3.33299 10C3.33299 11.8611 3.97882 13.4375 5.27049 14.7292C6.56215 16.0209 8.13854 16.6667 9.99965 16.6667Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default Info;
