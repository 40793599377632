import SignInComponent from "components/SignIn";
import { useAppState } from "config/store";
import useSignIn from "hooks/useSignIn";
import useSSOAuth from "hooks/useSSOAuth/useSSOAuth";
import React from "react";

const SignInPage = (): React.ReactElement => {
  const props = useSignIn();
  const ssoProps = useSSOAuth();
  const appState = useAppState();
  const invite = appState.invite.get();
  return <SignInComponent {...props} {...ssoProps} invite={invite} />;
};

export default SignInPage;
