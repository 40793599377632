import {
  getDiscountAmount,
  getProductTotal,
  getTaxAmount,
  getTotal
} from "hooks/useOrderForm/useOrderForm.utils";
import { CreateEditOrderProps } from "types";
import React from "react";
import Amount from "components/Shared/Amount";
import Dialog from "components/Shared/Dialog";
import TextInput from "components/Shared/TextInput";
import CurrencySymbol from "components/Shared/CurrencySymbol/CurrencySymbol";
import Select from "components/Shared/Select";
import Edit from "icons/Edit";
import Info from "icons/Info";
import Button from "components/Shared/Button";

export const DiscountAndTax = ({
  orderForm,
  dialog,
  setDialog,
  handleFormChange
}: CreateEditOrderProps): React.ReactElement => {
  return (
    <div className="w-full">
      <hr className="flex-1 h-0.5 m-0 mt-3.5 sm:mt-3.5 border-soft-grey space-x-4" />
      <div className="form-x sm:pr-20 flex flex-col items-end justify font-bold relative py-6 space-y-3">
        {orderForm.discountValue > 0 ? (
          <span className="font-bold flex items-center space-x-2">
            <span>
              Discount {orderForm.discountType == "PERCENTAGE" && `(${orderForm.discountValue}%)`}:
            </span>{" "}
            <Amount amount={getDiscountAmount(orderForm)} />
            <button type="button" className="link" onClick={() => setDialog("discount")}>
              <Edit />
            </button>
          </span>
        ) : (
          <button type="button" className="link" onClick={() => setDialog("discount")}>
            Add discount
          </button>
        )}

        {orderForm.taxRate > 0 ? (
          <span className="font-bold flex items-center space-x-2">
            <span>Tax {`(${orderForm.taxRate}%)`}:</span> <Amount amount={getTaxAmount(orderForm)} />
            <button type="button" className="link" onClick={() => setDialog("tax")}>
              <Edit />
            </button>
          </span>
        ) : (
          <button type="button" className="link" onClick={() => setDialog("tax")}>
            Add tax
          </button>
        )}

        <span className="">
          Total: <Amount amount={getTotal(orderForm)} />
        </span>
      </div>

      <Dialog
        isOpen={dialog == "discount"}
        setIsOpen={(open: boolean): void => {
          setDialog(open ? "discount" : "");
        }}
        title="Discount">
        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="md:w-1/2">
            <Select
              inputSize="sm"
              id="discountType"
              name="discountType"
              value={orderForm.discountType}
              required
              onChange={handleFormChange}
              label="Type">
              <option value="" disabled>
                Select a type
              </option>
              <option value="PERCENTAGE">PERCENTAGE</option> <option value="AMOUNT">AMOUNT</option>
            </Select>
          </div>
          <div className="md:w-1/2 relative">
            <TextInput
              inputSize="sm"
              type="number"
              name="discountValue"
              id="discountValue"
              value={orderForm.discountValue}
              onChange={handleFormChange}
              label="Value"
              step={1}
              min={0}
              prepend={
                <span className="text-xl">
                  {orderForm.discountType == "PERCENTAGE" ? <span>%</span> : <CurrencySymbol />}
                </span>
              }
            />
            {((orderForm.discountType == "PERCENTAGE" && orderForm.discountValue > 100) ||
              (orderForm.discountType == "AMOUNT" &&
                orderForm.discountValue > getProductTotal(orderForm))) && (
              <span
                className="absolute text-xs font-medium bg-warning text-dark-warning rounded px-2 py-1 inline-flex items-center w-full"
                data-testid="emptyActivities">
                <Info /> &nbsp; Discount is more than order amount
              </span>
            )}
          </div>
        </div>
        <Button
          type="button"
          onClick={(): void => setDialog("")}
          className="w-full md:w-60"
          data-testid="continue">
          Continue
        </Button>
      </Dialog>

      <Dialog
        isOpen={dialog == "tax"}
        setIsOpen={(open: boolean): void => {
          setDialog(open ? "tax" : "");
        }}
        title="Tax">
        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="md:w-1/2">
            <TextInput
              inputSize="sm"
              type="text"
              name="taxName"
              id="taxName"
              value={orderForm.taxName}
              onChange={handleFormChange}
              label="Name"
            />
          </div>
          <div className="md:w-1/2 relative">
            <TextInput
              inputSize="sm"
              type="number"
              name="taxRate"
              id="taxRate"
              value={orderForm.taxRate}
              onChange={handleFormChange}
              label="Rate"
              step={1}
              min={0}
              prepend={<span>%</span>}
            />
            {orderForm.taxRate > 100 && (
              <span
                className="absolute text-xs font-medium bg-warning text-dark-warning rounded px-2 py-1 inline-flex items-center w-full"
                data-testid="emptyActivities">
                <Info /> &nbsp; Tax is more than 100%
              </span>
            )}
          </div>
        </div>
        <Button
          type="button"
          onClick={(): void => setDialog("")}
          className="w-full md:w-60"
          data-testid="continue">
          Continue
        </Button>
      </Dialog>
    </div>
  );
};
