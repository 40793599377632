import { ERROR_OCCURRED } from "constants/response";
import React from "react";

const ErrorPage = (): React.ReactElement => {
  return (
    <div>
      <div className="text-center h-screen w-screen flex flex-col items-center justify-center">
        <p>{ERROR_OCCURRED}</p>
        <button
          onClick={() => window.location.reload()}
          className={
            "rounded-full px-5 py-2 text-center bg-gradient-to-r from-primary to-secondary text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-primary"
          }>
          Retry
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
