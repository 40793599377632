import React, { SVGProps } from "react";
import cx from "classnames";

const Caret = (props?: SVGProps<SVGSVGElement> & { open?: boolean }): React.ReactElement => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      {...props}
      className={cx("w-4 h-4 ml-2 caret", props?.className, props?.open && "rotate-180")}>
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M19 9l-7 7-7-7"></path>
    </svg>
  );
};

export default Caret;
