import React, { createContext, useContext, useState } from "react";
import { AlertContextType } from "types";
import { AlertType } from "types/enum";
import { v4 } from "uuid";

const defaultState: AlertContextType = {
  alerts: [],
  showAlert: () => {
    /**/
  },
  removeAlert: () => {
    /**/
  }
};

export const AlertContext = createContext<AlertContextType>(defaultState);

export const AlertContextProvider = ({
  children
}: {
  children: React.ReactElement;
}): JSX.Element => {
  const [alerts, setAlerts] = useState(defaultState.alerts);

  const showAlert = (type: AlertType, message?: React.ReactNode): void => {
    const id = v4();

    setAlerts((prev) => {
      const existingAlert = prev.find((alert) => alert.type == type && alert.message == message);
      if (existingAlert) {
        return prev;
      }

      return [...prev, { type, message, id }];
    });

    setTimeout(() => {
      removeAlert(id);
    }, 5000);
  };

  const removeAlert = (alertId: string): void => {
    setAlerts((prev) => prev.filter(({ id }) => id != alertId));
  };

  const contextValues: AlertContextType = {
    alerts,
    showAlert,
    removeAlert
  };

  return <AlertContext.Provider value={contextValues}>{children}</AlertContext.Provider>;
};

export const useAlert = (): AlertContextType => useContext(AlertContext);
