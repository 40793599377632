import CreateProduct from "components/CreateProduct";
import useProduct from "hooks/useProduct";
import React from "react";

const CreateExpensePage = (): React.ReactElement => {
  const props = useProduct();

  return <CreateProduct {...props} />;
};

export default CreateExpensePage;
