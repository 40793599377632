import { ProductAddOnProps } from "types";
import React, { useState } from "react";
import Autocomplete from "components/Shared/Autocomplete";
import Cancel from "icons/Cancel";
import Accordion from "components/Shared/Accordion";
import TextInput from "components/Shared/TextInput";
import Info from "icons/Info";
import IconButton from "components/Shared/IconButton";
import ArrowLeft from "icons/ArrowLeft";
import ArrowRight from "icons/ArrowRight";
import Button from "components/Shared/Button";
import Error from "components/Shared/Error";
import Amount from "components/Shared/Amount";
import Dialog from "components/Shared/Dialog";
import { AutosaveButton } from "components/Shared/AutosaveButton/AutosaveButton";
import CurrencySymbol from "components/Shared/CurrencySymbol/CurrencySymbol";

const AddOns = (props: ProductAddOnProps): React.ReactElement => {
  const {
    handleAddAddOn,
    handleRemoveAddOn,
    productVariantAddOns,
    handleVariantAddOnsChange,
    businessAddOns,
    productAddOns,
    loaders,
    handleFormSubmit,
    handleCostFormSubmit,
    handleFormChange,
    error,
    hasUnsavedChanges,
    variantsHaveChanges,
    productName,
    addOnForm
  } = props;
  const [activeKey, setActiveKey] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <form className="pb-40" onSubmit={handleCostFormSubmit} data-testid="addOnForm">
        <div className="bg-white md:rounded-lg px-4 md:px-6 py-4 mt-4 md:h-auto">
          <Error error={error} />
          <div>
            <Autocomplete
              type="text"
              name="addOns"
              onDataSelected={(data) => {
                if (data) {
                  handleAddAddOn(data.name);
                }
              }}
              value=""
              label="Add Add-on"
              data-testid="addOns"
              dataIdName="addOnId"
              data={
                businessAddOns
                  .filter(
                    (businessAddOn) =>
                      !productAddOns.find(
                        (productAddOn) => businessAddOn.id === productAddOn.businessAddOnId
                      )
                  )
                  .map((item) => ({ id: item.id, name: item.name })) || []
              }
              placeholder="Start typing to filter business add-ons"
              defaultData={
                <span className="text-grey-50 text-sm">
                  Can’t see what you are looking for?&nbsp;
                  <span className="link inline-flex" onClick={() => setIsOpen(true)}>
                    <b>Create a new add-on</b>
                  </span>
                </span>
              }
            />
            <div>
              <p className="font-bold">Add-ons in use</p>
              <div className="flex mt-4 flex-wrap" data-testid="productAddOns">
                {productAddOns.map((addOn) => (
                  <span
                    data-testid="productAddOn"
                    key={addOn.id}
                    className="border border-grey-30 text-dark-grey cursor-pointer rounded-md py-2 px-4 flex items-center space-x-2 font-bold mb-2 mr-3">
                    <span>{addOn.name}</span>
                    <Cancel
                      data-testid="removeIcon"
                      width={20}
                      height={20}
                      onClick={() => handleRemoveAddOn(addOn.id)}
                    />
                  </span>
                ))}
              </div>
            </div>
          </div>
          <p className="text-grey-50 px-4 mb-4 md:px-0 mt-2 sm:mt-6" data-testid="">
            {productAddOns.length > 0
              ? `Set the add-on costs for ${productName} variants.`
              : "No add-ons in use."}
          </p>
          {productVariantAddOns.map(
            (productVariantAddOn, productVariantAddOnIndex): React.ReactElement => (
              <Accordion
                key={productVariantAddOn.variantId}
                itemId={productVariantAddOn.variantId}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                header={
                  <div className="inline-flex items-center justify-between flex-1 pr-6">
                    <span>
                      <b data-testid="variantName">{productVariantAddOn.variantName}</b>
                      {productVariantAddOn.addOnCosts.filter((addOnCost) => addOnCost.cost == 0)
                        .length > 0 && (
                        <span
                          className="ml-4 text-xs font-medium bg-warning text-dark-warning rounded px-2 py-1 inline-flex items-center"
                          data-testid="emptyAddOnCosts">
                          <Info /> &nbsp;
                          {
                            productVariantAddOn.addOnCosts.filter(
                              (addOnCost) => addOnCost.cost == 0
                            ).length
                          }
                        </span>
                      )}
                    </span>
                    {!variantsHaveChanges[productVariantAddOn.variantId] && (
                      <Amount amount={productVariantAddOn.totalCost} />
                    )}
                  </div>
                }>
                <div className="flex flex-col">
                  {productVariantAddOn.addOnCosts.map((addOnCost) => (
                    <div
                      className="flex justify-between items-center pt-2 px-2 md:px-4  border-b border-grey-30"
                      key={addOnCost.addOnId}
                      data-testid="addOnCost">
                      <span data-testid="addOnName" className="flex-1">
                        {
                          productAddOns.find((productAddOn) => productAddOn.id == addOnCost.addOnId)
                            ?.name
                        }
                      </span>
                      <div className="flex-1 max-w-xs">
                        <TextInput
                          type="number"
                          name="cost"
                          id="amount"
                          value={addOnCost.cost}
                          required={true}
                          onChange={(e): void =>
                            handleVariantAddOnsChange(
                              e,
                              productVariantAddOn.variantId,
                              addOnCost.addOnId
                            )
                          }
                          label={
                            <>
                              Cost <Amount amount={+addOnCost.cost} />
                            </>
                          }
                          data-testid="amountInput"
                          inputSize="sm"
                          prepend={
                            <span className="text-md">
                              <CurrencySymbol />
                            </span>
                          }
                        />
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-between items-center py-2 px-4 border-grey-30 bg-soft-grey text-primary">
                    <div>
                      {productVariantAddOnIndex > 0 && (
                        <IconButton
                          size="sm"
                          onClick={() =>
                            setActiveKey(
                              productVariantAddOns[productVariantAddOnIndex - 1].variantId
                            )
                          }
                          icon={<ArrowLeft />}
                          data-testid="prev"
                          outlined>
                          Previous
                        </IconButton>
                      )}
                    </div>
                    <div>
                      {productVariantAddOnIndex < productVariantAddOns.length - 1 && (
                        <IconButton
                          size="sm"
                          onClick={() =>
                            setActiveKey(
                              productVariantAddOns[productVariantAddOnIndex + 1].variantId
                            )
                          }
                          iconEnd={<ArrowRight />}
                          data-testid="next"
                          outlined>
                          Next variant
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              </Accordion>
            )
          )}
        </div>
        <div className="fixed left-0 right-0 bottom-0 bg-white sm:px-12 xl:px-40 py-6 text-center md:text-right z-10">
          <AutosaveButton
            disabled={!hasUnsavedChanges}
            hasChanges={hasUnsavedChanges}
            type="submit"
            loading={loaders.savingAddOns}
            data-testid="submitButton"
            className="w-60"
          />
        </div>
      </form>

      <Dialog isOpen={isOpen} setIsOpen={setIsOpen} title="Create Add-on">
        <form
          onSubmit={(e) => {
            handleFormSubmit(e);
            setIsOpen(false);
          }}
          className=""
          data-testid="addOnNameForm">
          <Error error={error} />
          <div>
            <TextInput
              type="text"
              name="name"
              onChange={handleFormChange}
              value={addOnForm.name}
              required
              label="Add-on name"
              data-testid="addOnName"
            />
          </div>
          <Button
            type="submit"
            data-testid="addOnSubmitButton"
            disabled={!addOnForm.name}
            loading={loaders.savingAddOns}
            className="w-full mt-6">
            Save
          </Button>
        </form>
      </Dialog>
    </div>
  );
};

export default AddOns;
