import React from "react";

export const BarChart = (): React.ReactElement => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1578_3954" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="41">
        <rect width="41" height="41" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1578_3954)">
        <path
          d="M7.26038 33.7396V15.8021H13.2395V33.7396H7.26038ZM17.5104 33.7396V7.26041H23.4895V33.7396H17.5104ZM27.7604 33.7396V22.6354H33.7395V33.7396H27.7604Z"
          fill="#00A89D"
        />
      </g>
    </svg>
  );
};
