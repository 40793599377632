import React, { SVGProps } from "react";

const Power = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_57_2776" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_57_2776)">
        <path
          d="M11.25 13V3.42499H12.75V13H11.25ZM12 20.5C10.8333 20.5 9.73333 20.275 8.7 19.825C7.66667 19.375 6.76667 18.7667 6 18C5.23333 17.2333 4.625 16.3333 4.175 15.3C3.725 14.2667 3.5 13.1667 3.5 12C3.5 10.7333 3.76667 9.54165 4.3 8.42499C4.83333 7.30832 5.575 6.34165 6.525 5.52499L7.55 6.54999C6.75 7.21665 6.125 8.02499 5.675 8.97499C5.225 9.92499 5 10.9333 5 12C5 13.9333 5.68333 15.5833 7.05 16.95C8.41667 18.3167 10.0667 19 12 19C13.95 19 15.604 18.3167 16.962 16.95C18.3207 15.5833 19 13.9333 19 12C19 10.9333 18.779 9.92499 18.337 8.97499C17.8957 8.02499 17.2667 7.21665 16.45 6.54999L17.475 5.52499C18.4417 6.34165 19.1873 7.30832 19.712 8.42499C20.2373 9.54165 20.5 10.7333 20.5 12C20.5 13.1667 20.275 14.2667 19.825 15.3C19.375 16.3333 18.7667 17.2333 18 18C17.2333 18.7667 16.3373 19.375 15.312 19.825C14.2873 20.275 13.1833 20.5 12 20.5Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default Power;
