import { StoreItemProduct, StoreItemRaw } from "types";

export const getStoreItemName = (item: StoreItemRaw | StoreItemProduct): string => {
  if (item.type == "RAW_MATERIAL") {
    return item.name;
  }

  if (item.type == "PRODUCT") {
    return `${item.productName} (${item.variantName})`;
  }
  return "";
};
