import React from "react";
import { Link } from "react-router-dom";
import { AuthLayoutProps } from "types";

const AuthLayout = (props: AuthLayoutProps): React.ReactElement => {
  const { children, image, header } = props;
  return (
    <div className="w-screen h-screen px-6 sm:px-12 xl:px-40 pt-6 pr sm:pt-10 flex flex-col">
      <header className="w-full flex justify-between flex-row items-center">
        <Link to="/" data-testid="homeLink">
          <img src="logo.svg" alt="Bloom Logo" className="w-32 sm:w-44" />
        </Link>
        {header}
      </header>
      <div className="w-full py-6 flex flex-1">
        <div className="hidden md:flex w-1/2 h-full pr-4 bg-soft-grey">{image}</div>
        <div className="w-full md:w-1/2 h-full flex flex-col md:pl-4 pt-10 sm:pt-0 sm:justify-center">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
