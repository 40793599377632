import ProductConfiguration from "components/ProductConfiguration";
import useProduct from "hooks/useProduct";
import React, { useEffect } from "react";
import LoadingComponent from "components/Shared/Loading";
import useProductComponent from "hooks/useProductComponent";
import useStoreItems from "hooks/useStoreItems";
import useProductAddOn from "hooks/useProductAddOn";
import useProductActivity from "hooks/useProductActivities/useProductActivities";

const ConfigureProductPage = (): React.ReactElement => {
  const { loaders, productDetail } = useProduct();
  const productComponents = useProductComponent();
  const productAddOns = useProductAddOn();
  const productActivities = useProductActivity();
  const { storeItems, handleGetStoreItems, measurementUnits } = useStoreItems();
  useEffect(() => {
    handleGetStoreItems();
  }, []);

  return (
    <LoadingComponent loading={loaders.fetchingProduct && !productDetail}>
      {productDetail && (
        <ProductConfiguration
          productDetail={productDetail}
          productComponents={productComponents}
          storeItems={storeItems}
          measurementUnits={measurementUnits}
          productAddOns={productAddOns}
          productActivities={productActivities}
        />
      )}
    </LoadingComponent>
  );
};

export default ConfigureProductPage;
