import React from "react";
import { CreateEditMemberProps } from "types";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Title from "components/Shared/Title";
import { SettingsHeaderTab } from "components/Settings/Settings";
import Toggle from "components/Shared/Toggle";
import Hidden from "components/Shared/Hidden";
import { FeatureFlag } from "types/enum";

const CreateEditMember = (props: CreateEditMemberProps): React.ReactElement => {
  const {
    memberForm,
    handleFormChange,
    handleFormSubmit,
    formIsValid,
    error,
    loaders,
    handleTogglePermission,
    memberId,
    member
  } = props;
  const isCreatingNewMember = !memberId;

  return (
    <div className="px-4 sm:px-0 pb-8">
      <div className="space-y-4">
        <SettingsHeaderTab selectedIndex={1} />

        <div className="bg-white rounded-lg p-4 lg:p-6">
          <Link
            to="/settings/members"
            className="font-bold text-sm inline-flex"
            data-testid="backLink">
            <span className="text-primary mr-1">
              <ArrowLeft />
            </span>
            Back to all members
          </Link>
          <Title className="mt-6 md:mt-0">
            {isCreatingNewMember ? "Invite new member" : `Edit ${member?.name}`}
          </Title>
          {isCreatingNewMember && (
            <p className="text-grey-50 pb-4">Create a new user to help manage this account</p>
          )}
          <form onSubmit={handleFormSubmit} className="mt-5 lg:w-2/3" data-testid="memberForm">
            <Error error={error} />

            <div>
              <TextInput
                disabled={!isCreatingNewMember}
                type="email"
                name="email"
                onChange={handleFormChange}
                value={memberForm.email}
                required
                label="Email address"
                data-testid="emailInput"
              />
            </div>
            <div className="flex flex-col">
              <p className="text-grey-50 pb-4">Permissions</p>

              <Toggle
                checked={true}
                disabled={true}
                id="recordOrders"
                name="recordOrders"
                data-testid="recordOrders"
                label="Record Orders"
              />
              <Toggle
                checked={true}
                disabled={true}
                id="recordExpenses"
                name="recordExpenses"
                data-testid="recordExpenses"
                label="Record Expenses"
              />
              <Hidden flag={FeatureFlag.STORE}>
                <Toggle
                  checked={true}
                  disabled={true}
                  id="recordStoreItems"
                  name="recordStoreItems"
                  data-testid="recordStoreItems"
                  label="Record Store Items"
                />
              </Hidden>
              <Toggle
                checked={memberForm.permissions.CAN_VIEW_BUSINESS_FINANCIAL_ANALYSIS}
                onChange={() => handleTogglePermission("CAN_VIEW_BUSINESS_FINANCIAL_ANALYSIS")}
                id="viewFinancialAnalysis"
                name="viewFinancialAnalysis"
                data-testid="viewFinancialAnalysis"
                label="View Financial Analysis"
              />
              <Toggle
                checked={memberForm.permissions.CAN_VIEW_ALL_EXPENSES}
                onChange={() => handleTogglePermission("CAN_VIEW_ALL_EXPENSES")}
                id="viewAllExpenses"
                name="viewAllExpenses"
                data-testid="viewAllExpenses"
                label="View All Expenses"
              />
              <Toggle
                checked={memberForm.permissions.CAN_VIEW_ALL_ORDERS}
                onChange={() => handleTogglePermission("CAN_VIEW_ALL_ORDERS")}
                id="viewAllOrders"
                name="viewAllOrders"
                data-testid="viewAllOrders"
                label="View All Orders"
              />
              <Toggle
                checked={memberForm.permissions.CAN_VIEW_ALL_STORE_ITEMS}
                onChange={() => handleTogglePermission("CAN_VIEW_ALL_STORE_ITEMS")}
                id="viewAllStoreItems"
                name="viewAllStoreItems"
                data-testid="viewAllStoreItems"
                label="View All Store Items"
              />
              <Toggle
                checked={memberForm.permissions.CAN_VIEW_PRODUCTS}
                onChange={() => handleTogglePermission("CAN_VIEW_PRODUCTS")}
                id="viewProducts"
                name="viewProducts"
                data-testid="viewProducts"
                label="View Products"
              />
            </div>
            <Button
              type="submit"
              data-testid="submitButton"
              disabled={!formIsValid}
              loading={loaders.savingMember}
              className="w-full lg:w-auto mt-6 px-20">
              {isCreatingNewMember ? "Send invite" : "Save changes"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateEditMember;
