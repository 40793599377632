import CreateProductVariant from "components/CreateEditProductVariant";
import useProduct from "hooks/useProduct";
import React from "react";

const CreateProductVariantPage = (): React.ReactElement => {
  const props = useProduct();

  return <CreateProductVariant {...props} />;
};

export default CreateProductVariantPage;
