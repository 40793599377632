import api from "config/api";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { InvoiceDetail, InvoiceLoaders, Response, UseInvoiceType } from "types";
import html2pdf from "html2pdf.js";
import { getDay } from "helpers/date";

export const useInvoice = (): UseInvoiceType => {
  const { orderId } = useParams();
  const [invoiceDetail, setInvoiceDetail] = useState<InvoiceDetail>();
  const [loaders, setLoaders] = useState<InvoiceLoaders>({
    fetchingInvoice: true
  });
  const invoiceRef = useRef(null);

  const handleGetInvoiceByOrder = async (orderId: string): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingInvoice: true }));

    try {
      const json: Response<InvoiceDetail> = await api.get(`order/${orderId}/invoice`).json();
      if (json.code === 200) {
        setInvoiceDetail(json.data);
      }
    } catch (err) {
      // showAlert(AlertType.DANGER, FETCH_FAILED);
      // console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingInvoice: false }));
  };

  const handleDownload = async () => {
    const options = {
      margin: 0,
      filename: `Invoice-${invoiceDetail?.ref}-${getDay()}.pdf`,
      pagebreak: { avoid: ".no-break" },
      image: { type: "jpeg", quality: 0.7 },
      html2canvas: { scale: 2, y: 0, scrollY: 0, useCORS: true },
      jsPDF: { orientation: "portrait" }
    };

    const content = invoiceRef.current;
    await html2pdf().set(options).from(content).save();
  };

  // UseEffects
  useEffect(() => {
    if (orderId) {
      handleGetInvoiceByOrder(orderId);
    }
  }, []);

  return {
    invoiceDetail,
    loaders,
    invoiceRef,
    handleDownload,
    handleGetInvoiceByOrder
  };
};
