import api from "config/api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FormChangeEvent,
  OrderProductionDetail,
  OrderProductionLoaders,
  Response,
  UseOrderProductionType
} from "types";
import deepEqual from "deep-equal";
import { updateProperty } from "helpers/object";
import { useAlert } from "context/alert/AlertContext";
import { AlertType } from "types/enum";
import { FETCH_FAILED } from "constants/response";
import { useAutosave } from "react-autosave";
import { AUTOSAVE_INTERVAL } from "constants/general";
import { removeAtIndex } from "helpers/array";

const useOrderProduction = (): UseOrderProductionType => {
  const { orderId } = useParams();
  const { showAlert } = useAlert();

  const [productionDetail, setProductionDetail] = useState<OrderProductionDetail>();
  const [savedProductionDetail, setSavedProductionDetail] = useState<OrderProductionDetail>();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [loaders, setLoaders] = useState<OrderProductionLoaders>({
    savingProductionDetails: false,
    fetchingProductionDetails: true
  });
  const [error, setError] = useState("");
  useAutosave({
    data: productionDetail,
    onSave: async (): Promise<void> => {
      if (hasUnsavedChanges) {
        setLoaders((prev) => ({ ...prev, savingProductionDetails: true }));
        const result = await Promise.all([
          await handleSaveMaterials(),
          await handleSaveAddOns(),
          await handleSaveActivities()
        ]);
        if (result.some((res) => res === true)) {
          await handleGetOrderProductionDetails();
        }
        setLoaders((prev) => ({ ...prev, savingProductionDetails: false }));
      }
    },
    interval: AUTOSAVE_INTERVAL,
    saveOnUnmount: true
  });

  const handleProductionDetailsChange = (
    event: FormChangeEvent,
    variantId: string,
    storeItemId: string
  ): void => {
    const { name, value } = event.target;
    setProductionDetail((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        variants: prev?.variants.map((variant) => {
          const materials = variant.materials.map((material) =>
            updateProperty(material.storeItemId === storeItemId, material, { [name]: value })
          );
          return updateProperty(variant.id === variantId, variant, { materials });
        })
      };
    });
    setError("");
  };

  const handleProductionAddOnDetailsChange = (
    event: FormChangeEvent,
    variantId: string,
    addOnId: string
  ): void => {
    const { name, value } = event.target;
    setProductionDetail((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        variants: prev?.variants.map((variant) => {
          const services = variant.services.map((addOn) =>
            updateProperty(addOn.businessAddOnId === addOnId, addOn, { [name]: value })
          );
          return updateProperty(variant.id === variantId, variant, { services });
        })
      };
    });
    setError("");
  };

  const handleProductionActivityDetailsChange = (
    event: FormChangeEvent,
    variantId: string,
    activityId: string
  ): void => {
    const { name, value } = event.target;
    setProductionDetail((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        variants: prev?.variants.map((variant) => {
          const activities = variant.activities.map((activity) =>
            updateProperty(activity.businessActivityId === activityId, activity, { [name]: value })
          );
          return updateProperty(variant.id === variantId, variant, { activities });
        })
      };
    });
    setError("");
  };

  const handleSaveMaterials = async (): Promise<boolean> => {
    const requestData = {
      materials: productionDetail?.variants
        .map((variant) =>
          variant?.materials.map((material) => ({
            storeItemId: material.storeItemId,
            orderItemId: variant.orderItemId,
            measurementUnit: material.measurementUnit,
            measurementValue: material.measurementValue,
            variantId: variant.id
          }))
        )
        .flat(1)
    };

    try {
      const json: Response<string> = await api
        .put(`output/order/${orderId}/materials`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleSaveAddOns = async (): Promise<boolean> => {
    const requestData = {
      services: productionDetail?.variants
        .map((variant) =>
          variant?.services.map((addOn) => ({
            businessAddOnId: addOn.businessAddOnId,
            orderItemId: variant.orderItemId,
            cost: +addOn.cost,
            variantId: variant.id
          }))
        )
        .flat(1)
    };

    try {
      const json: Response<string> = await api
        .put(`output/order/${orderId}/external-services`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleSaveActivities = async (): Promise<boolean> => {
    const requestData = {
      activities: productionDetail?.variants
        .map((variant) =>
          variant?.activities.map((activity) => ({
            businessActivityId: activity.businessActivityId,
            orderItemId: variant.orderItemId,
            activityTime: +activity.activityTime,
            unit: activity.unit,
            variantId: variant.id
          }))
        )
        .flat(1)
    };

    try {
      const json: Response<string> = await api
        .put(`output/order/${orderId}/activities`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingProductionDetails: true }));
    const result = await handleSaveMaterials();

    setLoaders((prev) => ({ ...prev, savingProductionDetails: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
    } else {
      showAlert(AlertType.DANGER);
    }
  };

  const handleAddonFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingProductionDetails: true }));
    const result = await handleSaveAddOns();

    setLoaders((prev) => ({ ...prev, savingProductionDetails: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
    } else {
      showAlert(AlertType.DANGER);
    }
  };

  const handleActivityFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingProductionDetails: true }));
    const result = await handleSaveActivities();

    setLoaders((prev) => ({ ...prev, savingProductionDetails: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
    } else {
      showAlert(AlertType.DANGER);
    }
  };

  const handleGetOrderProductionDetails = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingProductionDetails: true }));

    try {
      const json: Response<OrderProductionDetail> = await api.get(`output/order/${orderId}`).json();
      if (json.code === 200) {
        setProductionDetail(json.data);
        setSavedProductionDetail(json.data);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingProductionDetails: false }));
  };

  const handleAddMaterial = (variantId: string): void => {
    setProductionDetail((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        variants: prev?.variants.map((variant) => {
          const materials = [
            ...variant.materials,
            {
              id: "",
              name: "",
              storeItemId: "",
              unit: {
                name: "",
                category: "",
                symbol: ""
              },
              measurementUnit: "",
              measurementValue: 0,
              cost: 0
            }
          ];
          return updateProperty(variant.id === variantId, variant, { materials });
        })
      };
    });
  };

  const handleRemoveMaterial = (materialIndex: number, variantId: string): void => {
    setProductionDetail((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        variants: prev?.variants.map((variant) => {
          const materials = removeAtIndex(variant.materials, materialIndex);
          return updateProperty(variant.id === variantId, variant, { materials });
        })
      };
    });
    setError("");
  };

  const handleAddAddOn = (variantId: string): void => {
    setProductionDetail((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        variants: prev?.variants.map((variant) => {
          const services = [
            ...variant.services,
            {
              id: "",
              name: "",
              businessAddOnId: "",
              cost: 0
            }
          ];
          return updateProperty(variant.id === variantId, variant, { services });
        })
      };
    });
  };

  const handleRemoveAddOn = (addOnIndex: number, variantId: string): void => {
    setProductionDetail((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        variants: prev?.variants.map((variant) => {
          const services = removeAtIndex(variant.services, addOnIndex);
          return updateProperty(variant.id === variantId, variant, { services });
        })
      };
    });
    setError("");
  };

  const handleAddActivity = (variantId: string): void => {
    setProductionDetail((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        variants: prev?.variants.map((variant) => {
          const activities = [
            ...variant.activities,
            {
              id: "",
              name: "",
              businessActivityId: "",
              cost: 0,
              activityTime: 0,
              unit: "HOUR"
            }
          ];
          return updateProperty(variant.id === variantId, variant, { activities });
        })
      };
    });
  };

  const handleRemoveActivity = (activityIndex: number, variantId: string): void => {
    setProductionDetail((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        variants: prev?.variants.map((variant) => {
          const activities = removeAtIndex(variant.activities, activityIndex);
          return updateProperty(variant.id === variantId, variant, { activities });
        })
      };
    });
    setError("");
  };

  // UseEffects
  useEffect(() => {
    setHasUnsavedChanges(!deepEqual(productionDetail, savedProductionDetail));
  }, [savedProductionDetail, productionDetail]);

  useEffect(() => {
    handleGetOrderProductionDetails();
  }, []);

  return {
    productionDetail,
    handleFormSubmit,
    error,
    hasUnsavedChanges,
    handleProductionDetailsChange,
    orderId,
    loaders,
    handleAddMaterial,
    handleRemoveMaterial,
    handleProductionAddOnDetailsChange,
    handleAddonFormSubmit,
    handleAddAddOn,
    handleRemoveAddOn,
    handleProductionActivityDetailsChange,
    handleActivityFormSubmit,
    handleAddActivity,
    handleRemoveActivity
  };
};

export default useOrderProduction;
