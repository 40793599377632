import api from "config/api";
import { BANK_DEPOSIT, PAYMENT_LINK } from "constants/general";
import { ERROR_OCCURRED, FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { isNotEmpty } from "helpers/validate";
import { useEffect, useState } from "react";
import {
  BusinessPaymentMethod,
  FormChangeEvent,
  PaymentMethod,
  BusinessPaymentMethodForm,
  BusinessPaymentMethodLoaders,
  Response,
  UsePaymentMethodsType
} from "types";
import { AlertType, SegmentEvent } from "types/enum";
import validator from "validator";

export const usePaymentMethods = (): UsePaymentMethodsType => {
  const defaultForm: BusinessPaymentMethodForm = {
    paymentMethod: "",
    name: "",
    bank: "",
    accountName: "",
    accountNumber: "",
    paymentLink: ""
  };

  const [businessPaymentMethods, setBusinessPaymentMethods] = useState<BusinessPaymentMethod[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const { showAlert } = useAlert();
  const [loaders, setLoaders] = useState<BusinessPaymentMethodLoaders>({
    savingBusinessPaymentMethods: false,
    fetchingBusinessPaymentMethods: true
  });
  const [error, setError] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [businessPaymentMethodForm, setBusinessPaymentMethodForm] =
    useState<BusinessPaymentMethodForm>(defaultForm);
  const [businessPaymentMethod, setBusinessPaymentMethod] = useState<BusinessPaymentMethod>();
  const [isOpen, setIsOpen] = useState(false);

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setBusinessPaymentMethodForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleCreateBusinessPaymentMethod = async (): Promise<boolean> => {
    const { name, paymentMethod, ...details } = businessPaymentMethodForm;
    const requestData = {
      paymentMethod,
      name: paymentMethod == PAYMENT_LINK ? name : `${details.bank} ${details.accountNumber}`,
      details
    };

    try {
      const json: Response<string> = await api
        .post("business-payment-method", { json: requestData })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.BUSINESS_PAYMENT_METHOD_ADDED, {
          name,
          paymentMethod
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleUpdateBusinessPaymentMethod = async (): Promise<boolean> => {
    const { name, paymentMethod, ...details } = businessPaymentMethodForm;
    const requestData = {
      paymentMethod,
      name: paymentMethod == PAYMENT_LINK ? name : `${details.bank} ${details.accountNumber}`,
      details
    };

    try {
      const json: Response<void> = await api
        .put(`business-payment-method/${businessPaymentMethod?.id}`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.BUSINESS_PAYMENT_METHOD_MODIFIED, {
          paymentMethod,
          name
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    setLoaders((prev) => ({ ...prev, savingBusinessPaymentMethods: true }));
    let result;
    if (businessPaymentMethod) {
      result = await handleUpdateBusinessPaymentMethod();
    } else {
      result = await handleCreateBusinessPaymentMethod();
    }
    setLoaders((prev) => ({ ...prev, savingBusinessPaymentMethods: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
      await handleGetBusinessPaymentMethods();
      setIsOpen(false);
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  const handleGetBusinessPaymentMethods = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingBusinessPaymentMethods: true }));

    try {
      const json: Response<BusinessPaymentMethod[]> = await api
        .get("business-payment-method")
        .json();
      if (json.code === 200) {
        setBusinessPaymentMethods(json.data);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingBusinessPaymentMethods: !paymentMethods }));
  };

  const handleGetPaymentMethods = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingBusinessPaymentMethods: true }));

    try {
      const json: Response<PaymentMethod[]> = await api.get("payment-method").json();
      if (json.code === 200) {
        setPaymentMethods(json.data);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingBusinessPaymentMethods: !businessPaymentMethods }));
  };

  const resetForm = (): void => {
    setError("");
    setBusinessPaymentMethodForm(defaultForm);
    setBusinessPaymentMethod(undefined);
  };

  useEffect(() => {
    handleGetPaymentMethods();
    handleGetBusinessPaymentMethods();
  }, []);

  useEffect(() => {
    if (businessPaymentMethod) {
      setBusinessPaymentMethodForm({
        paymentMethod: businessPaymentMethod.paymentMethodName,
        name: businessPaymentMethod.name,
        bank: businessPaymentMethod.details.bank,
        accountName: businessPaymentMethod.details.accountName,
        accountNumber: businessPaymentMethod.details.accountNumber,
        paymentLink: businessPaymentMethod.details.paymentLink
      });
    }
  }, [businessPaymentMethod]);

  useEffect(() => {
    const bankDepositIsValid =
      businessPaymentMethodForm.paymentMethod == BANK_DEPOSIT &&
      isNotEmpty(businessPaymentMethodForm.accountName) &&
      isNotEmpty(businessPaymentMethodForm.accountNumber) &&
      isNotEmpty(businessPaymentMethodForm.bank);

    const paymentLinkIsValid =
      isNotEmpty(businessPaymentMethodForm.name) &&
      businessPaymentMethodForm.paymentMethod == PAYMENT_LINK &&
      isNotEmpty(businessPaymentMethodForm.paymentLink) &&
      validator.isURL(businessPaymentMethodForm.paymentLink);

    setFormIsValid(
      isNotEmpty(businessPaymentMethodForm.paymentMethod) &&
        (bankDepositIsValid || paymentLinkIsValid)
    );
  }, [businessPaymentMethodForm]);

  return {
    loaders,
    formIsValid,
    handleFormChange,
    handleFormSubmit,
    error,
    paymentMethods,
    businessPaymentMethods,
    businessPaymentMethodForm,
    setBusinessPaymentMethod,
    businessPaymentMethod,
    resetForm,
    setIsOpen,
    isOpen
  };
};
