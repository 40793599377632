import { none } from "@hookstate/core";
import api from "config/api";
import { useAppState } from "config/store";
import { ERROR_OCCURRED, INVITE_ACCEPTED, INVITE_INVALID } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UseInviteType, Response, InviteDetail, InviteLoaders } from "types";
import { AlertType, SegmentEvent } from "types/enum";

const useInvite = (): UseInviteType => {
  const appState = useAppState();
  const navigate = useNavigate();
  const user = appState.user.get();
  const member = appState.member.get();
  const { inviteId } = useParams();
  const { showAlert } = useAlert();

  const [invite, setInvite] = useState<InviteDetail>();
  const [loaders, setLoaders] = useState<InviteLoaders>({
    fetchingInvite: true,
    acceptingInvite: false
  });

  // Handlers
  const handleGetInvite = async (): Promise<void> => {
    try {
      const json: Response<InviteDetail> = await api.get(`member/invite/${inviteId}`).json();
      if (json.code === 200) {
        setInvite(json.data);
        // TODO remove this if same as line above
        appState.invite.set(json.data);
        // user is logged in and has record in server, process invite
        if (user && user.email == json.data.email && member) {
          navigate("/post-auth");
          return;
        }
        navigate("/signin");
        return;
      }
    } catch (e) {
      showAlert(AlertType.DANGER, INVITE_INVALID);
      navigate("/signin");
      setLoaders((prev) => ({ ...prev, fetchingInvite: false }));
    }
  };

  const processInvite = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, acceptingInvite: true }));
    const result = await acceptInvite(inviteId as string);
    if (result) {
      navigate("/post-auth");
    } else {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
      handleNavigate();
    }
    setLoaders((prev) => ({ ...prev, acceptingInvite: false }));
  };

  const acceptInvite = async (inviteId: string): Promise<boolean> => {
    const json: Response<InviteDetail> = await api.post(`member/invite/${inviteId}/accept`).json();
    track(SegmentEvent.INVITE_ACCEPTED, {
      userId: user?.uid,
      email: user?.email
    });
    appState.invite.set(none);
    if (json.code !== 200) {
      showAlert(AlertType.SUCCESS, INVITE_INVALID);
      navigate("/signin");
      return false;
    }
    showAlert(AlertType.SUCCESS, INVITE_ACCEPTED);
    return true;
  };

  const handleAcceptInvite = async (): Promise<boolean> => {
    const invite = appState.invite.get();
    const user = appState.user.get();

    if (!invite || invite.email != user?.email) {
      appState.invite.set(none);
      return false;
    }
    return acceptInvite(invite.id);
  };

  const handleNavigate = (): void => {
    navigate(user ? "/overview" : "/signin");
  };

  return {
    invite,
    inviteId,
    loaders,
    handleAcceptInvite,
    handleGetInvite,
    handleNavigate
  };
};

export default useInvite;
