import api from "config/api";
import {
  ADDON_ADDED,
  ADDON_REMOVED,
  ERROR_OCCURRED,
  FETCH_FAILED,
  REQUEST_SUCCESSFUL
} from "constants/response";
import { track } from "helpers/analytics";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AddOnCostForm,
  AddOnLoaders,
  BusinessAddOn,
  FormChangeEvent,
  ProductAddOn,
  ProductVariantAddOn,
  ProductVariantAddOnDetails,
  Response,
  UseProductAddOnType,
  ObjectChanges,
  AddOnForm
} from "types";
import { AlertType, SegmentEvent } from "types/enum";
import deepEqual from "deep-equal";
import { updateProperty } from "helpers/object";
import { useAlert } from "context/alert/AlertContext";
import { useAutosave } from "react-autosave";
import { AUTOSAVE_INTERVAL } from "constants/general";

const useProductAddOn = (): UseProductAddOnType => {
  const { productId } = useParams();
  const { showAlert } = useAlert();

  // UseStates
  const [addOnCostForm, setAddOnCostForm] = useState<AddOnCostForm>({
    amount: ""
  });
  const [addOnForm, setaddOnForm] = useState<AddOnForm>({
    name: ""
  });
  const [productAddOns, setProductAddOns] = useState<ProductAddOn[]>([]);
  const [businessAddOns, setBusinessAddOns] = useState<BusinessAddOn[]>([]);
  const [productVariantAddOns, setProductVariantAddOns] = useState<ProductVariantAddOn[]>([]);
  const [savedProductVariantAddOns, setSavedProductVariantAddOns] = useState<ProductVariantAddOn[]>(
    []
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [loaders, setLoaders] = useState<AddOnLoaders>({
    savingAddOns: false,
    fetchingAddOns: true
  });
  const [error, setError] = useState("");
  const [variantsHaveChanges, setVariantsHaveChanges] = useState<ObjectChanges>({});
  useAutosave({
    data: productVariantAddOns,
    onSave: async (): Promise<void> => {
      if (hasUnsavedChanges) {
        setLoaders((prev) => ({ ...prev, savingAddOns: true }));
        await handleSaveAddOnCost();
        setLoaders((prev) => ({ ...prev, savingAddOns: false }));
      }
    },
    interval: AUTOSAVE_INTERVAL,
    saveOnUnmount: true
  });

  const handleCostFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setAddOnCostForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setaddOnForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleVariantAddOnsChange = (
    event: FormChangeEvent,
    variantId: string,
    addOnId: string
  ): void => {
    const { name, value } = event.target;
    setProductVariantAddOns((prev) => {
      return prev.map((variant) => {
        const addOnCosts = variant.addOnCosts.map((cost) =>
          updateProperty(cost.addOnId === addOnId, cost, { [name]: value })
        );
        return updateProperty(variant.variantId === variantId, variant, { addOnCosts });
      });
    });
    setError("");
  };

  const handleAddAddOn = async (addOnName: string): Promise<boolean> => {
    setError("");
    const requestData = {
      productId,
      name: addOnName
    };

    try {
      const json: Response<string> = await api.post("addon", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.ADDON_ADDED, {
          productId,
          addOnName
        });
        showAlert(AlertType.SUCCESS, ADDON_ADDED);
        await handleGetProductAddOns();
        await handleGetBusinessAddOns();
      } else {
        showAlert(AlertType.DANGER, ERROR_OCCURRED);
      }
      return isSuccessfull;
    } catch {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
      return false;
    }
  };

  const handleRemoveAddOn = async (addOnId: string): Promise<boolean> => {
    setError("");
    try {
      const json: Response<void> = await api.delete(`addon/${addOnId}`).json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.ADDON_REMOVED, {
          productId,
          addOnId
        });
        showAlert(AlertType.SUCCESS, ADDON_REMOVED);
        await handleGetProductAddOns();
      } else {
        showAlert(AlertType.DANGER, ERROR_OCCURRED);
      }
      return isSuccessfull;
    } catch {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
      return false;
    }
  };

  const handleSaveAddOnCost = async (): Promise<boolean> => {
    const requestData = {
      variants: productVariantAddOns.map(({ variantId, addOnCosts }) => ({
        variantId,
        addOnCosts: addOnCosts.map(({ addOnId, cost }) => ({
          cost: +cost,
          addOnId
        }))
      }))
    };

    try {
      const json: Response<string> = await api
        .put(`addon-cost/${productId}`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        await handleGetProductAddOns();
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleSaveAddOn = async (): Promise<boolean> => {
    const requestData = {
      productId,
      name: addOnForm.name
    };

    try {
      const json: Response<string> = await api.post("addon", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        await handleGetProductAddOns();
        await handleGetBusinessAddOns();
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleCostFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingAddOns: true }));
    const result = await handleSaveAddOnCost();

    setLoaders((prev) => ({ ...prev, savingAddOns: false }));
    if (result) {
      showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
    } else {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingAddOns: true }));
    const result = await handleSaveAddOn();

    setLoaders((prev) => ({ ...prev, savingAddOns: false }));
    if (result) {
      showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
    } else {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
    }
  };

  const handleGetBusinessAddOns = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingAddOns: true }));

    try {
      const json: Response<BusinessAddOn[]> = await api.get("business-addons").json();
      if (json.code === 200) {
        setBusinessAddOns(json.data);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingAddOns: false }));
  };

  const handleGetProductAddOns = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingAddOns: true }));

    try {
      const json: Response<ProductAddOn[]> = await api.get(`addon/${productId}`).json();
      if (json.code === 200) {
        setProductAddOns(json.data);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingAddOns: false }));
  };

  const handleGetProductVariantAddOns = async (): Promise<void> => {
    try {
      const json: Response<ProductVariantAddOnDetails> = await api
        .get(`addon-cost/${productId}`)
        .json();
      if (json.code === 200) {
        setSavedProductVariantAddOns(json.data.variants);
        // Set received addons to saved data if present
        setProductVariantAddOns((prev) => {
          return json.data.variants.map((variant) => {
            const existingProductVariant = prev.find(
              (oldVariant) => oldVariant.variantId === variant.variantId
            );
            return updateProperty(!!existingProductVariant, variant, {
              addOnCosts: variant.addOnCosts.map((cost) => {
                const existingCost = existingProductVariant?.addOnCosts.find(
                  (oldCost) => oldCost.addOnId == cost.addOnId
                );
                return !existingCost
                  ? cost
                  : {
                      ...cost,
                      cost: +existingCost.cost
                    };
              })
            });
          });
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // UseEffects
  useEffect(() => {
    setHasUnsavedChanges(!deepEqual(productVariantAddOns, savedProductVariantAddOns));
    const variantsHaveChanges: ObjectChanges = {};
    productVariantAddOns.forEach((variant) => {
      variantsHaveChanges[variant.variantId] = !deepEqual(
        variant,
        savedProductVariantAddOns.find(
          (savedVariant) => savedVariant.variantId == variant.variantId
        )
      );
    });
    setVariantsHaveChanges(variantsHaveChanges);
  }, [savedProductVariantAddOns, productVariantAddOns]);

  useEffect(() => {
    handleGetBusinessAddOns();
    if (productId) {
      handleGetProductAddOns();
    }
  }, []);

  useEffect(() => {
    // Get variant addons
    if (productId) {
      handleGetProductVariantAddOns();
    }
  }, [productAddOns]);

  return {
    addOnCostForm,
    productAddOns,
    businessAddOns,
    handleCostFormChange,
    handleCostFormSubmit,
    handleFormChange,
    handleFormSubmit,
    error,
    hasUnsavedChanges,
    handleVariantAddOnsChange,
    productId,
    handleAddAddOn,
    handleRemoveAddOn,
    loaders,
    productVariantAddOns,
    variantsHaveChanges,
    addOnForm
  };
};

export default useProductAddOn;
