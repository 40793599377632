import { removeAtIndex, replaceAtIndex } from "helpers/array";
import { resetProduct } from "hooks/useOrderForm/useOrderForm.utils";
import { Dispatch, SetStateAction, useRef } from "react";
import {
  FormChangeEvent,
  OrderProductsForm,
  ProductDetailVariant,
  UseOrderProductsFormType,
} from "types";
import { v4 } from "uuid";

export const useOrderProductsForm = <T extends OrderProductsForm>(
  setForm: Dispatch<SetStateAction<T>>,
  setError: Dispatch<SetStateAction<string>>
): UseOrderProductsFormType => {
  const orderFormProducts = useRef<OrderProductsForm["products"]>([
    {
      key: v4(),
      type: "order_item",
      value: {
        groupId: "",
        productId: "",
        variantId: "",
        quantity: "1",
        unitPrice: ""
      }
    }
  ]);

  const handleGroupedNameChange = (event: FormChangeEvent, productIndex: number): void => {
    const existingProduct = orderFormProducts.current[productIndex];
    if (existingProduct.type == "order_item") {
      return;
    }

    const { name, value } = event.target;
    setForm((prev) => {
      const products = replaceAtIndex(
        prev.products,
        {
          ...existingProduct,
          value: { ...existingProduct.value, [name]: value }
        },
        productIndex
      );
      orderFormProducts.current = products;
      return {
        ...prev,
        products
      };
    });
    setError("");
  };

  const handleSingleProductChange = (
    event: FormChangeEvent,
    productIndex: number,
    variant: ProductDetailVariant | undefined
  ): void => {
    const existingProduct = orderFormProducts.current[productIndex];
    if (existingProduct.type == "group") {
      return;
    }

    const { name, value } = event.target;
    setForm((prev) => {
      const products = replaceAtIndex(
        prev.products,
        {
          ...existingProduct,
          value: { ...existingProduct.value, ...resetProduct(event, variant), [name]: value }
        },
        productIndex
      );
      orderFormProducts.current = products;
      return {
        ...prev,
        products
      };
    });
    setError("");
  };

  const handleGroupedProductChange = (
    event: FormChangeEvent,
    productIndex: number,
    itemIndex: number,
    variant: ProductDetailVariant | undefined
  ): void => {
    const existingProduct = orderFormProducts.current[productIndex];
    if (existingProduct.type == "order_item") {
      return;
    }

    const { name, value } = event.target;
    setForm((prev) => {
      const products = replaceAtIndex(
        prev.products,
        {
          ...existingProduct,
          value: {
            ...existingProduct.value,
            items: replaceAtIndex(
              existingProduct.value.items,
              {
                ...existingProduct.value.items[itemIndex],
                ...resetProduct(event, variant),
                [name]: value
              },
              itemIndex
            )
          }
        },
        productIndex
      );
      orderFormProducts.current = products;
      return {
        ...prev,
        products
      };
    });
    setError("");
  };

  const handleAddSingleProduct = (): void => {
    setForm((prev) => {
      const products: OrderProductsForm["products"] = [
        ...prev.products,
        {
          key: v4(),
          type: "order_item",
          value: {
            groupId: "",
            productId: "",
            variantId: "",
            quantity: "1",
            unitPrice: ""
          }
        }
      ];
      orderFormProducts.current = products;

      return {
        ...prev,
        products
      };
    });
  };

  const handleAddGroupedProduct = (): void => {
    setForm((prev) => {
      const products: OrderProductsForm["products"] = [
        ...prev.products,
        {
          key: v4(),
          type: "group",
          value: {
            name: "",
            quantity: 1,
            items: [{ productId: "", groupId: "", variantId: "", quantity: "1", unitPrice: "" }]
          }
        }
      ];
      orderFormProducts.current = products;

      return {
        ...prev,
        products
      };
    });
  };

  const handleAddProductInGroup = (productIndex: number): void => {
    const existingProduct = orderFormProducts.current[productIndex];
    if (existingProduct.type == "order_item") {
      return;
    }

    setForm((prev) => {
      const products = replaceAtIndex(
        prev.products,
        {
          ...existingProduct,
          value: {
            ...existingProduct.value,
            items: [
              ...existingProduct.value.items,
              { productId: "", groupId: "", variantId: "", quantity: "1", unitPrice: "" }
            ]
          }
        },
        productIndex
      );
      orderFormProducts.current = products;

      return {
        ...prev,
        products
      };
    });
  };

  const handleDuplicateGroupedProduct = (productIndex: number): void => {
    const existingProduct = orderFormProducts.current[productIndex];
    if (existingProduct.type == "order_item") {
      return;
    }

    setForm((prev) => {
      const products = [
        ...prev.products,
        {
          ...existingProduct,
          key: v4(),
          value: {
            ...existingProduct.value,
            name: ""
          }
        }
      ];
      orderFormProducts.current = products;

      return {
        ...prev,
        products
      };
    });
  };

  const handleRemoveSingleOrGroupedProduct = (productIndex: number): void => {
    setForm((prev) => {
      const products = removeAtIndex(prev.products, productIndex);
      orderFormProducts.current = products;

      return {
        ...prev,
        products
      };
    });
    setError("");
  };

  const handleRemoveProductInGroup = (productIndex: number, itemIndex: number): void => {
    const groupedProducts = orderFormProducts.current[productIndex];
    if (groupedProducts.type == "order_item") {
      return;
    }

    const newGroupedProductsItems = removeAtIndex(groupedProducts.value.items, itemIndex);
    if (newGroupedProductsItems.length === 0) {
      // All products in the group have been removed, so remove group
      setForm((prev) => {
        const products = removeAtIndex(prev.products, productIndex);
        orderFormProducts.current = products;

        return {
          ...prev,
          products
        };
      });
    } else {
      setForm((prev) => {
        const products = replaceAtIndex(
          prev.products,
          {
            ...groupedProducts,
            value: {
              ...groupedProducts.value,
              items: removeAtIndex(groupedProducts.value.items, itemIndex)
            }
          },
          productIndex
        );
        orderFormProducts.current = products;

        return {
          ...prev,
          products
        };
      });
    }
    setError("");
  };

  const handleSetProducts = (products: OrderProductsForm["products"]): void => {
    setForm((prev) => {
      orderFormProducts.current = products;
      return {
        ...prev,
        products
      };
    });
  };

  return {
    handleGroupedNameChange,
    handleSingleProductChange,
    handleGroupedProductChange,
    handleAddSingleProduct,
    handleAddGroupedProduct,
    handleAddProductInGroup,
    handleDuplicateGroupedProduct,
    handleRemoveSingleOrGroupedProduct,
    handleRemoveProductInGroup,
    handleSetProducts
  };
};
