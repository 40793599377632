import CreateEditOrder from "components/CreateEditOrder";
import LoadingComponent from "components/Shared/Loading";
import useCustomer from "hooks/useCustomer";
import useOrderForm from "hooks/useOrderForm";
import useProduct from "hooks/useProduct";
import React, { useEffect, useRef } from "react";
import { ProductWithVariants } from "types";

const EditOrderPage = (): React.ReactElement => {
  const props = useOrderForm();
  const { handleGetProductsWithVariants, loaders } = useProduct();
  const { customers } = useCustomer();

  const productsWithVariants = useRef<ProductWithVariants[]>([]);
  useEffect(() => {
    (async () => {
      productsWithVariants.current = await handleGetProductsWithVariants();
    })();
  }, []);

  return (
    <LoadingComponent loading={loaders.fetchingProduct || props.loaders.fetchingOrder}>
      <CreateEditOrder {...props} products={productsWithVariants.current} customers={customers} />
    </LoadingComponent>
  );
};

export default EditOrderPage;
