import EditStoreItem from "components/EditStoreItem";
import LoadingComponent from "components/Shared/Loading";
import useStoreItem from "hooks/useStoreItem";
import React from "react";

const EditStoreItemPage = (): React.ReactElement => {
  const props = useStoreItem();

  return (
    <LoadingComponent loading={false}>
      <EditStoreItem {...props} />
    </LoadingComponent>
  );
};

export default EditStoreItemPage;
