import StoreComponent from "components/Store";
import LoadingComponent from "components/Shared/Loading";
import useStoreItems from "hooks/useStoreItems";
import React, { useEffect } from "react";

const StorePage = (): React.ReactElement => {
  const props = useStoreItems();

  useEffect(() => {
    props.handleGetStoreItems();
  }, []);

  return (
    <LoadingComponent loading={props.loaders.fetchingStoreItems && !props.storeItems}>
      <StoreComponent {...props} />
    </LoadingComponent>
  );
};

export default StorePage;
