import Autocomplete from "components/Shared/Autocomplete";
import DateInput from "components/Shared/DateInput";
import { CreateEditOrderProps } from "types";
import React from "react";

const Details = ({
  handleFormChange,
  orderForm,
  customers,
  isCreatingNewOrder
}: CreateEditOrderProps & {
  isCreatingNewOrder: boolean;
}): React.ReactElement => {
  return (
    <div className="form-center">
      <div>
        <Autocomplete
          type="text"
          name="customerName"
          onChange={handleFormChange}
          value={orderForm.customerName}
          required
          label="Customer Name"
          data-testid="customerName"
          dataIdName="customerId"
          data={customers}
          disabled={!isCreatingNewOrder}
        />
      </div>
      <div>
        <DateInput
          name="date"
          onChange={handleFormChange}
          value={orderForm.date}
          required
          label="Date"
          data-testid="date"
        />
      </div>
    </div>
  );
};

export default Details;
