import { OrderProductionComponentProps } from "types";
import React, { useState } from "react";
import Accordion from "components/Shared/Accordion";
import TextInput from "components/Shared/TextInput";
import InputGroup from "components/Shared/InputGroup";
import Select from "components/Shared/Select";
import Info from "icons/Info";
import Error from "components/Shared/Error";
import Amount from "components/Shared/Amount";
import Cancel from "icons/Cancel";
import IconButton from "components/Shared/IconButton";
import MeasurementUnit from "components/EditStoreItem/MeasurementUnit";
import Plus from "icons/Plus";
import { AutosaveButton } from "components/Shared/AutosaveButton/AutosaveButton";
import { getStoreItemName } from "hooks/useStoreItems/useStoreItems.utils";

const Materials = (props: OrderProductionComponentProps): React.ReactElement => {
  const {
    productionDetail,
    handleProductionDetailsChange,
    measurementUnits,
    loaders,
    handleFormSubmit,
    error,
    hasUnsavedChanges,
    storeItems,
    handleAddMaterial,
    handleRemoveMaterial
  } = props;
  const [activeKey, setActiveKey] = useState("");

  return (
    <div>
      <form className="pb-40" onSubmit={handleFormSubmit} data-testid="productionDetailForm">
        <div className="bg-white md:rounded-lg px-4 md:px-6 py-4 mt-4 md:h-auto">
          <Error error={error} />
          {productionDetail.variants.map(
            (variant): React.ReactElement => (
              <Accordion
                key={variant.id}
                itemId={variant.id}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                header={
                  <div className="inline-flex items-center justify-between flex-1 pr-6">
                    <span>
                      <b data-testid="variantName">
                        {variant.product.name}: {variant.name}
                      </b>
                      <small>&nbsp; x {variant.quantity} unit(s)</small>

                      {variant.materials.filter((material) => material.measurementValue == 0)
                        .length > 0 && (
                        <span
                          className="ml-4 text-xs font-medium bg-warning text-dark-warning rounded px-2 py-1 inline-flex items-center"
                          data-testid="emptyStoreItems">
                          <Info /> &nbsp;
                          {
                            variant.materials.filter((material) => material.measurementValue == 0)
                              .length
                          }
                        </span>
                      )}
                    </span>
                    <Amount amount={variant.cost.materials} />
                  </div>
                }>
                <div className="flex flex-col">
                  {variant.materials.map((material, materialIndex) => (
                    <div
                      className="flex flex-wrap justify-between items-center pt-2 px-2 md:px-4  border-b border-grey-30"
                      key={material.storeItemId}
                      data-testid="material">
                      {material.id ? (
                        <span data-testid="materialName" className="flex-1">
                          {material.name}
                        </span>
                      ) : (
                        <div className="flex-[100%] md:flex-1 pr-2">
                          <Select
                            id="storeItemId"
                            inputSize="sm"
                            name="storeItemId"
                            value={material.storeItemId}
                            required
                            onChange={(e): void => {
                              handleProductionDetailsChange(
                                {
                                  ...e,
                                  target: {
                                    ...e.target,
                                    name: "measurementUnit",
                                    value:
                                      storeItems?.items.find((item) => item.id == e.target.value)
                                        ?.unit.name || ""
                                  }
                                },
                                variant.id,
                                material.storeItemId
                              );
                              handleProductionDetailsChange(e, variant.id, material.storeItemId);
                            }}
                            data-testid="storeItemId"
                            className="bg-soft-grey"
                            label="Add store item">
                            <option value="" disabled>
                              Select store item
                            </option>
                            {storeItems?.items
                              .filter(
                                (item) =>
                                  !variant.materials.find(
                                    (material) => material.storeItemId === item.id && material.id
                                  )
                              )
                              .map((item) => (
                                <option
                                  key={item.id}
                                  value={item.id}
                                  disabled={
                                    !!variant.materials.find(
                                      (material) => material.storeItemId === item.id
                                    )
                                  }>
                                  {getStoreItemName(item)}
                                </option>
                              ))}
                          </Select>
                        </div>
                      )}
                      <div className="flex-1 max-w-xs">
                        <InputGroup>
                          <div className="w-auto flex-1">
                            <TextInput
                              type="number"
                              min={0}
                              name="measurementValue"
                              inputSize="sm"
                              onChange={(e): void =>
                                handleProductionDetailsChange(e, variant.id, material.storeItemId)
                              }
                              value={material.measurementValue}
                              required
                              label="Measurement"
                              data-testid="measurementValue"
                            />
                          </div>
                          <div className="w-16 md:w-20">
                            <MeasurementUnit
                              id="unit"
                              inputSize="sm"
                              name="measurementUnit"
                              required
                              onChange={(e): void =>
                                handleProductionDetailsChange(e, variant.id, material.storeItemId)
                              }
                              data-testid="measurementUnit"
                              className="bg-soft-grey"
                              label="Unit"
                              measurementUnits={measurementUnits}
                              measurementUnit={material.measurementUnit}
                            />
                          </div>
                        </InputGroup>
                      </div>
                      <button
                        onClick={(): void => handleRemoveMaterial(materialIndex, variant.id)}
                        type="button"
                        className="ml-4 top-0 bottom-0 text-danger"
                        data-testid="removeMaterial">
                        <Cancel />
                      </button>
                    </div>
                  ))}
                  <div className="flex justify-between items-center py-2 px-4 border-grey-30 bg-soft-grey text-primary">
                    <div>
                      <IconButton
                        disabled={
                          variant.materials.filter((material) => !material.storeItemId).length > 0
                        }
                        size="sm"
                        onClick={() => handleAddMaterial(variant.id)}
                        icon={<Plus />}
                        data-testid="addMaterial"
                        outlined>
                        Add another item
                      </IconButton>
                    </div>
                  </div>
                </div>
              </Accordion>
            )
          )}
        </div>
        <div className="fixed left-0 right-0 bottom-0 bg-white px-4 sm:px-12 xl:px-40 py-6 flex justify-between items-center z-10">
          <p className="lg:ml-64">
            {hasUnsavedChanges ? (
              <b className="text-grey-50 mr-3" data-testid="unsavedChanges">
                New changes, save to update
              </b>
            ) : (
              <>
                <b className="text-grey-50 mr-3">Total producion cost</b>
                <b data-testid="productionCost">
                  <Amount amount={productionDetail.cost.total} />
                </b>
              </>
            )}
          </p>
          <AutosaveButton
            disabled={!hasUnsavedChanges}
            hasChanges={hasUnsavedChanges}
            type="submit"
            loading={loaders.savingProductionDetails}
            data-testid="submitButton"
            className="w-60"
          />
        </div>
      </form>
    </div>
  );
};

export default Materials;
