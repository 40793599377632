import { changeMonth, formatMonth, formatReadableMonth, getMonth } from "helpers/date";
import CaretLeft from "icons/CaretLeft";
import CaretRight from "icons/CaretRight";
import React, { useEffect } from "react";
import { DateFilterProps } from "types";
import { differenceInMonths, eachMonthOfInterval } from "date-fns";
import { addYears } from "date-fns";
import TextInput from "../TextInput";
import cx from "classnames";
import Check from "icons/Check";
import { useComponentVisible } from "hooks/shared/useComponentVisible/useComponentVisible";

const DateFilter = (props: DateFilterProps): React.ReactElement => {
  const { dateRange, handleDateRangeChange } = props;
  const [displayedDate, setDisplayedDate] = React.useState("");
  const [selectedMonthDiffIndex, setSelectedMonthDiffIndex] = React.useState<number | undefined>(0);
  const { ref, isComponentVisible } = useComponentVisible<HTMLSpanElement>(false);

  const dateFilters = [
    { label: formatReadableMonth(getMonth()), monthDiff: -1 },
    { label: "Last month", monthDiff: -2, monthEnd: -2 },
    { label: "Last 3 months", monthDiff: -3 },
    { label: "Last 6 months", monthDiff: -6 },
    { label: "Last 9 months", monthDiff: -9 },
    { label: "Last year", monthDiff: -12 },
    { label: "Last 2 years", monthDiff: -24 },
    { label: "Custom", monthDiff: undefined }
  ];

  useEffect(() => {
    const startMonth = formatReadableMonth(dateRange.startDate);
    const endMonth = formatReadableMonth(dateRange.endDate);
    setDisplayedDate(startMonth === endMonth ? startMonth : `${startMonth}  -  ${endMonth}`);
  }, [dateRange]);

  const handlePrevious = (): void => {
    const monthDiff =
      differenceInMonths(new Date(dateRange.endDate), new Date(dateRange.startDate)) + 1;
    handleDateRangeChange({
      startDate: changeMonth(dateRange.startDate, -monthDiff),
      endDate: changeMonth(dateRange.endDate, -monthDiff)
    });
    setSelectedMonthDiffIndex(-1);
  };

  const handleNext = (): void => {
    const monthDiff =
      differenceInMonths(new Date(dateRange.endDate), new Date(dateRange.startDate)) + 1;
    handleDateRangeChange({
      startDate: changeMonth(dateRange.startDate, monthDiff),
      endDate: changeMonth(dateRange.endDate, monthDiff)
    });
    setSelectedMonthDiffIndex(-1);
  };

  const months = eachMonthOfInterval({
    start: new Date("2022-01-01"),
    end: addYears(new Date(), 1)
  });

  const changeCustomDateFilter = (index: number): void => {
    const { monthDiff, monthEnd = -1 } = dateFilters[index];

    if (monthDiff !== undefined) {
      handleDateRangeChange({
        startDate: changeMonth(getMonth(), +monthDiff + 1),
        endDate: changeMonth(getMonth(), monthEnd + 1)
      });
    }
    setSelectedMonthDiffIndex(index);
  };

  return (
    <span className="flex sm:w-72 relative">
      <span className="text-primary flex mr-4">
        <button onClick={handlePrevious} data-testid="previousMonth">
          <CaretLeft />
        </button>
      </span>
      <span ref={ref}>
        <TextInput
          name="date"
          id="date"
          type="text"
          value={displayedDate}
          label=""
          placeholder=""
          inputSize="sm"
          data-testid="dateInput"
          className="!pt-0 dateInput text-center"
          readOnly
        />
      </span>
      <ul
        data-testid="dateFilterList"
        className={cx(
          "shadow-lg text-sm hidden absolute -mt-1 left-0 right-0 top-full z-10 bg-soft-grey rounded-lg hover:!block overflow-y-auto",
          isComponentVisible && "!block"
        )}>
        {dateFilters.map((filter, index) => (
          <li
            data-testid="dateFilterItem"
            className={cx(
              "py-2 px-4 hover:bg-primary hover:text-white cursor-pointer first:rounded-t-lg last:rounded-b-lg flex justify-between",
              selectedMonthDiffIndex === index && "bg-[rgba(0,0,0,0.1)]"
            )}
            key={index}
            onClick={(): void => {
              changeCustomDateFilter(index);
            }}>
            {filter.label}
            {selectedMonthDiffIndex === index && <Check />}
          </li>
        ))}
        {dateFilters.findIndex(
          (filter, index) => selectedMonthDiffIndex === index && filter.monthDiff === undefined
        ) >= 0 && (
          <li>
            <span className="rounded-lg w-full" data-testid="customDatePicker">
              <div className="bg-[rgba(0,0,0,0.1)] p-2 flex space-x-2 w-full justify-center">
                <select
                  name="startDate"
                  data-testid="startDate"
                  defaultValue=""
                  onChange={(e) =>
                    handleDateRangeChange({ ...dateRange, startDate: e.target.value })
                  }
                  className="flex-grow border-transparent focus:border-primary focus:outline-none focus:ring-0 rounded text-grey-50 text-sm sm:text-xs shadow appearance-none">
                  <option disabled value="">
                    Start date
                  </option>
                  {months.map((month) => (
                    <option value={formatMonth(month)} key={formatReadableMonth(month)}>
                      {formatReadableMonth(month)}
                    </option>
                  ))}
                </select>

                <select
                  name="endDate"
                  data-testid="endDate"
                  defaultValue=""
                  onChange={(e) => handleDateRangeChange({ ...dateRange, endDate: e.target.value })}
                  className="flex-grow border-transparent focus:border-primary focus:outline-none focus:ring-0 rounded text-grey-50 text-sm sm:text-xs shadow appearance-none">
                  <option disabled value="">
                    End date
                  </option>
                  {months.map((month) => (
                    <option value={formatMonth(month)} key={formatReadableMonth(month)}>
                      {formatReadableMonth(month)}
                    </option>
                  ))}
                </select>
              </div>
            </span>
          </li>
        )}
      </ul>
      <span className="text-primary flex ml-4">
        <button onClick={handleNext} data-testid="nextMonth">
          <CaretRight />
        </button>
      </span>
    </span>
  );
};

export default DateFilter;
