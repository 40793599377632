import React, { SVGProps } from "react";

const Check = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 96 960 960"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M378 810 154 586l43-43 181 181 384-384 43 43-427 427Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Check;
