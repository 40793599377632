import Select from "components/Shared/Select";
import React from "react";
import { MeasurementUnit, SelectProps } from "types";

const MeasurementUnitComponent = ({
  measurementUnit,
  measurementUnits,
  ...props
}: SelectProps & {
  measurementUnits: MeasurementUnit[];
  measurementUnit?: string;
}): React.ReactElement => {
  return (
    <Select value={measurementUnit} required {...props}>
      {measurementUnits
        .filter(
          (unit) =>
            unit.category ==
            measurementUnits.find((unit2) => unit2.name == measurementUnit)?.category
        )
        .map((measurementUnit) => (
          <option key={measurementUnit.name} value={measurementUnit.name}>
            {measurementUnit.symbol}
          </option>
        ))}
    </Select>
  );
};

export default MeasurementUnitComponent;
