import { OrderProductionComponentProps } from "types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Title from "components/Shared/Title";
import Prompt from "components/Shared/Prompt";
import { Tab } from "@headlessui/react";
import Hidden from "components/Shared/Hidden";
import { FeatureFlag } from "types/enum";
import Extension from "icons/Extension";
import Customize from "icons/Customize";
import Materials from "./Materials";
import Services from "./Services";
import Activities from "./Activities";
import Wallet from "icons/Wallet";
import { TabTitle } from "components/Shared/Tab/Tab";

const OrderProduction = (props: OrderProductionComponentProps): React.ReactElement => {
  const { productionDetail, hasUnsavedChanges } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div>
      <Prompt when={hasUnsavedChanges} />
      <Link
        to="./../"
        className="font-bold text-sm	flex px-4 md:px-0">
        <span className="text-primary mr-1">
          <ArrowLeft />
        </span>
        Back to order details
      </Link>
      <div className="mt-3 flex flex-col sm:flex-row justify-between sm:items-center space-y-4 sm:space-y-0 px-4 md:px-0 mb-4">
        <Title className="pt-2 md:b-6" data-testid="productionDetailDesc">
          Update production cost for Order #{productionDetail.order.ref}
        </Title>
      </div>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="bg-white flex text-grey-50 font-bold">
          <Hidden flag={FeatureFlag.STORE}>
            <TabTitle title="Components" isSelected={selectedIndex == 0} icon={<Extension />} />
          </Hidden>
          <TabTitle title="Add-ons" isSelected={selectedIndex == 1} icon={<Customize />} />
          <TabTitle
            title="Activities"
            isSelected={selectedIndex == 2}
            icon={<Wallet width={30} height={30} />}
          />
        </Tab.List>
        <Tab.Panels>
          <Hidden flag={FeatureFlag.STORE}>
            <Tab.Panel>
              <Materials {...props} />
            </Tab.Panel>
          </Hidden>
          <Tab.Panel>
            <Services {...props} />
          </Tab.Panel>
          <Tab.Panel>
            <Activities {...props} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default OrderProduction;
