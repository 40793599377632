import {
  CreateEditOrderProps,
  FormChangeEvent,
  OrderFormProduct,
  OrderProductsProps,
  ProductWithVariants
} from "types";
import React from "react";
import Select from "components/Shared/Select";
import Amount from "components/Shared/Amount";
import Cancel from "icons/Cancel";
import IconButton from "components/Shared/IconButton";
import TextInput from "components/Shared/TextInput";
import Plus from "icons/Plus";
import Caret from "icons/Caret";
import cx from "classnames";
import Edit from "icons/Edit";
import Copy from "icons/Copy";
import Delete from "icons/Delete";
import { DropdownButton } from "components/Shared/DropdownButton/DropdownButton";
import { InlineInput } from "components/Shared/InlineInput/InlineInput";

const ProductItem = ({
  product,
  products,
  handleFormProductChange,
  handleFormProductVariantChange,
  handleRemoveProduct,
  isInGroup,
  groupQuantity = 1
}: {
  product: OrderFormProduct;
  products: ProductWithVariants[];
  handleFormProductChange: (e: FormChangeEvent) => void;
  handleFormProductVariantChange: (e: FormChangeEvent) => void;
  handleRemoveProduct: () => void;
  isInGroup?: boolean;
  groupQuantity?: number;
}): React.ReactElement => {
  return (
    <div className="w-full flex flex-wrap mb-4 sm:mb-0">
      <div className={cx("w-full sm:w-4/5", isInGroup && "pl-6")}>
        <Select
          inputSize="sm"
          name="productId"
          onChange={handleFormProductChange}
          value={product.productId}
          required
          label="Select product"
          disabled={!!product.orderItemId}
          data-testid="productId">
          <option value="" disabled>
            Select product *
          </option>
          {products.map((product) => (
            <option key={product.id} value={product.id}>
              {product.name}
            </option>
          ))}
        </Select>
      </div>
      <span className="w-0 sm:w-1/5"></span>
      <div className={cx("w-full sm:w-2/5", isInGroup && "pl-6")}>
        <Select
          inputSize="sm"
          name="variantId"
          onChange={handleFormProductVariantChange}
          value={product.variantId}
          required
          label="Select variant"
          disabled={!!product.orderItemId}
          data-testid="variantId"
          className={cx(isInGroup && "pl-8")}>
          <option value="" disabled>
            Select variant *
          </option>
          {products
            .find(({ id }) => product.productId === id)
            ?.variants.map((variant) => (
              <option key={variant.id} value={variant.id}>
                {variant.name}
              </option>
            ))}
        </Select>
      </div>
      <div className={cx("w-1/2 sm:w-1/5 sm:pl-2", isInGroup && "pl-6 sm:pl-2")}>
        <TextInput
          inputSize="sm"
          type="number"
          name="quantity"
          onChange={handleFormProductChange}
          value={product.quantity}
          required
          label="Quantity"
          data-testid="quantity"
          step={1}
        />
      </div>
      <div className="w-1/2 sm:w-1/5 pl-2">
        <TextInput
          inputSize="sm"
          type="number"
          className="w-1/5"
          name="unitPrice"
          onChange={handleFormProductChange}
          value={product.unitPrice}
          required
          label="Unit price"
          data-testid="unitPrice"
          step={1}
          min={0}
        />
      </div>
      <span
        className={cx(
          "w-full sm:w-1/5 py-1 sm:py-0 flex items-center justify-between sm:justify-end font-bold relative",
          isInGroup && "pl-6 sm:pl-0"
        )}>
        <span>
          <span className="sm:hidden">Amount: </span>
          <Amount amount={+product.unitPrice * +product.quantity * groupQuantity} />
        </span>
        <button
          onClick={handleRemoveProduct}
          type="button"
          className="ml-3 sm:absolute left-full top-0 bottom-0 text-danger"
          data-testid="removeProduct">
          <Cancel />
        </button>
      </span>
    </div>
  );
};

const Products = ({
  products,
  orderFormProducts,
  handleSingleProductChange,
  handleGroupedProductChange,
  handleRemoveSingleOrGroupedProduct,
  handleRemoveProductInGroup,
  handleAddSingleProduct,
  handleAddGroupedProduct,
  handleGroupedNameChange,
  handleAddProductInGroup,
  handleDuplicateGroupedProduct
}: OrderProductsProps): React.ReactElement => {
  return (
    <div className="">
      <div className="justify-between hidden sm:flex form-x py-3 sm:pr-20 bg-soft-grey border-b">
        <p className="text-grey-50 font-bold">Details</p>
        <p className="text-grey-50 font-bold">Amount</p>
      </div>
      <div data-testid="products">
        {orderFormProducts.map((product, index) => (
          <div
            className="relative form-x sm:pr-20 focus-within:bg-soft-grey pt-4 pb-2 border-b border-soft-grey"
            key={product.key}
            data-testid="product">
            {product.type == "order_item" && (
              <ProductItem
                products={products}
                product={product.value}
                handleFormProductChange={(e): void => {
                  handleSingleProductChange(e, index);
                }}
                handleFormProductVariantChange={(e): void => {
                  handleSingleProductChange(
                    e,
                    index,
                    products
                      .find(({ id }) => product.value.productId === id)
                      ?.variants.find(({ id }) => e.target.value === id)
                  );
                }}
                handleRemoveProduct={(): void => handleRemoveSingleOrGroupedProduct(index)}
              />
            )}
            {product.type == "group" && (
              <div>
                <div className="w-full sm:w-4/5 flex justify-between pb-4 items-center">
                  <span className="flex items-center overflow-hidden max-w-[70%]">
                    <InlineInput
                      padSize={0}
                      defaultSize={13}
                      name="name"
                      id={`name${index}`}
                      value={product.value.name}
                      onChange={(e): void => {
                        handleGroupedNameChange(e, index);
                      }}
                      placeholder="Untitled group *"
                    />
                  </span>
                </div>
                {product.value.items.map((item, itemIndex) => (
                  <ProductItem
                    key={`${itemIndex}${item.variantId}`}
                    isInGroup
                    groupQuantity={product.value.quantity}
                    products={products}
                    product={item}
                    handleFormProductChange={(e): void => {
                      handleGroupedProductChange(e, index, itemIndex);
                    }}
                    handleFormProductVariantChange={(e): void => {
                      handleGroupedProductChange(
                        e,
                        index,
                        itemIndex,
                        products
                          .find(({ id }) => item.productId === id)
                          ?.variants.find(({ id }) => e.target.value === id)
                      );
                    }}
                    handleRemoveProduct={(): void => handleRemoveProductInGroup(index, itemIndex)}
                  />
                ))}
                <div className="mt-4 w-full sm:w-4/5 flex justify-between items-center">
                  <div className="w-48">
                    <TextInput
                      inputSize="sm"
                      type="number"
                      name="quantity"
                      onChange={(e): void => {
                        handleGroupedNameChange(e, index);
                      }}
                      value={product.value.quantity}
                      required
                      label="Group Quantity"
                      data-testid="quantity"
                      step={1}
                    />
                  </div>
                  <span className="flex">
                    <button
                      type="button"
                      title="Add product"
                      className="text-primary px-2 cursor-pointer"
                      onClick={(): void => {
                        handleAddProductInGroup(index);
                      }}>
                      <Plus height="24" width="24" />
                    </button>
                    <button
                      type="button"
                      title="Duplicate group"
                      className="text-primary pl-2 cursor-pointer"
                      onClick={(): void => {
                        handleDuplicateGroupedProduct(index);
                      }}>
                      <Copy />
                    </button>
                    <button
                      type="button"
                      title="Duplicate group"
                      className="text-danger pl-2 cursor-pointer"
                      onClick={(): void => {
                        handleRemoveSingleOrGroupedProduct(index);
                      }}>
                      <Delete />
                    </button>
                  </span>
                </div>
              </div>
            )}
          </div>
        ))}
        <div className="form-x sm:pr-20 py-2 pt-5">
          <DropdownButton
            items={[
              { text: "Single product", onClick: handleAddSingleProduct },
              { text: "Grouped product", onClick: handleAddGroupedProduct }
            ]}>
            {(props) => (
              <IconButton icon={<Plus />} data-testid="addProduct" outlined>
                Add an item
                <Caret {...props} />
              </IconButton>
            )}
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};

export default Products;
