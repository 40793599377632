import api from "config/api";
import { ERROR_OCCURRED, FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { getDate } from "helpers/date";
import { isNotEmpty, isNumber } from "helpers/validate";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormChangeEvent,
  Response,
  StoreForm,
  StoreItemsLoaders,
  UseStoreItemsType,
  MeasurementUnit,
  StoreItems
} from "types";
import { AlertType, SegmentEvent } from "types/enum";

const useStoreItems = (): UseStoreItemsType => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const defaultStoreForm: StoreForm = {
    name: "",
    price: "",
    measurement: "",
    unit: "",
    quantity: ""
  };

  // UseStates
  const [storeForm, setStoreForm] = useState<StoreForm>(defaultStoreForm);
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<StoreItemsLoaders>({
    savingStoreItem: false,
    fetchingStoreItems: true
  });
  const [error, setError] = useState("");
  const [measurementUnits, setMeasurementUnits] = useState<MeasurementUnit[]>([]);
  const [storeItems, setStoreItems] = useState<StoreItems>();

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setStoreForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleCreateStoreItem = async (): Promise<boolean> => {
    const requestData = {
      ...storeForm,
      date: getDate(),
      measurement: +storeForm.measurement,
      price: +storeForm.price,
      quantity: +storeForm.quantity,
      type: "RAW_MATERIAL"
    };

    try {
      const json: Response<string> = await api.post("store", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.STORE_ITEM_ADDED, {
          name: storeForm.name,
          measurement: storeForm.measurement,
          price: storeForm.price,
          quantity: storeForm.quantity,
          unit: storeForm.unit,
          type: "RAW_MATERIAL"
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setLoaders((prev) => ({ ...prev, savingStoreItem: true }));
    const result = await handleCreateStoreItem();

    setLoaders((prev) => ({ ...prev, savingStoreItem: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
      navigate("/store");
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  const handleGetMeasurementUnits = async (): Promise<void> => {
    try {
      const json: Response<MeasurementUnit[]> = await api.get("store/measurement-units").json();
      if (json.code === 200) {
        setMeasurementUnits(json.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleGetStoreItems = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingStoreItems: true }));

    try {
      const json: Response<StoreItems> = await api.get("store").json();
      if (json.code === 200) {
        setStoreItems(json.data);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingStoreItems: false }));
  };

  // UseEffects
  useEffect(() => {
    setFormIsValid(
      isNotEmpty(storeForm.name) &&
        isNumber(storeForm.measurement) &&
        isNumber(storeForm.price) &&
        isNumber(storeForm.quantity) &&
        isNotEmpty(storeForm.unit)
    );
  }, [storeForm]);

  useEffect(() => {
    handleGetMeasurementUnits();
  }, []);

  return {
    storeForm,
    formIsValid,
    loaders,
    error,
    handleFormChange,
    handleFormSubmit,
    measurementUnits,
    storeItems,
    handleGetStoreItems
  };
};

export default useStoreItems;
