import React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { AlertContextProvider } from "context/alert/AlertContext";

const AppRoutes = (): React.ReactElement => {
  return (
    <AlertContextProvider>
      <RouterProvider future={{ v7_startTransition: true }} router={router} />
    </AlertContextProvider>
  );
};

export default AppRoutes;
