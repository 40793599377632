import React, { SVGProps } from "react";

const CreditCard = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_333_2712" maskUnits="userSpaceOnUse" x="0" y="0" width="65" height="65">
        <rect width="65" height="65" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_333_2712)">
        <path
          d="M58.2298 17.0625V47.9375C58.2298 49.2917 57.7559 50.4427 56.8079 51.3906C55.86 52.3385 54.709 52.8125 53.3548 52.8125H11.6465C10.2923 52.8125 9.14128 52.3385 8.19336 51.3906C7.24544 50.4427 6.77148 49.2917 6.77148 47.9375V17.0625C6.77148 15.7083 7.24544 14.5573 8.19336 13.6094C9.14128 12.6615 10.2923 12.1875 11.6465 12.1875H53.3548C54.709 12.1875 55.86 12.6615 56.8079 13.6094C57.7559 14.5573 58.2298 15.7083 58.2298 17.0625ZM10.834 22.75H54.1673V17.0625C54.1673 16.8819 54.077 16.7014 53.8965 16.5208C53.7159 16.3403 53.5354 16.25 53.3548 16.25H11.6465C11.4659 16.25 11.2854 16.3403 11.1048 16.5208C10.9243 16.7014 10.834 16.8819 10.834 17.0625V22.75ZM10.834 31.4167V47.9375C10.834 48.1181 10.9243 48.2986 11.1048 48.4792C11.2854 48.6597 11.4659 48.75 11.6465 48.75H53.3548C53.5354 48.75 53.7159 48.6597 53.8965 48.4792C54.077 48.2986 54.1673 48.1181 54.1673 47.9375V31.4167H10.834Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default CreditCard;
