import { isEmail, isNotEmpty } from "helpers/validate";
import { useEffect, useState } from "react";
import { UseSignUpType, SignUpForm, SignUpLoaders, FormChangeEvent } from "types";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "config/firebase";
import { useNavigate } from "react-router-dom";
import { useAppState } from "config/store";
import { ACCOUNT_EXISTS, ERROR_OCCURRED } from "constants/response";
import { none } from "@hookstate/core";
import { identify, track } from "helpers/analytics";
import { SegmentEvent } from "types/enum";

const useSignUp = (): UseSignUpType => {
  const navigate = useNavigate();
  const appState = useAppState();

  // UseStates
  const [signUpForm, setSignUpForm] = useState<SignUpForm>({
    email: "",
    password: ""
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [loaders, setLoaders] = useState<SignUpLoaders>({
    creatingUserAccount: false
  });
  const [error, setError] = useState("");

  // Handlers
  const handleTogglePasswordVisibility = () => {
    setPasswordIsVisible((prev) => !prev);
  };

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setSignUpForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setLoaders((prev) => ({ ...prev, creatingUserAccount: true }));

    createUserWithEmailAndPassword(auth, signUpForm.email, signUpForm.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        appState.user.set(user);
        identify();
        track(SegmentEvent.SIGNED_UP, {
          provider: "email"
        });
        navigate("/post-auth");
      })
      .catch((error) => {
        setError(error.code === "auth/email-already-in-use" ? ACCOUNT_EXISTS : ERROR_OCCURRED);
      })
      .finally(() => {
        setLoaders((prev) => ({ ...prev, creatingUserAccount: false }));
      });
  };

  // UseEffects
  useEffect(() => {
    setFormIsValid(
      isEmail(signUpForm.email) && isNotEmpty(signUpForm.password) && signUpForm.password.length > 7
    );
  }, [signUpForm]);

  useEffect(() => {
    appState.user.set(none);
    appState.accessToken.set(none);
    appState.business.set(none);
  }, []);

  return {
    signUpForm,
    formIsValid,
    passwordIsVisible,
    handleTogglePasswordVisibility,
    handleFormChange,
    handleFormSubmit,
    loaders,
    error
  };
};

export default useSignUp;
