import useInvite from "hooks/useInvite";
import LoadingComponent from "components/Shared/Loading";

import React, { useEffect } from "react";

const JoinPage = (): React.ReactElement => {
  const { inviteId, handleGetInvite, handleNavigate } = useInvite();

  useEffect(() => {
    if (inviteId) {
      handleGetInvite();
    } else {
      handleNavigate();
    }
  }, []);

  return (
    <div className="w-screen h-screen">
      <LoadingComponent loading={true} />;
    </div>
  );
};

export default JoinPage;
