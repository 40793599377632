import SignUpComponent from "components/SignUp";
import { useAppState } from "config/store";
import useSignUp from "hooks/useSignUp";
import useSSOAuth from "hooks/useSSOAuth/useSSOAuth";
import React from "react";

const SignUpPage = (): React.ReactElement => {
  const props = useSignUp();
  const ssoProps = useSSOAuth();
  const appState = useAppState();
  const invite = appState.invite.get();
  return <SignUpComponent {...props} {...ssoProps} invite={invite} />;
};

export default SignUpPage;
