import CreateStoreItemProduct from "components/CreateStoreItemProduct";
import LoadingComponent from "components/Shared/Loading";
import useProduct from "hooks/useProduct";
import useStoreItems from "hooks/useStoreItems";
import useStoreItemProducts from "hooks/useStoreItemProducts";
import React, { useEffect, useRef } from "react";
import { ProductWithVariants } from "types";

const CreateStoreItemPage = (): React.ReactElement => {
  const { measurementUnits } = useStoreItems();
  const props = useStoreItemProducts();
  const { handleGetProductsWithVariants, loaders } = useProduct();

  const productsWithVariants = useRef<ProductWithVariants[]>([]);
  useEffect(() => {
    (async () => {
      productsWithVariants.current = await handleGetProductsWithVariants();
    })();
  }, []);

  return (
    <LoadingComponent loading={loaders.fetchingProduct}>
      <CreateStoreItemProduct
        {...props}
        measurementUnits={measurementUnits}
        products={productsWithVariants.current}
      />
    </LoadingComponent>
  );
};

export default CreateStoreItemPage;
