import React, { SVGProps } from "react";

const ArrowOutward = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="mask0_445_2290" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_445_2290)">
        <path
          d="M10.6666 29.407L8.92627 27.6667L25.3269 11.25H10.4166V8.75006H29.5832V27.9166H27.0833V13.0064L10.6666 29.407Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default ArrowOutward;
