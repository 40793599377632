import OnboardingComponent from "components/Onboarding";
import useOnboarding from "hooks/useOnboarding";
import React from "react";

const OnboardingPage = (): React.ReactElement => {
  const props = useOnboarding();

  return <OnboardingComponent {...props} />;
};

export default OnboardingPage;
