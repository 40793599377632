import { OnboardingProps } from "types";
import React from "react";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Error from "components/Shared/Error";
import SubTitle from "components/Shared/SubTitle";
import Title from "components/Shared/Title";
import Autocomplete from "components/Shared/Autocomplete";
import ArrowLeft from "icons/ArrowLeft";

const OnboardingBusiness = (props: OnboardingProps): React.ReactElement => {
  const {
    onboardingForm,
    handleFormChange,
    handleBusinessFormSubmit,
    error,
    businessFormIsValid,
    loaders,
    businessTypes,
    currencies,
    handleGoBack
  } = props;

  return (
    <div className="w-screen h-screen pl-0 md:pl-12 lg:pl-40">
      <header className="pr-0 md:pr-12 lg:pr-40 pt-6 sm:pt-10 w-full flex flex-col lg:flex-row">
        <img src="/logo.svg" alt="Bloom Logo" className="w-32 sm:w-44 mb-2 lg:mb-0 ml-4 sm:ml-0" />
      </header>
      <div className="flex justify-center mx-4 sm:mx-0 mt-14 sm:mt-20">
        <div className="bg-white rounded-sm px-4 py-10 md:p-20 sm:w-4/5 md:2/3 xl:w-1/2">
          <span
            className="font-bold text-sm flex cursor-pointer"
            data-testid="backLink"
            onClick={handleGoBack}>
            <span className="text-primary mr-1">
              <ArrowLeft />
            </span>
            Back
          </span>

          <Title className="text-center">Add a business!</Title>
          <SubTitle>Tell us about you and your business</SubTitle>
          <form
            onSubmit={handleBusinessFormSubmit}
            className="mt-10 sm:mt-16"
            data-testid="onboardingForm">
            <Error error={error} />
            <div>
              <TextInput
                data-testid="businessName"
                type="text"
                name="businessName"
                onChange={handleFormChange}
                value={onboardingForm.businessName}
                required
                label="Your business name"
              />
            </div>
            <div className="mb-4">
              <Autocomplete
                disableNew
                type="text"
                name="businessType"
                onChange={handleFormChange}
                value={onboardingForm.businessType}
                label="What does your business do?"
                data-testid="businessType"
                data={businessTypes.map((businessType) => ({
                  id: businessType.name,
                  name: businessType.name
                }))}
                placeholder="Start typing to filter business types"
              />
              <small className="flex -mt-4">
                {
                  businessTypes.find(
                    (businessType) => businessType.name == onboardingForm.businessType
                  )?.description
                }
              </small>
            </div>
            <div>
              <Autocomplete
                disableNew
                type="text"
                name="currency"
                onChange={handleFormChange}
                value={onboardingForm.currency}
                label="What currency do you operate in?"
                data-testid="currency"
                data={currencies.map((currency) => ({
                  id: currency.code,
                  name: `${currency.emoji} ${currency.name} (${currency.symbol})`
                }))}
                placeholder="Start typing to filter currencies"
                dataIdName="currencyCode"
              />
            </div>
            <div>
              <Button
                data-testid="submitButton"
                type="submit"
                disabled={!businessFormIsValid}
                loading={loaders.registeringBusiness}
                className="w-full mt-4">
                Submit details
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OnboardingBusiness;
