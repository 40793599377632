import React from "react";

export const NavNotification = (): React.ReactElement => (
  <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 17.3636V15.8636H2.3077V8.40205C2.3077 7.05719 2.72276 5.86809 3.55287 4.83476C4.38301 3.80142 5.44872 3.14053 6.75 2.85208V2.229C6.75 1.88179 6.87142 1.58666 7.11425 1.34361C7.35708 1.10054 7.65195 0.979004 7.99885 0.979004C8.34575 0.979004 8.64099 1.10054 8.88457 1.34361C9.12816 1.58666 9.24995 1.88179 9.24995 2.229V2.85208C10.5512 3.14053 11.6169 3.80142 12.4471 4.83476C13.2772 5.86809 13.6922 7.05719 13.6922 8.40205V15.8636H15.5V17.3636H0.5ZM7.99828 20.1713C7.50069 20.1713 7.0753 19.9943 6.7221 19.6403C6.3689 19.2863 6.1923 18.8607 6.1923 18.3636H9.80765C9.80765 18.8623 9.63048 19.2883 9.27615 19.6415C8.92182 19.9947 8.49586 20.1713 7.99828 20.1713ZM3.80765 15.8636H12.1923V8.40205C12.1923 7.24435 11.783 6.25621 10.9644 5.43763C10.1458 4.61903 9.15767 4.20973 7.99997 4.20973C6.84227 4.20973 5.85413 4.61903 5.03555 5.43763C4.21695 6.25621 3.80765 7.24435 3.80765 8.40205V15.8636Z"
      fill="currentColor"
    />
  </svg>
);
