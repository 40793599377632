import Amount from "components/Shared/Amount";
import {
  NotificationActionType,
  NotificationAction,
  NotificationData,
  ExpenseActionView,
  OrderActionView,
  OrderPaymentActionView,
  MemberActionView,
  StockActionView
} from "types";
import React from "react";
import { formatDate, formatReadableDate } from "helpers/date";
import { subDays } from "date-fns";

export const actionTypeMap: Record<NotificationActionType, string> = {
  CREATE: "created",
  UPDATE: "updated",
  DELETE: "deleted"
};

export const actionMap: Record<NotificationAction, string> = {
  EXPENSE: "an expense",
  ORDER: "an order",
  ORDER_PAYMENT: "an order payment",
  MEMBER: "a member",
  STORE_ITEM_STOCK: "stock for a store item"
};

export const getNotificationDescription = (
  action: NotificationAction,
  data: NotificationData
): React.ReactNode => {
  let text: React.ReactNode = "";
  switch (action) {
    case "EXPENSE": {
      const { name, amount } = data as ExpenseActionView;
      text = (
        <>
          {name} of <Amount amount={amount} />
        </>
      );
      break;
    }
    case "ORDER": {
      const { orderRef, customerName } = data as OrderActionView;
      text = (
        <>
          Order #{orderRef} for {customerName}
        </>
      );
      break;
    }
    case "ORDER_PAYMENT": {
      const { orderRef, amount } = data as OrderPaymentActionView;
      text = (
        <>
          Order #{orderRef} of <Amount amount={amount} />
        </>
      );
      break;
    }
    case "STORE_ITEM_STOCK": {
      const { name, amount, measurementAmount, measurementUnit } = data as StockActionView;
      text = (
        <>
          {name}({measurementAmount}
          {measurementUnit}) of <Amount amount={amount} />
        </>
      );
      break;
    }
    case "MEMBER": {
      const { name } = data as MemberActionView;
      text = <>{name}</>;
      break;
    }
  }
  return text;
};

export const getRelativeDate = (date: Date): string => {
  if (formatDate(new Date()) == formatDate(date)) {
    return "Today";
  }
  if (formatDate(subDays(new Date(), 1)) == formatDate(date)) {
    return "Yesterday";
  }
  return formatReadableDate(date);
};
