export const clean = (value: string | undefined): string => {
  return (value || "").toLowerCase().replace(/\s+/g, " ").trim();
};

export const capitalize = (str: string): string => {
  return str
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(" ");
};
