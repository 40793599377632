import EditProduct from "components/EditProduct";
import useProduct from "hooks/useProduct";
import React from "react";
import LoadingComponent from "components/Shared/Loading";

const EditExpensePage = (): React.ReactElement => {
  const { loaders, ...props } = useProduct();

  return (
    <LoadingComponent loading={loaders.fetchingProduct}>
      <EditProduct {...props} loaders={loaders} />
    </LoadingComponent>
  );
};

export default EditExpensePage;
