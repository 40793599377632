import api from "config/api";
import { ERROR_OCCURRED, FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { isEmail } from "helpers/validate";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormChangeEvent,
  Response,
  UseMembersType,
  MemberForm,
  MembersLoaders,
  Users,
  Member,
  Invite,
  Permission
} from "types";
import { AlertType, SegmentEvent } from "types/enum";

const useMembers = (): UseMembersType => {
  const navigate = useNavigate();
  const { memberId } = useParams();
  const { showAlert } = useAlert();

  const defaultmemberForm: MemberForm = {
    email: "",
    permissions: {
      CAN_VIEW_BUSINESS_FINANCIAL_ANALYSIS: false,
      CAN_VIEW_ALL_EXPENSES: false,
      CAN_VIEW_ALL_ORDERS: false,
      CAN_VIEW_ALL_STORE_ITEMS: false,
      CAN_VIEW_PRODUCTS: false,
      CAN_VIEW_SETTINGS: false
    }
  };

  // UseStates
  const [memberForm, setMemberForm] = useState<MemberForm>(defaultmemberForm);
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<MembersLoaders>({
    savingMember: false,
    fetchingMembers: true
  });
  const [error, setError] = useState("");
  const [users, setUsers] = useState<Users>({
    members: [],
    pendingInvites: []
  });
  const [member, setMember] = useState<Member>();
  const [selectedMember, setSelectedMember] = useState<Member>();
  const [selectedInvite, setSelectedInvite] = useState<Invite>();
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState("");

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setMemberForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleTogglePermission = (permission: Permission): void => {
    setMemberForm((prev) => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        [permission]: !prev.permissions[permission]
      }
    }));
    setError("");
  };

  const handleInviteMember = async (): Promise<boolean> => {
    const requestData = {
      email: memberForm.email.toLowerCase(),
      permissions: Object.keys(memberForm.permissions)
        .filter((key) => !!memberForm.permissions[key as Permission])
        .map((permission) => permission)
    };

    try {
      const json: Response<string> = await api.post("member/invite", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.MEMBER_INVITED, {
          email: memberForm.email.toLowerCase()
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleUpdateMember = async (): Promise<boolean> => {
    const requestData = {
      permissions: Object.keys(memberForm.permissions)
        .filter((key) => !!memberForm.permissions[key as Permission])
        .map((permission) => permission)
    };

    try {
      const json: Response<string> = await api
        .put(`member/${memberId}`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.MEMBER_MODIFIED, {
          email: memberForm.email.toLowerCase()
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setLoaders((prev) => ({ ...prev, savingMember: true }));
    let result = false;
    if (memberId) {
      result = await handleUpdateMember();
    } else {
      result = await handleInviteMember();
    }

    setLoaders((prev) => ({ ...prev, savingMember: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
      navigate("/settings/members");
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  const handleGetMembers = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingMembers: true }));

    try {
      const json: Response<Users> = await api.get("member").json();
      if (json.code === 200) {
        setUsers(json.data);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingMembers: false }));
  };

  const handleGetMember = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingMembers: true }));

    try {
      const json: Response<Member> = await api.get(`member/${memberId}`).json();
      if (json.code === 200) {
        (json.data.permissions || []).forEach((permission) => {
          memberForm.permissions[permission] = true;
        });
        setMember(json.data);
        setMemberForm({
          email: json.data.email,
          permissions: memberForm.permissions
        });
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingMembers: false }));
  };

  const handleDeleteMember = async (memberId: string): Promise<boolean> => {
    try {
      const json: Response<void> = await api.delete(`member/${memberId}`).json();
      return json.code === 200;
    } catch {
      return false;
    }
  };

  const handleDeleteInvite = async (inviteId: string): Promise<boolean> => {
    try {
      const json: Response<void> = await api.delete(`member/invite/${inviteId}`).json();
      return json.code === 200;
    } catch {
      return false;
    }
  };

  const handleConfirmDeleteMember = (member: Member): void => {
    setDeleteConfirmationMessage(`Are you sure you want to permanently delete ${member.name}?`);
    setSelectedMember(member);
    setSelectedInvite(undefined);
  };

  const handleConfirmDeleteInvite = (invite: Invite): void => {
    setDeleteConfirmationMessage(`Are you sure you want to permanently delete ${invite.email}?`);
    setSelectedInvite(invite);
    setSelectedMember(undefined);
  };

  const handleDeleteConfirmation = async (): Promise<void> => {
    if (!selectedInvite?.id && !selectedMember?.userId) {
      return;
    }
    let result = false;
    if (selectedInvite) {
      result = await handleDeleteInvite(selectedInvite.id);
    }
    if (selectedMember) {
      result = await handleDeleteMember(selectedMember.userId);
    }

    if (result) {
      showAlert(AlertType.SUCCESS);
    } else {
      showAlert(AlertType.DANGER);
    }
    handleDeleteCancellation();
    await handleGetMembers();
  };

  const handleDeleteCancellation = (): void => {
    setDeleteConfirmationMessage("");
    setSelectedMember(undefined);
    setSelectedInvite(undefined);
  };

  // UseEffects
  useEffect(() => {
    setFormIsValid(isEmail(memberForm.email));
  }, [memberForm]);

  useEffect(() => {
    handleGetMembers();
    if (memberId) {
      handleGetMember();
    }
  }, []);

  return {
    memberForm,
    formIsValid,
    loaders,
    error,
    handleFormChange,
    handleFormSubmit,
    users,
    handleDeleteCancellation,
    handleConfirmDeleteMember,
    handleConfirmDeleteInvite,
    handleDeleteConfirmation,
    deleteConfirmationMessage,
    handleTogglePermission,
    member,
    memberId
  };
};

export default useMembers;
