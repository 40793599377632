import CreateExpenseComponent from "components/CreateEditExpense";
import useExpense from "hooks/useExpense";
import React from "react";

const CreateExpensePage = (): React.ReactElement => {
  const props = useExpense();

  return <CreateExpenseComponent {...props} />;
};

export default CreateExpensePage;
