import React from "react";

export const CreditCard = (): React.ReactElement => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1578_4064" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="41">
        <rect width="41" height="41" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1578_4064)">
        <path
          d="M36.7292 10.7625V30.2375C36.7292 31.0917 36.4303 31.8177 35.8323 32.4156C35.2344 33.0135 34.5084 33.3125 33.6542 33.3125H7.34587C6.49171 33.3125 5.76567 33.0135 5.16775 32.4156C4.56983 31.8177 4.27087 31.0917 4.27087 30.2375V10.7625C4.27087 9.90833 4.56983 9.18229 5.16775 8.58438C5.76567 7.98646 6.49171 7.6875 7.34587 7.6875H33.6542C34.5084 7.6875 35.2344 7.98646 35.8323 8.58438C36.4303 9.18229 36.7292 9.90833 36.7292 10.7625ZM6.83337 14.35H34.1667V10.7625C34.1667 10.6486 34.1098 10.5347 33.9959 10.4208C33.882 10.3069 33.7681 10.25 33.6542 10.25H7.34587C7.23199 10.25 7.1181 10.3069 7.00421 10.4208C6.89032 10.5347 6.83337 10.6486 6.83337 10.7625V14.35ZM6.83337 19.8167V30.2375C6.83337 30.3514 6.89032 30.4653 7.00421 30.5792C7.1181 30.6931 7.23199 30.75 7.34587 30.75H33.6542C33.7681 30.75 33.882 30.6931 33.9959 30.5792C34.1098 30.4653 34.1667 30.3514 34.1667 30.2375V19.8167H6.83337Z"
          fill="#00A89D"
        />
      </g>
    </svg>
  );
};
