import Title from "components/Shared/Title";
import Error from "components/Shared/Error";
import ArrowLeft from "icons/ArrowLeft";
import React from "react";
import { Link } from "react-router-dom";
import { CreateEditOrderTemplateProps, OrderFormProduct, SingleProduct } from "types";
import Products from "components/CreateEditOrder/Products";
import Button from "components/Shared/Button";
import Form from "components/Shared/Form";
import { getProductTotal } from "hooks/useOrderForm/useOrderForm.utils";
import Amount from "components/Shared/Amount";
import Edit from "icons/Edit";
import { InlineInput } from "components/Shared/InlineInput/InlineInput";

export const CreateEditOrderTemplate = (
  props: CreateEditOrderTemplateProps
): React.ReactElement => {
  const {
    templateId,
    handleFormSubmit,
    error,
    orderTemplateForm,
    loaders,
    formIsValid,
    handleFormChange
  } = props;
  const isCreatingNewTemplate = !templateId;

  function getVariantCosts(props: CreateEditOrderTemplateProps, item: OrderFormProduct) {
    return (
      props.products
        .find((p) => p.id === item.productId)
        ?.variants.find((v) => v.id === item.variantId)?.cost || {
        total: 0,
        activitiesCost: 0,
        addOnCosts: 0,
        componentMeasurementsCost: 0
      }
    );
  }

  const productionCost = {
    total: orderTemplateForm.products.reduce((acc, product) => {
      if (product.type === "order_item") {
        const variantCost = getVariantCosts(props, product.value);
        return acc + Number(variantCost.total) * Number(product.value.quantity);
      }
      return product.value.items.reduce((acc, item) => {
        const variantCost = getVariantCosts(props, item);
        return acc + Number(variantCost.total) * Number(item.quantity);
      }, 0);
    }, 0),
    materials: orderTemplateForm.products.reduce((acc, product) => {
      if (product.type === "order_item") {
        const variantCost = getVariantCosts(props, product.value);
        return acc + Number(variantCost.componentMeasurementsCost) * Number(product.value.quantity);
      }
      return product.value.items.reduce((acc, item) => {
        const variantCost = getVariantCosts(props, item);
        return acc + Number(variantCost.componentMeasurementsCost) * Number(item.quantity);
      }, 0);
    }, 0),
    services: orderTemplateForm.products.reduce((acc, product) => {
      if (product.type === "order_item") {
        const variantCost = getVariantCosts(props, product.value);
        return acc + Number(variantCost.addOnCosts) * Number(product.value.quantity);
      }
      return product.value.items.reduce((acc, item) => {
        const variantCost = getVariantCosts(props, item);
        return acc + Number(variantCost.addOnCosts) * Number(item.quantity);
      }, 0);
    }, 0),
    activities: orderTemplateForm.products.reduce((acc, product) => {
      if (product.type === "order_item") {
        const variantCost = getVariantCosts(props, product.value);
        return acc + Number(variantCost.activitiesCost) * Number(product.value.quantity);
      }
      return product.value.items.reduce((acc, item) => {
        const variantCost = getVariantCosts(props, item);
        return acc + Number(variantCost.activitiesCost) * Number(item.quantity);
      }, 0);
    }, 0)
  };

  const profit = getProductTotal(orderTemplateForm) - productionCost.total;

  return (
    <div className="mobile-full-screen">
      <div className="bg-white rounded-lg py-form">
        <div className="form-xy">
          <Link to="./../" className="font-bold !text-sm mb-6 inline-flex" data-testid="backLink">
            <span className="text-primary mr-1">
              <ArrowLeft />
            </span>
            Back to templates
          </Link>
          <Title>{isCreatingNewTemplate ? "Add new" : "Edit"} template</Title>
          <div className="overflow-hidden max-w-[100%] mt-4">
            <span className="flex items-center">
              <InlineInput
                name="name"
                id="templateName"
                value={orderTemplateForm.name}
                onChange={handleFormChange}
                placeholder="Template name *"
                className="text-lg md:text-xl"
              />
            </span>
          </div>
        </div>
        <form autoComplete="off" onSubmit={handleFormSubmit} data-testid="templateForm">
          <div className="px-4 md:px-10">
            <Error error={error} />
          </div>

          <div>
            <Products {...props} orderFormProducts={orderTemplateForm.products} />
            <div className="w-full">
              <hr className="flex-1 h-0.5 m-0 mt-3.5 sm:mt-3.5 border-soft-grey space-x-4" />
              <div className="form-x sm:pr-20 flex flex-col items-end justify font-bold relative py-6 space-y-3">
                <span className="">
                  Total: <Amount amount={getProductTotal(orderTemplateForm)} />
                </span>
              </div>
            </div>
            <Form.Footer>
              <Button
                loading={loaders.savingOrderTemplate}
                type="submit"
                disabled={loaders.savingOrderTemplate || !formIsValid}
                className="w-full md:w-60"
                data-testid="continue">
                Save
              </Button>
            </Form.Footer>
          </div>
        </form>
      </div>
      <div
        className={`rounded-lg border ${
          profit < 0 ? "bg-[#FEE5E6] border-danger" : "bg-[#E6F6F5] border-primary"
        }   p-4 pb-3 sm:p-6 sm:pb-3 mx-4 sm:mx-0 mt-2 mb-2`}>
        <div className=" flex justify-between">
          <div className="mb-4">
            <p>
              <b className="mr-1">Production cost:</b>
              <b data-testid="productionCost">
                <Amount amount={productionCost.total} />
              </b>
            </p>
            <p className="text-grey-50 text-xs mt-1" data-testid="orderNote">
              Components ( <Amount amount={productionCost.materials} />) + Add-ons ({" "}
              <Amount amount={productionCost.services} />) + Activities ({" "}
              <Amount amount={productionCost.activities} />)
            </p>
          </div>

          <Link to={"production"} className="link flex space-x-1 items-center">
            <Edit />
            <span>Update</span>
          </Link>
        </div>
        <hr className={`flex-1 h-0.5 m-0 ${profit < 0 ? "border-danger" : "border-primary"}`} />
        <p className="mt-3">
          <b className="mr-1">{profit >= 0 ? "Profit" : "Loss"}:</b>
          <b data-testid="productionCost">
            <Amount amount={profit} />
          </b>
        </p>
      </div>
    </div>
  );
};
