import { useInvoice } from "hooks/useInvoice/useInvoice";
import LoadingComponent from "components/Shared/Loading";
import { Invoice } from "components/Invoice/Invoice";
import React, { useEffect } from "react";
import { track } from "helpers/analytics";
import { SegmentEvent } from "types/enum";

const ViewInvoicePage = (): React.ReactElement => {
  const invoiceProps = useInvoice();
  useEffect(() => {
    if (invoiceProps.invoiceDetail) {
      track(SegmentEvent.INVOICE_VIEWED, {
        business: invoiceProps.invoiceDetail.business.name,
        customer: invoiceProps.invoiceDetail.customer.name,
        orderId: invoiceProps.invoiceDetail.orderId,
        invoiceId: invoiceProps.invoiceDetail.id
      });
    }
  }, [invoiceProps.invoiceDetail]);

  return (
    <div className="w-screen h-screen">
      <LoadingComponent loading={invoiceProps.loaders.fetchingInvoice}>
        <Invoice {...invoiceProps} />
      </LoadingComponent>
    </div>
  );
};

export default ViewInvoicePage;
