import { isEmail, isNotEmpty } from "helpers/validate";
import { useEffect, useState } from "react";
import { UseSignInType, SignInForm, SignInLoaders, FormChangeEvent } from "types";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "config/firebase";
import { useNavigate } from "react-router-dom";
import { useAppState } from "config/store";
import { ERROR_OCCURRED, INCORRECT_CREDENTIALS } from "constants/response";
import { none } from "@hookstate/core";
import { identify, track } from "helpers/analytics";
import { SegmentEvent } from "types/enum";

const useSignIn = (): UseSignInType => {
  const navigate = useNavigate();
  const appState = useAppState();

  // UseStates
  const [signInForm, setSignInForm] = useState<SignInForm>({
    email: "",
    password: ""
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [loaders, setLoaders] = useState<SignInLoaders>({
    authenticatingUserAccount: false
  });
  const [error, setError] = useState("");

  // Handlers
  const handleTogglePasswordVisibility = () => {
    setPasswordIsVisible((prev) => !prev);
  };

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setSignInForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setLoaders((prev) => ({ ...prev, authenticatingUserAccount: true }));

    signInWithEmailAndPassword(auth, signInForm.email, signInForm.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        appState.user.set(user);
        identify();
        track(SegmentEvent.SIGNED_IN, {
          provider: "email"
        });
        navigate("/post-auth");
      })
      .catch((error) => {
        setError(error.code.includes("auth/") ? INCORRECT_CREDENTIALS : ERROR_OCCURRED);
      })
      .finally(() => {
        setLoaders((prev) => ({ ...prev, authenticatingUserAccount: false }));
      });
  };

  // UseEffects
  useEffect(() => {
    setFormIsValid(isEmail(signInForm.email) && isNotEmpty(signInForm.password));
  }, [signInForm]);

  useEffect(() => {
    appState.user.set(none);
    appState.accessToken.set(none);
    appState.accessToken.set(none);
    appState.business.set(none);
  }, []);

  return {
    signInForm,
    formIsValid,
    passwordIsVisible,
    handleTogglePasswordVisibility,
    handleFormChange,
    handleFormSubmit,
    loaders,
    error
  };
};

export default useSignIn;
