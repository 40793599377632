import api from "config/api";
import { FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { isNotEmpty, isNumber } from "helpers/validate";
import {
  collapseSingleAndGroupedProducts,
  formatOrderItemDiscountAndTax,
  formatOrderItemToOrderFormProduct,
  formatOrderProductFormItemToRequestData
} from "hooks/useOrderForm/useOrderForm.utils";
import { useOrderProductsForm } from "hooks/useOrderProductsForm/useOrderProductsForm";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormChangeEvent,
  OrderTemplateForm,
  OrderTemplateFormLoaders,
  UseOrderTemplateFormType,
  Response,
  OrderTemplate
} from "types";
import { AlertType, SegmentEvent } from "types/enum";
import { v4 } from "uuid";

export const useOrderTemplateForm = (): UseOrderTemplateFormType => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const { showAlert } = useAlert();

  const defaultOrderTemplateForm: OrderTemplateForm = {
    name: "",
    products: [
      {
        key: v4(),
        type: "order_item",
        value: {
          groupId: "",
          productId: "",
          variantId: "",
          quantity: "1",
          unitPrice: ""
        }
      }
    ]
  };

  const [orderTemplateForm, setOrderTemplateForm] =
    useState<OrderTemplateForm>(defaultOrderTemplateForm);
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<OrderTemplateFormLoaders>({
    savingOrderTemplate: false,
    fetchingOrderTemplate: true
  });
  const [error, setError] = useState("");
  const productIndexToBeRemoved = useRef<number>(-1);
  const groupedItemIndexToBeRemoved = useRef<number>(-1);

  const orderProductsForm = useOrderProductsForm(setOrderTemplateForm, setError);

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setOrderTemplateForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleCreateTemplate = async (): Promise<boolean> => {
    const requestData = {
      name: orderTemplateForm.name,
      items: orderTemplateForm.products.map((product) =>
        formatOrderProductFormItemToRequestData(product)
      )
    };

    try {
      const json: Response<string> = await api.post("order-template", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.ORDER_TEMPLATE_ADDED, {
          name: orderTemplateForm.name,
          noOfItems: requestData.items.length,
          templateId: json.data,
          items: requestData.items.map(({ type, value }) => ({ type, value }))
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleUpdateTemplate = async (): Promise<boolean> => {
    const requestData = {
      name: orderTemplateForm.name,
      items: orderTemplateForm.products.map((product) =>
        formatOrderProductFormItemToRequestData(product)
      )
    };

    try {
      await api.put(`order-template/${templateId}`, { json: requestData });
    } catch {
      return false;
    }
    return true;
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setLoaders((prev) => ({ ...prev, savingOrderTemplate: true }));
    let result;
    if (templateId) {
      result = await handleUpdateTemplate();
    } else {
      result = await handleCreateTemplate();
    }

    setLoaders((prev) => ({ ...prev, savingOrderTemplate: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
      navigate("/sales/templates");
    } else {
      showAlert(AlertType.DANGER);
    }
  };

  const handleGetOrderTemplate = async (templateId: string): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingOrderTemplate: true }));

    try {
      const json: Response<OrderTemplate> = await api.get(`order-template/${templateId}`).json();
      if (json.code === 200) {
        setOrderTemplateForm((prev) => ({
          name: json.data.name,
          products: prev.products
        }));
        orderProductsForm.handleSetProducts(
          json.data.items.map((item) => formatOrderItemToOrderFormProduct(item, false))
        );
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingOrderTemplate: false }));
  };

  // UseEffects
  useEffect(() => {
    const productsAreValid =
      orderTemplateForm.products.length > 0 &&
      collapseSingleAndGroupedProducts(orderTemplateForm.products).reduce((prev, curr) => {
        return (
          prev && isNotEmpty(curr.variantId) && isNumber(curr.quantity) && isNumber(curr.unitPrice)
        );
      }, true);

    const groupNamesAreValid = orderTemplateForm.products.every(
      (product) => product.type == "order_item" || isNotEmpty(product.value.name)
    );

    setFormIsValid(isNotEmpty(orderTemplateForm.name) && productsAreValid && groupNamesAreValid);
  }, [orderTemplateForm]);

  useEffect(() => {
    if (templateId) {
      handleGetOrderTemplate(templateId);
    }
  }, []);

  return {
    orderTemplateForm,
    formIsValid,
    loaders,
    error,
    handleFormChange,
    ...orderProductsForm,
    handleFormSubmit,
    templateId
  };
};
