import { PromptProps } from "types";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import React from "react";
import ConfirmationDialog from "../ConfirmationDialog";

const Prompt = (props: PromptProps): React.ReactElement => {
  const isBlocked = props.when;
  const blocker = useBlocker(isBlocked);

  React.useEffect(() => {
    if (blocker.state === "blocked" && !isBlocked) {
      blocker.reset();
    }
  }, [blocker, isBlocked]);

  if (blocker.state === "blocked" && isBlocked) {
    return (
      <ConfirmationDialog
        message={
          props.message ||
          "Are you sure you want to leave the current page? The changes that you made won't be saved."
        }
        handleContinue={() => blocker.proceed?.()}
        handleCancel={() => blocker.reset?.()}
      />
    );
  }

  return <></>;
};

export default Prompt;
