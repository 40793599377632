import { formatDate, formatMonth, formatReadableMonth } from "helpers/date";
import React from "react";
import { SelectProps } from "types";
import { eachMonthOfInterval, endOfMonth } from "date-fns";
import { addYears } from "date-fns";
import Select from "../Select";

const MonthInput = ({
  min,
  monthFormat,
  ...props
}: SelectProps & { min?: string; monthFormat?: "start" | "end" }): React.ReactElement => {
  const start = new Date(min || "2021-01-01");
  const end = addYears(new Date(), 4);
  const months = eachMonthOfInterval({
    start,
    end
  });
  return (
    <Select
      data-testid="dateInput"
      value={
        !monthFormat
          ? formatMonth(props.value as string)
          : monthFormat === "start"
          ? formatMonth(props.value as string)
          : formatDate(endOfMonth(props.value as string))
      }
      {...props}>
      <option disabled value="">
        {props.label}
      </option>
      {months.map((month) => (
        <option
          value={
            !monthFormat
              ? formatMonth(month)
              : monthFormat === "start"
              ? formatMonth(month)
              : formatDate(endOfMonth(month))
          }
          key={formatReadableMonth(month)}>
          {formatReadableMonth(month)}
        </option>
      ))}
    </Select>
  );
};

export default MonthInput;
