import OverviewComponent from "components/Overview";
import useOverview from "hooks/useOverview";
import React from "react";
import LoadingComponent from "components/Shared/Loading";

const OverviewPage = (): React.ReactElement => {
  const props = useOverview();
  return (
    <LoadingComponent loading={props.loaders.fetchingReport && !props.report}>
      <OverviewComponent {...props} />
    </LoadingComponent>
  );
};

export default OverviewPage;
