import { hasFeature } from "components/Layout/Dashboard/Dashboard.utils";
import { useAppState } from "config/store";
import { DefaultComponentProps } from "types";
import React from "react";
import { FeatureFlag } from "types/enum";

const Hidden = (props: DefaultComponentProps & { flag?: FeatureFlag }): React.ReactElement => {
  const appState = useAppState();

  const hidden = props.flag && !hasFeature(props.flag, appState.business.get()?.features);
  return <>{!hidden && props.children}</>;
};

export default Hidden;
