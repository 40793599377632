import { Tab } from "@headlessui/react";
import React from "react";
import cx from "classnames";
import { TabTitleProps } from "types";
import { useNavigate } from "react-router-dom";

export const TabTitle = ({ isSelected, title, icon, link }: TabTitleProps): React.ReactElement => {
  const navigate = useNavigate();
  return (
    <Tab
      className="flex-1 p-2 md:p-4 border-b-4 focus:outline-none ui-selected:text-primary ui-selected:border-primary ui-not-selected:border-white"
      onClick={(): string | undefined | void => link && navigate(link)}>
      <span className="flex justify-center items-center">
        <span className="mr-2">{icon}</span>
        <span className={cx(!isSelected && "hidden md:flex")}>{title}</span>
      </span>
    </Tab>
  );
};
