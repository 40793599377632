import React from "react";

const ArrowInward = (): React.ReactElement => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_445_2287" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_445_2287)">
        <path
          d="M8.91016 31.0897V15.2564H11.4101V26.8334L31.1601 7.08337L32.9165 8.83975L13.1665 28.5897H24.7434V31.0897H8.91016Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default ArrowInward;
