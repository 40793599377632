import React, { useState } from "react";
import cx from "classnames";
import { Caret } from "icons/landing/caret";

export const SimpleAccordion = (
  props: React.HTMLAttributes<HTMLElement> & {
    children?: React.ReactNode;
    title: React.ReactNode;
  }
): React.ReactElement => {
  const { children, title } = props;
  const [open, setOpen] = useState(false);
  return (
    <div className="my-2 w-full">
      <div
        className={cx(
          "py-6 text-white flex justify-between items-center font-bold cursor-pointer border-t border-grey-30",
          open && "border-grey-30 text-primary"
        )}
        onClick={(): void => {
          setOpen((open) => !open);
        }}>
        {title}
        <Caret open={open} />
      </div>
      <div className={cx("flex-grow overflow-hidden text-grey-30", !open && "h-0")}>{children}</div>
    </div>
  );
};
