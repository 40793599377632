/// <reference types="@types/segment-analytics" />

import { appState } from "config/store";
import { SegmentEvent } from "types/enum";

const isProd = process.env.NODE_ENV === "production";

export function identify(): void {
  if (!isProd) return;
  window.analytics.identify(appState.user.get()?.uid || "", {
    businessId: appState.business.get()?.id || "",
    userId: appState.user.get()?.uid || "",
    email: appState.user.get()?.email || "",
    name: appState.user.get()?.displayName || ""
  });
}

export const load = () => {
  if (!isProd) return;
  window.analytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY || "");
};
export const page = () => {
  if (!isProd) return;
  window.analytics.page();
};

export const group = () => {
  if (!isProd) return;
  window.analytics.group(appState.business.get()?.id || "", {
    name: appState.business.get()?.name
  });
};

// eslint-disable-next-line
export const track = (name: SegmentEvent, properties: any = {}) => {
  if (!isProd) return;

  if (!properties.userId) {
    properties.userId = appState.user.get()?.uid || "";
  }
  if (!properties.businessId) {
    properties.businessId = appState.business.get()?.id || "";
  }
  if (!properties.email) {
    properties.email = appState.user.get()?.email || "";
  }
  window.analytics.track(name.toString(), properties);
};
