import React, { useEffect, useState } from "react";
import { ConfirmationDialogProps } from "types";
import Dialog from "../Dialog";

const ConfirmationDialog = (props: ConfirmationDialogProps): React.ReactElement => {
  const { message, handleContinue, handleCancel } = props;
  const [isOpen, setIsOpen] = useState(!!message);

  useEffect(() => {
    setIsOpen(!!message);
  }, [message]);

  useEffect(() => {
    if (!isOpen) {
      handleCancel();
    }
  }, [isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Confirm Action"
      footer={
        <>
          <button
            data-testid="continueButton"
            onClick={handleContinue}
            data-modal-hide="defaultModal"
            type="button"
            className="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-soft-teal font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Continue
          </button>
          <button
            data-testid="cancelButton"
            onClick={handleCancel}
            data-modal-hide="defaultModal"
            type="button"
            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-soft-teal rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10">
            Cancel
          </button>
        </>
      }>
      {message}
    </Dialog>
  );
};

export default ConfirmationDialog;
