import React from "react";

export const NavProduct = (): React.ReactElement => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none">
    <path
      d="M4.2 8.10386L9 0.203857L13.8 8.10386H4.2ZM14.5 19.1039C13.35 19.1039 12.375 18.7039 11.575 17.9039C10.775 17.1039 10.375 16.1289 10.375 14.9789C10.375 13.8289 10.775 12.8539 11.575 12.0539C12.375 11.2539 13.35 10.8539 14.5 10.8539C15.65 10.8539 16.625 11.2539 17.425 12.0539C18.225 12.8539 18.625 13.8289 18.625 14.9789C18.625 16.1289 18.225 17.1039 17.425 17.9039C16.625 18.7039 15.65 19.1039 14.5 19.1039ZM0.375 18.6039V11.3539H7.625V18.6039H0.375ZM14.5 17.6039C15.2333 17.6039 15.8543 17.3499 16.363 16.8419C16.871 16.3332 17.125 15.7122 17.125 14.9789C17.125 14.2455 16.871 13.6245 16.363 13.1159C15.8543 12.6079 15.2333 12.3539 14.5 12.3539C13.7667 12.3539 13.146 12.6079 12.638 13.1159C12.1293 13.6245 11.875 14.2455 11.875 14.9789C11.875 15.7122 12.1293 16.3332 12.638 16.8419C13.146 17.3499 13.7667 17.6039 14.5 17.6039ZM1.875 17.1039H6.125V12.8539H1.875V17.1039ZM6.85 6.60386H11.15L9 3.12886L6.85 6.60386Z"
      fill="currentColor"
    />
  </svg>
);
