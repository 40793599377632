import SettingsComponent from "components/Settings";
import LoadingComponent from "components/Shared/Loading";
import useSettings from "hooks/useSettings";
import React from "react";

const SettingsPage = (): React.ReactElement => {
  const settingsProps = useSettings();

  return (
    <LoadingComponent loading={false}>
      <SettingsComponent {...settingsProps} />
    </LoadingComponent>
  );
};

export default SettingsPage;
