import React, { useEffect, useRef } from "react";
import "./Range.css";

export const RangeInput = (
  props: React.InputHTMLAttributes<HTMLInputElement>
): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const clonedValue = props.value;
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.setProperty("--val", `${props.value ? props.value : 0}`);
      inputRef.current.style.setProperty("--min", `${props.min || "0"}`);
      inputRef.current.style.setProperty("--max", `${props.max || "100"}`);
    }
  }, [inputRef.current, props.value]);

  return <input ref={inputRef} {...props} value={clonedValue} type="range" />;
};
