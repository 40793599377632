import api from "config/api";
import { ERROR_OCCURRED, FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { getDate } from "helpers/date";
import { isNotEmpty } from "helpers/validate";
import useDateRange from "hooks/shared/useDateRange";
import useSort from "hooks/useSort";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormChangeEvent,
  OrderLoaders,
  UseOrderType,
  Response,
  Orders,
  Order,
  OrderDetail,
  OrderDetailPayment,
  OrderPaymentForm
} from "types";
import { AlertType, SegmentEvent } from "types/enum";

const useOrder = (): UseOrderType => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { showAlert } = useAlert();
  const { dateRange, handleDateRangeChange, getDateFilter } = useDateRange();

  const defaultOrderPaymentForm: OrderPaymentForm = {
    paymentAmount: "",
    paymentDate: getDate()
  };

  // UseStates
  const [paymentForm, setPaymentForm] = useState<OrderPaymentForm>(defaultOrderPaymentForm);
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<OrderLoaders>({
    fetchingOrder: true
  });
  const [error, setError] = useState("");
  const [ordersSummary, setOrdersSummary] = useState<Orders>();
  const [orders, setOrders] = useState<Order[]>([]);
  const [order, setOrder] = useState<Order>();
  const [orderDetail, setOrderDetail] = useState<OrderDetail>();
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState("");
  const [creatingPayment, setCreatingPayment] = useState(false);
  const sort = useSort(setOrders);

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setPaymentForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleGetOrders = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingOrder: true }));

    try {
      const json: Response<Orders & { orders: Order[] }> = await api
        .get(`order?${getDateFilter()}`)
        .json();
      if (json.code === 200) {
        setOrdersSummary(json.data);
        setOrders(json.data.orders);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingOrder: false }));
  };

  const handleGetOrder = async (orderId: string): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingOrder: true }));

    try {
      const json: Response<OrderDetail> = await api.get(`order/${orderId}`).json();
      if (json.code === 200) {
        setOrderDetail(json.data);
        setPaymentForm((prev) => ({ ...prev, paymentDate: json.data.date }));
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingOrder: false }));
  };

  const handleDeleteOrder = async (orderId: string): Promise<boolean> => {
    try {
      const json: Response<void> = await api.delete(`order/${orderId}`).json();
      return json.code === 200;
    } catch {
      return false;
    }
  };

  const handleConfirmDelete = (order: Order): void => {
    setDeleteConfirmationMessage(
      `Are you sure you want to permanently delete ${order.customerName}'s order?`
    );
    setOrder(order);
  };

  const handleDeleteConfirmation = async (): Promise<void> => {
    if (order?.id) {
      await handleDeleteOrder(order?.id);
      handleDeleteCancellation();
      if (orderId) {
        navigate("/sales/orders");
      } else {
        await handleGetOrders();
      }
    }
  };

  const handleDeleteCancellation = (): void => {
    setDeleteConfirmationMessage("");
    setOrder(undefined);
  };

  const handleDeletePayment = async (payment: OrderDetailPayment): Promise<void> => {
    if (!orderId) {
      return;
    }
    try {
      const json: Response<void> = await api
        .delete(`order/${orderId}/payment/${payment.id}`)
        .json();
      if (json.code === 200) {
        showAlert(AlertType.SUCCESS);
        return handleGetOrder(orderId);
      }
    } catch {
      /* */
    }
    showAlert(AlertType.DANGER);
    setError(ERROR_OCCURRED);
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (!orderId || !paymentForm.paymentAmount) {
      return;
    }
    const requestData = {
      date: paymentForm.paymentDate,
      amount: paymentForm.paymentAmount
    };
    try {
      const json: Response<void> = await api
        .post(`order/${orderId}/payment`, { json: requestData })
        .json();
      if (json.code === 201) {
        showAlert(AlertType.SUCCESS);
        setPaymentForm(defaultOrderPaymentForm);
        setCreatingPayment(false);
        track(SegmentEvent.ORDER_PAYMENT_ADDED, {
          amount: paymentForm.paymentAmount,
          orderId
        });
        return handleGetOrder(orderId);
      }
    } catch {
      /* */
    }
    showAlert(AlertType.DANGER);
    setError(ERROR_OCCURRED);
  };

  const handleSort = (field: string): void => {
    sort.sort(orders, field as keyof Order);
  };

  // UseEffects
  useEffect(() => {
    if (orderId) {
      handleGetOrder(orderId);
    }
  }, []);

  useEffect(() => {
    setFormIsValid(isNotEmpty(paymentForm.paymentAmount) && isNotEmpty(paymentForm.paymentDate));
  }, [paymentForm]);

  return {
    paymentForm,
    formIsValid,
    loaders,
    error,
    handleFormChange,
    handleFormSubmit,
    orders,
    handleGetOrders,
    handleConfirmDelete,
    handleDeleteConfirmation,
    deleteConfirmationMessage,
    handleDeleteCancellation,
    dateRange,
    handleDateRangeChange,
    orderDetail,
    handleDeletePayment,
    creatingPayment,
    setCreatingPayment,
    orderId,
    sort: { ...sort, handleSort },
    ordersSummary
  };
};

export default useOrder;
