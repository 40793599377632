import {
  FormChangeEvent,
  GroupedProduct,
  GroupedProductOrderItem,
  OrderForm,
  OrderFormProduct,
  OrderProductsForm,
  ProductDetailVariant,
  SingleProduct,
  SingleProductOrderItem
} from "types";

export const resetProduct = (
  event: FormChangeEvent,
  variant: ProductDetailVariant | undefined
): Partial<OrderFormProduct> => {
  const { name } = event.target;
  const product: Partial<OrderFormProduct> = {};

  if (name === "productId") {
    product.variantId = "";
    product.unitPrice = "";
  }

  if (name === "variantId") {
    product.unitPrice = variant?.defaultSellingPrice?.toString() || "";
  }

  return product;
};

export const collapseSingleAndGroupedProducts = (
  products: (GroupedProduct | SingleProduct)[]
): OrderFormProduct[] => {
  const data = products.map((product) => {
    return product.type == "order_item" ? product.value : product.value.items;
  });

  return data.flat();
};

export const collapseSingleAndGroupedProductsWithGroupQuantity = (
  products: (GroupedProduct | SingleProduct)[]
): OrderFormProduct[] => {
  const data = products.map((product) => {
    return product.type == "order_item"
      ? product.value
      : product.value.items.map((item) => ({
          ...item,
          quantity: `${+item.quantity * product.value.quantity}`
        }));
  });

  return data.flat();
};

export const formatOrderProductFormItemToRequestData = (
  product: GroupedProduct | SingleProduct
) => {
  if (product.type == "order_item") {
    return {
      type: "order_item",
      value: {
        itemId: product.value.orderItemId,
        variantId: product.value.variantId,
        quantity: +product.value.quantity,
        unitPrice: +product.value.unitPrice
      }
    };
  }

  return {
    type: "group",
    value: {
      groupId: product.value.id,
      name: product.value.name,
      quantity: +product.value.quantity,
      items: product.value.items.map((item) => ({
        variantId: item.variantId,
        quantity: +item.quantity,
        unitPrice: +item.unitPrice,
        itemId: item.orderItemId
      }))
    }
  };
};

export const formatOrderItemToOrderFormProduct = (
  product: SingleProductOrderItem | GroupedProductOrderItem,
  preserveIds = true
): GroupedProduct | SingleProduct => {
  if (product.type == "order_item") {
    return {
      key: product.value.id,
      type: "order_item",
      value: {
        groupId: product.value.groupId,
        productId: product.value.product.id,
        variantId: product.value.variant.id,
        quantity: product.value.quantity.toString(),
        unitPrice: product.value.unitPrice.toString(),
        orderItemId: preserveIds ? product.value.id : undefined
      }
    };
  }
  return {
    key: product.value.id,
    type: "group",
    value: {
      id: preserveIds ? product.value.id : undefined,
      name: product.value.name,
      quantity: product.value.quantity,
      items: product.value.items.map((item) => ({
        groupId: item.groupId,
        productId: item.product.id,
        variantId: item.variant.id,
        quantity: item.quantity.toString(),
        unitPrice: item.unitPrice.toString(),
        orderItemId: preserveIds ? item.id : undefined
      }))
    }
  };
};

export const getProductTotal = ({
  products
}: {
  products: OrderProductsForm["products"];
}): number => {
  return collapseSingleAndGroupedProductsWithGroupQuantity(products).reduce(
    (total: number, product: OrderFormProduct) => {
      return total + (+product.unitPrice * +product.quantity || 0);
    },
    0
  );
};

export const getDiscountAmount = (orderForm: OrderForm): number => {
  if (orderForm.discountValue == 0) {
    return 0;
  }
  if (orderForm.discountType == "AMOUNT") {
    return orderForm.discountValue;
  }
  const productTotal = getProductTotal(orderForm);
  return (orderForm.discountValue / 100) * productTotal;
};

export const getTaxAmount = (orderForm: OrderForm): number => {
  if (orderForm.taxRate == 0) {
    return 0;
  }
  return (orderForm.taxRate / 100) * (+getProductTotal(orderForm) - +getDiscountAmount(orderForm));
};

export const getTotal = (orderForm: OrderForm): number => {
  return +getProductTotal(orderForm) - +getDiscountAmount(orderForm) + +getTaxAmount(orderForm);
};

export const formatOrderItemDiscountAndTax = (orderForm: OrderForm) => {
  return {
    items: orderForm.products.map((product) => formatOrderProductFormItemToRequestData(product)),
    discount:
      orderForm.discountValue > 0
        ? {
            type: orderForm.discountType,
            value: +orderForm.discountValue
          }
        : undefined,
    tax:
      orderForm.taxRate > 0
        ? {
            taxName: orderForm.taxName,
            taxRate: +orderForm.taxRate
          }
        : undefined
  };
};
