import { Link, matchPath, useLocation } from "react-router-dom";
import React from "react";
import { NavMenuItemProps } from "types";
import cx from "classnames";
import Caret from "icons/Caret";

const NavMenu = (props: NavMenuItemProps): React.ReactElement => {
  const location = useLocation();
  const { text, link, icon, className, isSubmenu, subMenu = [], ...anchorProps } = props;
  const active = matchPath({ path: link, end: false }, location.pathname);
  const hasDropDown = subMenu.length > 0;

  return (
    <div>
      <Link
        to={link}
        className={cx(
          "px-4 py-3 text-grey-60 flex items-center rounded-r-lg hover:text-dark-grey hover:font-bold",
          className,
          active && !hasDropDown && "!text-primary bg-dark-grey",
          isSubmenu && "rounded-l-lg"
        )}
        {...anchorProps}
        data-testid="menuLink">
        {icon}
        <span
          className={cx(
            "ml-3 font-semibold flex-grow",
            active && !hasDropDown && "!text-white font-bold",
            isSubmenu && "text-sm"
          )}
          data-testid="menuText">
          {text}
        </span>
        {hasDropDown && (
          <Caret open={!!active} className={cx("ml-4", active && "stroke-primary")} />
        )}
      </Link>
      {hasDropDown && active && (
        <div className="ml-2">
          {subMenu.map((menu) => (
            <NavMenu {...menu} key={menu.link} isSubmenu />
          ))}
        </div>
      )}
    </div>
  );
};

export default NavMenu;
