import DateInput from "components/Shared/DateInput";
import { CreateEditOrderProps } from "types";
import React from "react";
import Checkbox from "components/Shared/Checkbox";
import TextInput from "components/Shared/TextInput";

const AdditionalInformation = ({
  handleFormChange,
  orderForm,
  handleToggle,
  isCreatingNewOrder
}: CreateEditOrderProps & {
  isCreatingNewOrder: boolean;
}): React.ReactElement => {
  return (
    <>
      {isCreatingNewOrder && (
        <>
          <div className="form-xy">
            <p className="text-grey-50 font-bold mb-4 flex flex-wrap justify-between space-y-2 sm:space-y-0">
              <span className="w-full sm:w-1/2">Have you received any payment for this order?</span>
              <span className="w-full sm:w-1/2 md:pl-1.5">
                <Checkbox
                  data-testid="fullPayment"
                  checked={orderForm.fullPayment}
                  onChange={handleToggle}
                  label="I have received full payment"
                  name="fullPayment"
                />
              </span>
            </p>
            <div className="flex space-x-4">
              <DateInput
                name="paymentDate"
                onChange={handleFormChange}
                value={orderForm.paymentDate}
                label="Payment date"
                data-testid="paymentDate"
                min={orderForm.date}
              />
              <TextInput
                disabled={orderForm.fullPayment}
                type="number"
                name="paymentAmount"
                onChange={handleFormChange}
                value={orderForm.paymentAmount}
                label="Amount paid"
                data-testid="paymentAmount"
                min={0}
              />
            </div>
          </div>
          <hr className="flex-1 h-0.5 m-0 border-soft-grey" />
        </>
      )}
      <div className="form-xy">
        <TextInput
          name="note"
          onChange={handleFormChange}
          value={orderForm.note}
          label="Order note (Optional)"
          data-testid="note"
          multiLine
        />
      </div>
    </>
  );
};

export default AdditionalInformation;
