import { useEffect, useState } from "react";
import api from "config/api";
import {
  NotificationsSummary,
  Notification,
  NotificationActionType,
  NotificationAction,
  Response,
  UseNotificationsType,
  NotificationLoaders,
  NotificationActionAt
} from "types";
import { AlertType } from "types/enum";
import { useAlert } from "context/alert/AlertContext";
import { NOTIFICATION_ERROR } from "constants/response";
import { formatToISODate } from "helpers/date";

const useNotifications = (): UseNotificationsType => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationsSummary, setNotificationsSummary] = useState<NotificationsSummary>();
  const [actions, setActions] = useState<NotificationAction[]>();
  const [actionType, setActionType] = useState<NotificationActionType[]>();
  const [actionBy, setActionBy] = useState<string>();
  const [actionAt, setActionAt] = useState<NotificationActionAt>();
  const { showAlert } = useAlert();

  const [loaders, setLoaders] = useState<NotificationLoaders>({
    fetchingNotifications: true
  });

  const handleGetNotifications = async (more = true): Promise<void> => {
    if (!more) {
      setNotifications([]);
    }
    setLoaders((prev) => ({ ...prev, fetchingNotifications: true }));
    const requestData = {
      cursor: more ? notifications.at(-1)?.cursor : undefined,
      actions,
      actionType,
      actionBy,
      actionTime: actionAt
        ? { from: formatToISODate(actionAt.from), to: formatToISODate(actionAt.to) }
        : undefined
    };

    try {
      const json: Response<NotificationsSummary & { notifications: Notification[] }> = await api
        .post("notifications/query", { json: requestData })
        .json();
      setNotifications((prev) => [...(more ? prev : []), ...json.data.notifications]);
      setNotificationsSummary(json.data);
    } catch {
      showAlert(AlertType.DANGER, NOTIFICATION_ERROR);
    }
    setLoaders((prev) => ({ ...prev, fetchingNotifications: false }));
  };

  const handleLoadMore = () => {
    handleGetNotifications();
  };

  const handleClearFilter = () => {
    setActions(undefined);
    setActionBy(undefined);
    setActionAt(undefined);
  };

  useEffect(() => {
    handleGetNotifications(false);
  }, [actions, actionBy, actionAt]);

  return {
    handleLoadMore,
    notifications,
    notificationsSummary,
    actions,
    actionType,
    actionBy,
    loaders,
    setActions,
    setActionBy,
    actionAt,
    setActionAt,
    filtered: [!!actionType, !!actions, !!actionBy, !!actionAt].some((isFilled) => isFilled),
    handleClearFilter
  };
};

export default useNotifications;
