import React from "react";
import Button from "../Button";
import { AutosaveButtonProps } from "types";

export const AutosaveButton = (props: AutosaveButtonProps): React.ReactElement => {
  return (
    <Button type="submit" className="w-full mt-6" {...props}>
      {props.loading ? "Saving" : props.hasChanges ? "Changes unsaved" : "Changes saved"}
    </Button>
  );
};
