import { IconButtonProps, Size } from "types";
import React from "react";
import cx from "classnames";

/* eslint-disable */
const IconButton = (props: IconButtonProps): React.ReactElement => {
  const {
    children,
    loading,
    size,
    className,
    disabled,
    icon,
    iconEnd,
    outlined,
    error,
    ...buttonProps
  } = props;
  const buttonSize: Size = size || "md";
  const sizes: Record<Size, string> = {
    sm: "px-3 py-2 text-sx md:text-sm",
    md: "px-3 py-2 lg:py-2 lg:px-4 text-sm md:text-base",
    lg: "px-5 py-3 lg:px-6 lg:py-5 text-sm md:text-base"
  };
  const loaderSizes: Record<Size, string> = {
    sm: "h-3 w-3 lg:h-4 lg:w-4 ml-2",
    md: "h-4 w-4 lg:h-5 lg:w-5 ml-2",
    lg: "h-5 w-5 lg:h-6 lg:w-6 ml-3"
  };
  return (
    <button
      data-testid="button"
      type="button"
      {...buttonProps}
      disabled={disabled || loading}
      className={cx(
        "rounded-full text-center bg-dark-grey disabled:opacity-50 disabled:cursor-not-allowed inline-flex font-bold items-center",
        sizes[buttonSize],
        outlined
          ? `text-${error ? "danger" : "primary"} !bg-white border-2 border-${
              error ? "danger" : "primary"
            }`
          : "text-white",
        className
      )}>
      {icon && (
        <span data-testid="icon" className={`text-${error ? "danger" : "primary"} mr-2`}>
          {icon}
        </span>
      )}
      {children}
      {loading && (
        <svg
          data-testid="loadingIcon"
          className={cx("animate-spin", !outlined && "text-white", loaderSizes[buttonSize])}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      )}
      {iconEnd && !loading && (
        <span data-testid="icon" className={`text-${error ? "danger" : "primary"} ml-2`}>
          {iconEnd}
        </span>
      )}
    </button>
  );
};

export default IconButton;
