import Dialog from "components/Shared/Dialog";
import { PaymentMethodsProps } from "types";
import React from "react";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Select from "components/Shared/Select";
import { capitalize } from "helpers/string";
import { BANK_DEPOSIT, PAYMENT_LINK } from "constants/general";

export const PaymentMethodsDialog = (props: PaymentMethodsProps): React.ReactElement => {
  const {
    handleFormSubmit,
    error,
    businessPaymentMethodForm,
    handleFormChange,
    formIsValid,
    loaders,
    paymentMethods,
    businessPaymentMethod,
    setIsOpen,
    isOpen
  } = props;

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={`${businessPaymentMethod ? "Update" : "Create"} Business Payment Method`}>
      <form onSubmit={handleFormSubmit} className="" data-testid="paymentMethodForm">
        <Error error={error} />
        <div>
          <Select
            id="paymentMethod"
            name="paymentMethod"
            value={businessPaymentMethodForm.paymentMethod}
            required
            onChange={handleFormChange}
            data-testid="paymentMethod"
            label="Payment method type">
            <option value="" disabled>
              Select a type
            </option>
            {paymentMethods.map((mehod) => (
              <option key={mehod.name} value={mehod.name}>
                {capitalize(mehod.name)}
              </option>
            ))}
          </Select>
        </div>
        {businessPaymentMethodForm.paymentMethod == BANK_DEPOSIT && (
          <>
            <div>
              <TextInput
                name="bank"
                id="bank"
                value={businessPaymentMethodForm.bank}
                required
                onChange={handleFormChange}
                label="Bank name"
              />
            </div>
            <div>
              <TextInput
                type="number"
                name="accountNumber"
                id="accountNumber"
                value={businessPaymentMethodForm.accountNumber}
                required
                onChange={handleFormChange}
                label="Account number"
              />
            </div>
            <div>
              <TextInput
                name="accountName"
                id="accountName"
                value={businessPaymentMethodForm.accountName}
                required
                onChange={handleFormChange}
                label="Account name"
              />
            </div>
          </>
        )}
        {businessPaymentMethodForm.paymentMethod == PAYMENT_LINK && (
          <>
            <div>
              <TextInput
                name="name"
                id="name"
                value={businessPaymentMethodForm.name}
                required
                onChange={handleFormChange}
                label="Payment method name"
              />
            </div>
            <div>
              <TextInput
                name="paymentLink"
                id="paymentLink"
                value={businessPaymentMethodForm.paymentLink}
                onChange={handleFormChange}
                label="Payment link"
                required
              />
            </div>
          </>
        )}
        <Button
          type="submit"
          data-testid="paymentMethodSubmitButton"
          disabled={!formIsValid}
          loading={loaders.savingBusinessPaymentMethods}
          className="w-full mt-6">
          Save
        </Button>
      </form>
    </Dialog>
  );
};
