import React, { useState } from "react";
import { ExpenseConfigurationProps } from "types";
import Title from "components/Shared/Title";
import { Tab } from "@headlessui/react";
import Expenses from "./Expenses";
import Activities from "./Activities";
import Extension from "icons/Extension";
import Customize from "icons/Customize";
import { TabTitle } from "components/Shared/Tab/Tab";
import { useSearchParams } from "react-router-dom";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";

const ProductConfiguration = (props: ExpenseConfigurationProps): React.ReactElement => {
  const { expenseDetail, activityDetail } = props;

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") || "";
  const tabs = ["expenses", "activities"];

  const [selectedIndex, setSelectedIndex] = useState(tabs.indexOf(tab) || 0);

  return (
    <div className="">
      <Title className="pt-2 pb-6 px-4 sm:px-0">
        Expenses
        <InfoTooltip>
          Record all the indirect costs incurred by your business. Indirect costs are costs incurred
          by your business that are not easily measured directly into creating each unit of your
          product. For example: Rent, salaries, utilities, equipments, marketting, transportation
          e.t.c
        </InfoTooltip>
      </Title>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="bg-white flex text-grey-50 font-bold">
          <TabTitle title="Expenses" isSelected={selectedIndex == 0} icon={<Extension />} />
          <TabTitle title="Activites" isSelected={selectedIndex == 1} icon={<Customize />} />
        </Tab.List>

        <Tab.Panels>
          <Tab.Panel>
            <Expenses {...expenseDetail} />
          </Tab.Panel>
          <Tab.Panel>
            <Activities {...activityDetail} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default ProductConfiguration;
