import OrdersComponent from "components/Orders";
import LoadingComponent from "components/Shared/Loading";
import useOrder from "hooks/useOrder";
import React, { useEffect } from "react";

const OrdersPage = (): React.ReactElement => {
  const props = useOrder();
  const { dateRange, handleGetOrders, loaders } = props;

  useEffect(() => {
    handleGetOrders();
  }, [dateRange]);

  return (
    <LoadingComponent loading={loaders.fetchingOrder && !props.ordersSummary}>
      <OrdersComponent {...props} />
    </LoadingComponent>
  );
};

export default OrdersPage;
