import useNotifications from "hooks/useNotifications";
import React, { useEffect } from "react";
import Notifications from "components/Notifications";
import useMembers from "hooks/useMembers";
import useBusiness from "hooks/useBusiness";
import { useAppState } from "config/store";

const NotificationsPage = (): React.ReactElement => {
  const props = useNotifications();
  const membersDetail = useMembers();

  const { handleGetBusiness } = useBusiness();
  const appState = useAppState();

  useEffect(() => {
    handleGetBusiness(appState.activeBusinessId.get() as string);
    return () => {
      handleGetBusiness(appState.activeBusinessId.get() as string);
    };
  }, []);

  return <Notifications {...props} members={membersDetail.users.members} />;
};

export default NotificationsPage;
