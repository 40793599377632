import { ArrayCombination } from "types";

export const combineDuplicates = <T extends object, K extends keyof T>(
  duplicateData: ArrayCombination<T, K>
): Array<T> => {
  const { label, data, canMerge, doMerge } = duplicateData;

  // eslint-disable-next-line
  const seen: Record<any, T> = {};

  return JSON.parse(JSON.stringify(data)).filter((entry: T) => {
    let previous: T;
    // Have we seen this label before and can we merge?
    if (
      Object.prototype.hasOwnProperty.call(seen, entry[label] as K) &&
      canMerge(seen[entry[label]], entry)
    ) {
      // Yes, grab it and run the custom merge function
      previous = seen[entry[label]];
      const mergedData = doMerge(previous, entry);
      Object.keys(mergedData).forEach((key: string) => {
        previous[key as K] = mergedData[key as K];
      });
      // Don't keep this entry, we've merged it into the previous one
      return false;
    }

    // Remember that we've seen it
    seen[entry[label] as string] = entry;

    // Keep this one, we'll merge any others that match into it
    return true;
  });
};

export const replaceAtIndex = <T>(items: Array<T>, item: T, index: number): Array<T> => {
  return [...items.slice(0, index), item, ...items.slice(index + 1, items.length)];
};

export const removeAtIndex = <T>(items: Array<T>, index: number): Array<T> => {
  return [...items.slice(0, index), ...items.slice(index + 1, items.length)];
};
