import EditProductVariant from "components/CreateEditProductVariant";
import useProduct from "hooks/useProduct";
import React from "react";
import LoadingComponent from "components/Shared/Loading";

const EditProductVariantPage = (): React.ReactElement => {
  const props = useProduct();
  const { loaders } = props;

  return (
    <LoadingComponent loading={loaders.fetchingProduct}>
      <EditProductVariant {...props} />
    </LoadingComponent>
  );
};

export default EditProductVariantPage;
