import { load } from "helpers/analytics";
import React, { useEffect } from "react";
import AppRoutes from "routes";
import "./App.css";

function App() {
  useEffect(() => {
    load();
  }, []);

  return <AppRoutes />;
}

export default App;
