import api from "config/api";
import { track } from "helpers/analytics";
import { getYear } from "helpers/date";
import useDateRange from "hooks/shared/useDateRange";
import { useEffect, useState } from "react";
import { OverviewLoaders, UseOverviewType, Response, Report, YearlyReport } from "types";
import { SegmentEvent } from "types/enum";

const useOverview = (): UseOverviewType => {
  const { dateRange, handleDateRangeChange, getDateFilter } = useDateRange();
  const [loaders, setLoaders] = useState<OverviewLoaders>({
    fetchingReport: true
  });
  const [report, setReport] = useState<Report>();
  const [year, setYear] = useState<string>(getYear());
  const [yearlyReport, setYearlyReport] = useState<YearlyReport>();

  const handleGetCustomReport = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingReport: true }));
    try {
      const json: Response<Report> = await api.get(`report/custom?${getDateFilter()}`).json();
      if (json.code === 200) {
        track(SegmentEvent.REPORT_VIEWED, {
          startingFrom: dateRange.startDate,
          endingOn: dateRange.endDate,
          dateDiff: 1
        });
        setReport(json.data);
      }
    } catch (err) {
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingReport: false }));
  };

  const handleGetYearlyReport = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingReport: true }));
    try {
      const json: Response<YearlyReport> = await api.get(`report/year?year=${year}`).json();
      if (json.code === 200) {
        track(SegmentEvent.REPORT_VIEWED, {
          year
        });
        setYearlyReport(json.data);
      }
    } catch (err) {
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingReport: false }));
  };

  useEffect(() => {
    handleGetCustomReport();
  }, [dateRange]);

  useEffect(() => {
    handleGetYearlyReport();
  }, [year]);

  return {
    dateRange,
    handleDateRangeChange,
    report,
    loaders,
    year,
    yearlyReport,
    setYear
  };
};

export default useOverview;
