import React, { useState } from "react";
import { CreateEditOrderProps } from "types";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Error from "components/Shared/Error";
import Button from "components/Shared/Button";
import Title from "components/Shared/Title";
import Stepper from "components/Shared/Stepper";
import Form from "components/Shared/Form";
import ConfirmationDialog from "components/Shared/ConfirmationDialog";
import cx from "classnames";
import Details from "./Details";
import Products from "./Products";
import AdditionalInformation from "./AdditionalInformation";
import { DiscountAndTax } from "./DiscountAndTax/DiscountAndTax";
import Checkbox from "components/Shared/Checkbox";

const CreateEditOrder = (props: CreateEditOrderProps): React.ReactElement => {
  const {
    orderForm,
    handleFormSubmit,
    formIsValid,
    error,
    loaders,
    orderId,
    deleteConfirmationMessage,
    handleDeleteCancellation,
    handleDeleteConfirmation,
    handleToggle,
    templateId
  } = props;
  const [activeStep, setActiveStep] = useState("Order details");
  const isCreatingNewOrder = !orderId;

  return (
    <div className="bg-white rounded-lg py-form mobile-full-screen">
      <div className="form-xy">
        {templateId ? (
          <Link to="/sales/templates" className="font-bold text-sm inline-flex" data-testid="backLink">
            <span className="text-primary mr-1">
              <ArrowLeft />
            </span>
            Back to templates
          </Link>
        ) : (
          <Link to="./../" className="font-bold text-sm	inline-flex" data-testid="backLink">
            <span className="text-primary mr-1">
              <ArrowLeft />
            </span>
            Back to {isCreatingNewOrder ? "orders" : "order"}
          </Link>
        )}

        <Title className="my-8 text-center">{isCreatingNewOrder ? "Add new" : "Edit"} order</Title>

        <div className={cx(!isCreatingNewOrder && "hidden")}>
          <Stepper
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            steps={["Order details", "Products", "Additional information"]}
          />
        </div>
      </div>
      <form autoComplete="off" onSubmit={handleFormSubmit} data-testid="orderForm">
        <div className="px-4 md:px-10">
          <Error error={error} />
        </div>
        {activeStep === "Order details" && (
          <div data-testid="orderDetails">
            <Details {...props} isCreatingNewOrder={isCreatingNewOrder} />
            <Form.Footer>
              {isCreatingNewOrder ? (
                <Button
                  type="button"
                  disabled={!orderForm.customerName || !orderForm.date}
                  onClick={(): void => setActiveStep("Products")}
                  className="w-full md:w-60"
                  data-testid="continue">
                  Continue
                </Button>
              ) : (
                <Button
                  loading={loaders.savingOrder}
                  type="submit"
                  disabled={loaders.savingOrder || !orderForm.customerName || !orderForm.date}
                  className="w-full md:w-60"
                  data-testid="continue">
                  Save
                </Button>
              )}
            </Form.Footer>
          </div>
        )}
        {activeStep === "Products" && (
          <div>
            <Products {...props} orderFormProducts={orderForm.products} />
            <DiscountAndTax {...props} />
            <Form.Footer>
              {isCreatingNewOrder ? (
                <>
                  <Button
                    type="button"
                    onClick={(): void => setActiveStep("Order details")}
                    className="w-full sm:w-60 !bg-black"
                    gradient={false}
                    data-testid="back">
                    Go back
                  </Button>
                  <Button
                    type="button"
                    disabled={!formIsValid}
                    onClick={(): void => setActiveStep("Additional information")}
                    className="w-full sm:w-60"
                    data-testid="continue">
                    {orderId ? "Save and continue" : "Continue"}
                  </Button>
                </>
              ) : (
                <Button
                  loading={loaders.savingOrder}
                  type="submit"
                  disabled={loaders.savingOrder || !formIsValid}
                  className="w-full md:w-60"
                  data-testid="continue">
                  Save
                </Button>
              )}
            </Form.Footer>
          </div>
        )}
        {activeStep === "Additional information" && (
          <div className="text-dark-grey" data-testid="additionalInfo">
            <AdditionalInformation {...props} isCreatingNewOrder={isCreatingNewOrder} />
            <Form.Footer spaceBetween>
              {isCreatingNewOrder ? (
                <>
                  <div className="inline-flex items-center py-2">
                    <Checkbox
                      data-testid="saveAsTemplate"
                      checked={orderForm.saveAsTemplate}
                      onChange={handleToggle}
                      label="Save as template"
                      name="saveAsTemplate"
                    />
                  </div>
                  <div className="flex space-x-4 w-full md:w-auto">
                    <Button
                      type="button"
                      onClick={(): void => setActiveStep("Products")}
                      className="w-full md:w-60 !bg-black"
                      gradient={false}
                      data-testid="back">
                      Go back
                    </Button>
                    <Button
                      type="submit"
                      disabled={!formIsValid}
                      loading={loaders.savingOrder}
                      data-testid="submitButton"
                      className="w-full md:w-60">
                      Submit order
                    </Button>
                  </div>
                </>
              ) : (
                <Button
                  type="submit"
                  disabled={!formIsValid}
                  loading={loaders.savingOrder}
                  data-testid="submitButton"
                  className="w-full md:w-60">
                  Save
                </Button>
              )}
            </Form.Footer>
          </div>
        )}
      </form>
      <ConfirmationDialog
        message={deleteConfirmationMessage}
        handleContinue={handleDeleteConfirmation}
        handleCancel={handleDeleteCancellation}
      />
    </div>
  );
};

export default CreateEditOrder;
