import React from "react";

export const NavSale = (): React.ReactElement => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none">
    <path
      d="M19.5 2.77888V14.1789C19.5 14.6789 19.325 15.1039 18.975 15.4539C18.625 15.8039 18.2 15.9789 17.7 15.9789H2.3C1.8 15.9789 1.375 15.8039 1.025 15.4539C0.675 15.1039 0.5 14.6789 0.5 14.1789V2.77888C0.5 2.27888 0.675 1.85388 1.025 1.50388C1.375 1.15388 1.8 0.978882 2.3 0.978882H17.7C18.2 0.978882 18.625 1.15388 18.975 1.50388C19.325 1.85388 19.5 2.27888 19.5 2.77888ZM2 4.87888H18V2.77888C18 2.71222 17.9667 2.64555 17.9 2.57888C17.8333 2.51222 17.7667 2.47888 17.7 2.47888H2.3C2.23333 2.47888 2.16667 2.51222 2.1 2.57888C2.03333 2.64555 2 2.71222 2 2.77888V4.87888ZM2 8.07888V14.1789C2 14.2455 2.03333 14.3122 2.1 14.3789C2.16667 14.4455 2.23333 14.4789 2.3 14.4789H17.7C17.7667 14.4789 17.8333 14.4455 17.9 14.3789C17.9667 14.3122 18 14.2455 18 14.1789V8.07888H2Z"
      fill="currentColor"
    />
  </svg>
);
