import { OnboardingProps } from "types";
import React from "react";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Error from "components/Shared/Error";
import Title from "components/Shared/Title";

const OnboardingMember = (props: OnboardingProps & { email?: string }): React.ReactElement => {
  const {
    onboardingForm,
    handleFormChange,
    handleMemberFormSubmit,
    error,
    memberFormIsValid,
    loaders,
    email
  } = props;

  return (
    <div className="w-screen h-screen pl-0 md:pl-12 lg:pl-40">
      <div className="flex justify-center mx-4 sm:mx-0 mt-14 sm:mt-20">
        <div className="bg-white rounded-sm px-4 py-10 md:p-20 sm:w-4/5 md:2/3 xl:w-1/2">
          <Title className="text-center">Welcome to BloomPulse!</Title>
          <form
            onSubmit={handleMemberFormSubmit}
            className="mt-6 sm:mt-10"
            data-testid="onboardingForm">
            <Error error={error} />
            <div>
              <TextInput
                data-testid="email"
                type="email"
                name="email"
                value={email}
                disabled
                label="Email address"
              />
            </div>
            <div className="w-full flex space-x-4">
              <div className="w-1/2">
                <TextInput
                  data-testid="firstName"
                  type="firstName"
                  name="firstName"
                  onChange={handleFormChange}
                  value={onboardingForm.firstName}
                  required
                  label="First name"
                />
              </div>
              <div className="w-1/2">
                <TextInput
                  data-testid="lastName"
                  type="lastName"
                  name="lastName"
                  onChange={handleFormChange}
                  value={onboardingForm.lastName}
                  required
                  label="Last name"
                />
              </div>
            </div>
            <div>
              <Button
                data-testid="submitButton"
                type="submit"
                disabled={!memberFormIsValid}
                loading={loaders.registeringUser}
                className="w-full mt-4">
                Get started
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OnboardingMember;
