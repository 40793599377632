import React from "react";
import { SortProps } from "types";
import Select from "../Select";

const Sort = (props: SortProps): React.ReactElement => {
  const { selectedField, handleSort, fields } = props;
  return (
    <div className="flex pl-0">
      <Select
        simple
        inputSize="sm"
        name="reuse"
        value={selectedField}
        onChange={(e): void => handleSort?.(e.target.value)}
        label="Sort by field">
        <option value="" disabled>
          Select field
        </option>
        {fields.map((field) => (
          <option value={field.field} key={field.field}>
            {field.name}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default Sort;
