import React from "react";

export const NavStore = (): React.ReactElement => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none">
    <path
      d="M1.2 2.22888V0.728882H16.8V2.22888H1.2ZM1.25 16.2289V10.2289H0.125V8.72888L1.2 3.72888H16.8L17.875 8.72888V10.2289H16.75V16.2289H15.25V10.2289H10.75V16.2289H1.25ZM2.75 14.7289H9.25V10.2289H2.75V14.7289ZM1.675 8.72888H16.325L15.575 5.22888H2.425L1.675 8.72888Z"
      fill="currentColor"
    />
  </svg>
);
