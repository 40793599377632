import CreateEditMember from "components/CreateEditMember";
import useMembers from "hooks/useMembers";
import React from "react";
import LoadingComponent from "components/Shared/Loading";

const CreateExpensePage = (): React.ReactElement => {
  const membersDetail = useMembers();

  return (
    <LoadingComponent loading={membersDetail.loaders.fetchingMembers}>
      <CreateEditMember {...membersDetail} />
    </LoadingComponent>
  );
};

export default CreateExpensePage;
