import { hasFeature } from "components/Layout/Dashboard/Dashboard.utils";
import api from "config/api";
import { useAppState } from "config/store";
import { ERROR_OCCURRED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { isNotEmpty } from "helpers/validate";
import useBusiness from "hooks/useBusiness";
import { useEffect, useState } from "react";
import { BusinessForm, FormChangeEvent, SettingsLoaders, UseSettingsType, Response } from "types";
import { AlertType, FeatureFlag, SegmentEvent } from "types/enum";

const useSettings = (): UseSettingsType => {
  const { showAlert } = useAlert();
  const { handleGetUserBusinesses, handleGetBusiness } = useBusiness();

  const appState = useAppState();
  const bussiness = appState.business.get();

  const storeEnabled = hasFeature(FeatureFlag.STORE, appState.business.get()?.features);

  const [businessForm, setBusinessForm] = useState<BusinessForm>({
    businessType: bussiness?.businessType || "",
    businessName: bussiness?.name || "",
    currency: bussiness?.currency || ""
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<SettingsLoaders>({
    savingSettings: false,
    togglingFeature: false
  });
  const [error, setError] = useState("");

  const handleEditBusiness = async (): Promise<boolean> => {
    const requestData = {
      businessName: businessForm.businessName
    };

    try {
      const json: Response<string> = await api.put("business", { json: requestData }).json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.BUSINESS_MODIFIED, {
          name: businessForm.businessName
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleToggleFeature = async (feature: FeatureFlag): Promise<void> => {
    try {
      setLoaders((prev) => ({ ...prev, togglingFeature: true }));

      const json: Response<string> = await api.put(`features/toggle/${feature}`).json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.FEATURE_TOGGLED, {
          feature
        });
        await handleGetBusiness(appState.activeBusinessId.get() || "");
      }
      setLoaders((prev) => ({ ...prev, togglingFeature: false }));
      return;
    } catch {
      return;
    }
  };

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setBusinessForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingSettings: true }));
    const result = await handleEditBusiness();

    setLoaders((prev) => ({ ...prev, savingSettings: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
      await Promise.all([
        handleGetBusiness(appState.activeBusinessId.get() || ""),
        handleGetUserBusinesses()
      ]);
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  // UseEffects
  useEffect(() => {
    setFormIsValid(
      isNotEmpty(businessForm.businessName) && businessForm.businessName != bussiness?.name
    );
  }, [businessForm, bussiness]);

  return {
    storeEnabled,
    businessForm,
    loaders,
    error,
    handleFormChange,
    formIsValid,
    handleFormSubmit,
    handleToggleFeature
  };
};

export default useSettings;
